@import "../../scss/colors.scss";

.points_summary_container {
  display: flex;
  flex-direction: column;

  .container_title {
    padding: 0;
    margin: 6px 0 0 0;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600 !important;
    color: $lightBlue;
  }

  .summary_header {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin-top: 13px;

    span {
      text-align: left;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;

      &:first-child {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 0.7;
      }

      &:nth-child(3) {
        flex: 0.7;
      }

      &:last-child {
        flex: 0.2;
        text-align: right;
      }
    }
  }

  .summary_body {
    max-height: 80px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: $lightBlack2;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      width: 100%;
      background-color: $black2;
      border-radius: 6px;
    }
    .summary_body_1 {
      width: 98%;

      .row {
        display: flex;
        text-align: left;
        text-transform: capitalize;
        margin: 2px 0;

        span {
          text-align: left;
          // font-family: "Poppins";
          font-size: 12px;
          color: $grey;

          &:first-child {
            flex: 1;
            text-transform: uppercase;
          }

          &:nth-child(2) {
            flex: 0.7;
          }

          &:nth-child(3) {
            flex: 0.7;
          }

          &:last-child {
            flex: 0.2;
            text-align: left;
          }
        }
      }
    }
  }

  .summary_total_pts {
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $lightGrey6;
    margin-top: 14px;
    border-radius: 6px;

    p {
      margin: 0;
      padding: 0;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
