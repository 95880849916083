.__block{
    display: block;
}
.__inline{
    display: inline;
}
.__inline-block{
    display: inline-block;
}
.__center{
    text-align: center;
}
.__right{
    text-align: right;
}
.__left{
    text-align: left;
}
.__italic{
    font-style: italic;
}
.__line-height-1{
    line-height: 1em;
}
.__background{
    background-size: cover;
    background-repeat: no-repeat;
    .__to-right{
        background-position: right;
    }
    .__to-left{
        background-position: left;
    }
}
.__viewport{
    min-height: 100vh;
    @extend .__column;
}
.__viewport-large-title{
    font-size: 80px;
    font-weight: bolder;
}
.__viewport-title{
    font-size: 52px;
    letter-spacing: -0.5px;
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
    padding: 0 0.5em;
    border-radius: 6px;
    font-weight: 600;
    sup, sub{
        font-size: 0.462em;
    }
    sup{
        margin-left: 0.3em;
        position: relative;
        top: -0.4em;
    }
}
.__container{
    width: 86%;
    margin-left: auto;
    margin-right: auto;
}
.__container-2{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.__container-3{
    margin-left: 48px;
    margin-right: 48px;
}
.__p-3{
    padding: 3em;
}
.__p-0{
    padding: 0;
}
.__p-1{
    padding: 1em !important;
}
.__pl-1{
    padding-left: 1em;
}
.__pr-1{
    padding-right: 1em;
}
.__pt-1{
    padding-top: 1em;
}
.__pb-1{
    padding-bottom: 1em;
}

.__m-0{
    margin: 0;
}
.__m-s{
    margin: 0.5em;
}
.__m-1{
    margin: 1em;
}
.__m-2{
    margin: 2em;
}
.__m-3{
    margin: 3em;
}
.__m-4{
    margin: 4em;
}
.__m-a{
    margin: auto;
}
.__m-5{
    margin: 5em;
}
.__mt-0{
    margin-top: 0;
}
.__mb-0{
    margin-bottom: 0;
}
.__ml-0{
    margin-left: 0;
}
.__mr-0{
    margin-right: 0;
}
.__mt-s{
    margin-top: 0.5em !important;
}
.__mb-s{
    margin-bottom: 0.5em !important;
}
.__mr-s{
    margin-right: 0.5em !important;
}
.__ml-s{
    margin-left: 0.5em !important;
}
.__ml-1{
    margin-left: 1em !important;
}
.__mr-1{
    margin-right: 1em !important;
}
.__mt-1{
    margin-top: 1em !important;
}
.__mb-1{
    margin-bottom: 1em !important;
}
.__ml-2{
    margin-left: 2em !important;
}
.__mr-2{
    margin-right: 2em !important;
}
.__mt-2{
    margin-top: 2em !important;
}
.__mb-2{
    margin-bottom: 2em !important;
}

.__ml-3{
    margin-left: 3em !important;
}
.__mr-3{
    margin-right: 3em !important;
}
.__mt-3{
    margin-top: 3em !important;
}
.__mb-3{
    margin-bottom: 3em !important;
}
.__ml-3{
    margin-left: 3em !important;
}

.__ml-4{
    margin-left: 4em !important;
}
.__mr-4{
    margin-right: 4em !important;
}
.__mt-4{
    margin-top: 4em !important;
}
.__mb-4{
    margin-bottom: 4em !important;
}

.__ml-a{
    margin-left: auto !important;
}
.__mr-a{
    margin-right: auto !important;
}
.__mt-a{
    margin-top: auto !important;
}
.__mb-a{
    margin-bottom: auto !important;
}
.__mt-5{
    margin-top: 5em;
}
.__mb-5{
    margin-bottom: 5em;
}
.__ml-5{
    margin-left: 5em;
}
.__mr-5{
    margin-right: 5em;
}
.__mt-6{
    margin-top: 6em;
}
.__mb-6{
    margin-bottom: 6em;
}
.__ml-6{
    margin-left: 6em;
}
.__mr-6{
    margin-right: 6em;
}
.__mt-7{
    margin-top: 7em;
}
.__mb-7{
    margin-bottom: 7em;
}
.__ml-7{
    margin-left: 7em;
}
.__mr-7{
    margin-right: 7em;
}
.__ml-1s{
    margin-left: 1.5em;
}
.__mr-1s{
    margin-right: 1.5em;
}
.__ml-1s{
    margin-top: 1.5em;
}
.__mb-1s{
    margin-bottom: 1.5em;
}
.__mb-sm{
    margin-bottom: 0.3rem !important;
}