@import "../../scss/colors.scss";

p {
  margin: 0;
  padding: 0;
}
.checkboxalt {
  width: 19px;
  height: 19px;
  /* margin: 1px 8px 20px 0; */
  /* padding: 5px 3px 4px; */
  border-radius: 3px;
  border: solid 1px #fb6e00;
  position: relative;
  display: inline-block;
  margin-right: 10px;
  vertical-align: -4px;
  img {
    width: 13px;
    /* height: 13px; */
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.list_header_wrapper {
  display: flex;
  flex-direction: column;

  .list_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    font-style: 16px;

    .list_left_side {
      display: flex;
      align-items: center;
      font-size: 16px;
      flex: 1;

      .span {
        font-weight: bold;
        font-size: 26px;
        color: $primary;
        flex: 1;
      }
    }

    .list_right_side {
      display: inherit;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 394px;
      .list_right_side_btns {
        > :first-child {
          width: 134px;
          height: 48px;
          margin: 0 10px 0 0;
        }
      }

      .list_right_side_text {
        display: flex;
        width: 85%;
        justify-content: flex-end;

        span {
          font-size: 14px;
        }
      }

      button {
        width: 190px;
        height: 48px;
        box-shadow: 0 3px 27px -2px $primaryLight3;
        border-radius: 12px;
      }
    }

    @media (max-width: 540px) {
      .list_right_side {
        display: inherit;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: auto;
        .list_right_side_btns {
          display: flex;

          button {
            width: 150px !important;
            height: 40px !important;
            box-shadow: 0 3px 27px -2px $primaryLight3;
            border-radius: 12px;
          }
        }

        .list_right_side_text {
          display: flex;
          width: 85%;
          justify-content: flex-end;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .list_container:first-child {
    border-bottom: 2px solid $lightBlack3;
  }
}

.list_left_side_2 {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: $grey3;
  width: 50px;
  justify-content: center;

  span {
    margin: 0 8px;
  }
}

.list_left_side_1 {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: $grey3;
  flex: 1;

  span {
    margin: 0 8px;
  }
}

.list_body {
  margin: 60px 0;

  .list_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 15px;
    margin: 20px 0;
    background-color: $grey4;
    font-size: 16px;

    button {
      border: none;
      outline: none;
      background-color: transparent;
      color: $primary;
      font-weight: bold;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
    }

    button.claimed {
      color: $white;
    }
  }
}

.modal_container {
  background-color: $white;
  border-radius: 6px;
  width: 70%;
  overflow: hidden scroll;
  max-height: 100vh - 10vh;
  margin-top: 15px;
  border-radius: 6px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $black2;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey5;
    width: 7px;
    border-radius: 5px;
  }

  .modal_header {
    display: flex;
    justify-content: space-between;
    color: $white;
    background-color: $primary;
    padding: 10px 30px;
    font-size: 26px;
    font-weight: 500;
    border-radius: 6px 6px 0 0;
    align-items: center;

    .modal_close_icon {
      width: 20px;
      height: 20px;
      opacity: 0.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:before,
      &:after {
        position: absolute;
        content: " ";
        width: 2px;
        height: 20px;
        background-color: $white;
      }

      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .modal_body {
    color: $black;
    padding: 10px 30px;

    .body_header {
      font-size: 14px;
      font-weight: bold;
      color: $grey9;
    }

    .body_header_primary {
      color: $primary;
    }

    .margin_l_40 {
      margin-left: 40px;
    }

    .margin_t_10 {
      margin-top: 10px;
    }

    label {
      font-weight: 14px;
      color: $darkblack;

      span {
        font-style: italic;
        font-weight: bold;
      }
    }

    .form_amountInput {
      > div {
        width: 220px;
      }
    }

    .form_Input_50 {
      display: inline-flex;
      flex-direction: column;
      width: 50%;
    }

    .form_control_center {
      justify-content: center;
      align-items: center;
    }

    .form_control {
      display: flex;
      margin: 10px 0;

      .form_dropdown_main {
        display: flex;
        flex-direction: column;
        min-width: 225px;
        max-width: 340px;
      }
      .form_dropdown {
        border: 2px solid $offwhite;
        padding: 18px 20px;
        background-color: $white;
        border-radius: 6px;
        appearance: none;
        background: url("../../assets/down-arrow.png") $white no-repeat 95.5%;
        background-size: 17px;
        min-width: 225px;
        max-width: 340px;
        outline: none;
      }
    }
  }
  .select {
    background-color: $white !important;
  }
}

.verify_model {
  .verify_container {
    min-height: 140px;
    padding: 25px;
    border-radius: 15px;
    background-color: #202124;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .verifyButtonsDiv {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 70%;
    }
    button {
      min-width: 160px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      height: 42px;
      padding: 9px 30px 10px 30px;
      border-radius: 12px;
      border: transparent;
      box-shadow: 0 3px 27px -2px rgba(251, 110, 0, 0.38);
      background-color: #fb6e00;
    }
  }
}

@media (max-width: 540px) {
  .list_header_wrapper {
    display: flex;
    flex-direction: column;

    .list_container {
      display: flex;
      flex-direction: column;
      border-bottom: none;
      padding: 0;
      margin-bottom: 30px;

      .list_left_side {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 10px 0;
        flex: 1;

        .span {
          font-weight: bold;
          font-size: 26px;
          color: $primary;
          flex: 1;
          text-align: end;
        }
      }

      .list_right_side {
        display: inherit;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0;

        span {
          font-size: 14px;
          margin-top: 10px;
        }

        button {
          width: 100%;
          height: 41px;
        }
      }
    }

    .list_container:last-child {
      border-bottom: none;
    }
  }

  .list_left_side_1 {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $grey3;
    flex: 1;

    span {
      margin: 0 8px;
    }
  }

  .list_body {
    margin: 60px 0;

    .list_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .modal_container {
    background-color: $white;
    border-radius: 6px;
    width: 90%;
    overflow: visible !important;
    max-height: 100vh - 13vh;
    margin-top: 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    .modal_header {
      display: flex;
      color: $primary;
      background-color: $white;
      align-items: flex-end;
      justify-content: flex-end;
      position: absolute;
      width: 90%;

      p {
        display: none;
      }

      .modal_close_icon {
        position: absolute;
        opacity: 1;
        display: flex;
        align-items: flex-end;
        transform: translateY(30px);

        &:before,
        &:after {
          background-color: $darkblack;
        }
      }
    }

    .modal_body {
      color: $black;
      padding: 10px 30px;
      margin-top: 20px;

      .hidden {
        display: none;
      }

      .body_header {
        font-size: 14px;
        font-weight: bold;
        color: $grey9;
      }

      .body_header_primary {
        color: $primary;
      }

      .margin_l_40 {
        margin-left: 0px;
      }

      .margin_t_10 {
        margin-top: 10px;
      }

      label {
        font-weight: 14px;
        color: $darkblack;

        span {
          font-style: italic;
          font-weight: bold;
        }
      }

      .form_amountInput {
        > div {
          width: 55%;
        }
      }

      .form_Input_50 {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
      }
      .form_control_center {
        justify-content: center;
        align-items: center;
      }

      .form_control {
        display: flex;
        flex-direction: column;
        margin: 0;

        .form_dropdown_main {
          display: flex;
          flex-direction: column;
        }
        .form_dropdown {
          border: 2px solid $offwhite;
          padding: 15px 20px;
          background-color: $white;
          border-radius: 6px;
          appearance: none;
          background: url("../../assets/down-arrow.png") $white no-repeat 95.5%;
          background-size: 17px;
          min-width: 100%;
          // max-width: 340px;
          outline: none;
        }

        .button {
          width: 100%;
          transform: translateY(70%);
          position: relative;
          justify-content: center;
          align-items: center;
          display: flex;

          button {
            width: 100%;
            height: 52px;
          }
        }
      }
    }
    .select {
      background-color: $white !important;
    }
  }
}
.custom_dropdown {
  min-width: 225px;
  max-width: 340px;
}
.custom_dropdown + .custom_dropdown {
  margin-left: 40px;
}
