@import '../../scss/colors.scss';

.__bingo-game-2{
    background: $darkblack;
    max-width: 1138px;
    margin-bottom: 0.6em;
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    border: 0.5px solid $primary;
    border-radius: 6px;
    >*{
        flex: 1;
        border: 0.5px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
