.__primary {
  background: $primary;
}
.__uppercase {
  text-transform: uppercase;
}
svg {
  height: 1.3em;
}
.__text-color-primary {
  color: $primary;
}
.__btn {
  padding: 0.5em 1em;
  @extend .__primary;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border: 1px solid $primary;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // min-width: 9em;
  &.__style-2 {
    background: transparent;
    border-color: currentColor;
  }
  &.__style-3 {
    border-radius: 2em;
    padding: 0.4em 2.29em;
  }
  &.__shadow {
    box-shadow: 0 3px 27px 0 rgba(251, 110, 0, 0.48);
  }
  &.__shadow-2 {
    box-shadow: 0 3px 27px 0 #fb6e00;
  }
  &.__large-btn {
    padding: 0.563em 1.1em;
    min-width: 10.625em;
  }
}
.__primary-color {
  color: $primary;
}
.__dark-white-color {
  color: #f2f2f2;
  &.__shade-2 {
    color: rgba($color: #f2f2f2, $alpha: 0.5);
  }
}
.__white-color {
  color: #fff !important;
  border-color: #fff !important;
}
.__white {
  background: #fff;
  border-color: #fff;
  color: #000;
}
.__unordered-list {
  list-style-type: none;
  padding: 0;
  > li {
    margin: 1.2em 0;
    padding-left: 2.4em;
    position: relative;
    &::before {
      content: "";
      min-width: 1em;
      height: 1em;
      background: $primary;
      position: absolute;
      top: 0.21em;
      border-radius: 0.2em;
      left: 0;
    }
  }
}
.__chevron {
  &::after {
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: inline-block;
    height: 0.4em;
    left: 0.24em;
    position: relative;
    vertical-align: center;
    width: 0.4em;
  }
  &.__-right::after {
    transform: rotate(45deg);
  }
  &.__-bottom::after {
    transform: rotate(135deg);
  }
}
.__badge {
  background: linear-gradient(to right, #fb6e00 11%, #fa3800 94%);
  padding: 0.05em 0.46em;
  border-radius: 8px;
}
.__small-border {
  width: 39px;
  height: 4px;
  background: $primary;
  border-radius: 2px;
}
.__text-field,
.__input-field {
  font-family: inherit;
  outline: none;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.__text-field {
  background: rgba(216, 216, 216, 0.6);
  color: #f2f2f2;
  padding: 0.675em 1em;
  border-radius: 6px;
  border: solid 1px #979797;
  width: 100%;
  &::placeholder {
    color: #f2f2f2;
    font-style: italic;
  }
}
.__input-field {
  padding: 0.76em 2em;
  background-color: #35363a;
  font-size: 15px;
  border-radius: 2em;
  color: #fff;
}
label {
  .__checkbox:checked + span {
    color: $primary !important;
  }
}
.__checkbox {
  padding: 0;
  margin: 0;
  position: relative;
  min-width: 16px;
  min-height: 16px;
  visibility: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #f2f2f2;
  &::after,
  &::before {
    content: "";
    position: absolute;
    visibility: visible;
    border-radius: 3px;
  }
  &::before {
    left: 50%;
    top: 50%;
    border: 1px currentColor solid;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    transform: translate(-50%, -50%);
    background: transparent;
  }
  &::after {
    left: 0;
    height: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    background: currentColor;
    transition: linear 0.1s transform;
  }
  &:checked {
    color: $primary;
    &::after {
      transform: scale(0.7);
    }
  }
}

.__outline-badge {
  padding: 0.54em 1.2em;
  font-size: 0.94em;
  border-radius: 12px;
  background: #555658;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(99, 99, 99, 0.24);
  width: 120px;
  height: 48px;
  svg {
    margin-right: 0.4em;
  }
  &.__active {
    color: $primary;
    font-weight: 500;
    background-color: rgba(99, 99, 99, 0.24);
    border: 2px solid;
  }
}
.__pointer {
  cursor: pointer;
}
.__relative {
  position: relative;
}
.__absolute {
  position: absolute;
}

.__scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}


.form-check-input:checked {border: 0;background-color: #414243;}

.form-switch .form-check-input {
    width: 50px;
    height: 28px;
    cursor: pointer;
}

.form-check-input {
        background-color: #414243;
}


label.form-check-label {
    display: flex;
    align-items: center;
}

.form-check.form-switch {
    display: flex;
    align-items: center;
    margin-left: 10px;
}



.form-switch .form-check-input:checked {background-image: url("../ui/CheckBox/checked-round.svg") !important;}
.form-switch .form-check-input {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;}
.form-switch .form-check-input:focus{
  box-shadow: none !important;
  border: 0 !important;
}



.text-black {color: #fff !important;}
.text-black  option { color: #000; }
.text-black  option:first-child{
  color: #000;
}

select.text-black {
    color: #fff !important;
}.main__teams__point .main__card__inner {
  margin-top: 10px !important;
}

.nhlmobilelive .cardsSection .carousel.carousel-slider {
  height: auto !important;
}
.nhlmobilelive .carousel .slider-wrapper.axis-horizontal .slider .slide {
  background: none;
}
.nhlmobilelive .carousel-root .carousel .thumbs-wrapper.axis-vertical{
  display: none;
}
.nhlmobilelive .leftSide .carousel-root {
  width: 100%;
}
.nhlmobilelive .stick {
  position: sticky;
  top: 66px;
  z-index: 200;
  background-color: #1e1e1e;
  padding-top: 10px;
  backdrop-filter: blur(50px);
  border: solid 1px #36383d;
  border-left: 0;
  border-right: 0;
  height: 68px;
}
.rotate {
  transform: rotate(180deg);
}

.react-toast-notifications__container{
  position: fixed !important;
  top: 50% !important;
  left: 36%;
}