@import '../../scss/colors.scss';

.__power_center {
    .__power_center_banner {
        background-image: url('../../assets/power-center-banner.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 50px;
        padding-bottom: 50px;

        .__power_center_banner_left {
            display: flex;
            flex-direction: row;

            .__power_center_banner_left_icon {
                display: inherit;
                align-items: flex-end;
            }

            .__power_center_banner_left_title {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .__power_center_banner_left_title_main {
                    width: 502px;
                    height: 96px;
                    font-family: Teko;
                    font-size: 96px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $grey2;
                }

                .__power_center_banner_left_title_sub {
                    height: 23px;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.2px;
                    color: $primary;
                }
            }
        }

        .__balance {
            position: relative;
            margin-top: 60px;

        }
    }
}

@media (max-width: 1024px) {
    .__power_center {
        .__power_center_banner {
            background-image: url('../../assets/power-center-banner-mobile.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 553px;

            .__power_center_banner_left {
                display: flex;
                flex-direction: row;
                justify-content: center;
                height: 350px;
                align-items: center;

                .__power_center_banner_left_icon {
                    display: inherit;
                    align-items: flex-end;
                    margin-right: 10px;

                    .__banner_title_icon {
                        width: 45px;
                        height: 70px;
                    }
                }
    
                .__power_center_banner_left_title {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
    
                    .__power_center_banner_left_title_main {
                        width: 396px;
                        height: 70px;
                        font-family: Teko;
                        font-size: 70px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: $grey2;
                    }
    
                    .__power_center_banner_left_title_sub {
                        width: 396px;
                        height: 17px;
                        font-family: Poppins;
                        font-size: 15px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.4px;
                        color: $grey2;
                        padding-top: 5px;
                    }
                }
            }

            .__balance {
                margin-top: 153px;
            }
        }
    }
}

@media (max-width: 768px) {
    .__power_center {
        .__power_center_banner {
            background-image: url('../../assets/power-center-banner-mobile.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 553px;

            .__power_center_banner_left {
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex: 1;
                height: 350px;
                align-items: center;

                .__power_center_banner_left_icon {
                    display: inherit;
                    align-items: flex-end;
                    margin-right: 10px;

                    .__banner_title_icon {
                        width: 45px;
                        height: 70px;
                    }
                }
    
                .__power_center_banner_left_title {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
    
                    .__power_center_banner_left_title_main {
                        width: 395px;
                        height: 70px;
                        font-family: Teko;
                        font-size: 70px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: $grey2;
                    }
    
                    .__power_center_banner_left_title_sub {
                        width: 395px;
                        height: 17px;
                        font-family: Poppins;
                        font-size: 15px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.4px;
                        color: $grey2;
                        padding-top: 5px;
                    }
                }
            }

            .__balance {
                margin-top: 153px;
            }
        }
    }
}

@media (max-width: 414px) {
    .__power_center {
        .__power_center_banner {
            background-image: url('../../assets/power-center-banner-mobile-new.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 300px;

            .__power_center_banner_left {
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex: 1;
                height: 255px;
                align-items: center;
                
                .__power_center_banner_left_icon {
                    display: inherit;
                    align-items: flex-end;
                    margin-right: 10px;

                    .__banner_title_icon {
                        width: 35px;
                        height: 60px;
                    }
                }
    
                .__power_center_banner_left_title {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
    
                    .__power_center_banner_left_title_main {
                        width: 293px;
                        height: 56px;
                        font-family: Teko;
                        font-size: 56px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: $grey2;
                    }
    
                    .__power_center_banner_left_title_sub {
                        width: 294px;
                        height: 17px;
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.4px;
                        color: $grey2;
                        padding-top: 5px;
                    }
                }
            }

            .__balance {
                margin-top: 153px;
            }
        }
    }
}