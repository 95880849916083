.comingSoonDiv {
    margin-top: 60px;
    text-align: center;
    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
    }
    img {
        width: 465px;
        height: 310px;
        object-fit: contain;
        background-blend-mode: color;
        margin: 0 auto;
    }
}

@media (max-width: 414px) {
    .comingSoonDiv {
        img {
            width: 345px;
            height: auto;
            object-fit: contain;
            background-blend-mode: color;
            margin: 0 auto;
        }
    }
}