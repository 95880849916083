@import '../../scss/colors.scss';

.title{
    font-weight: 900;
    line-height: 1em;
    font-size: 3.4285rem;
    margin: 0;
    font-family: Teko;
}
.root{
    section{
        margin-top: -4.4rem;
        padding-top: 7.4rem;
        a{
            color: $primary;
            &:hover{
                text-decoration: underline !important;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
        h2{
            font-size: 1.4286rem;
            color: $primary;
            margin: 0;
            text-transform: uppercase;
        }
        p{
            margin-top: 1.7rem;
        }
        h3{
            margin: 1.7rem 0 0 0;
            font-size: 1.2857rem;
            font-weight: 600;
        }
        h3 + p{
            margin: 0;
        }
        b{
            font-weight: 600;
        }
        ul{
            list-style: none;
            padding: 0;
        }
        li{
            padding-left: 2em;
            position: relative;
        }
        li::before{
            content: "";
            position: absolute;
            left: 0.75em;
            color: $primary;
            top: 0.5em;
            width: 0.5em;
            height: 0.5em;
            border-radius: 1em;
            background: $primary;
        }
    }
}
.notice{
    border-left: 0.2143em solid $primary;
    display: flex;
    flex-direction: column;
    padding-left: 0.7em;
    margin-left: 0.7em;
    font-weight: 600;
    font-style: italic;
    span{
        position: relative;
        line-height: 1.3em;
        &:first-child{
            top: -0.15em;
        }
        &:last-child{
            bottom: -0.15em;
        }
    }
}

@media (max-width: 770px) {
    .root{
        section{
            padding: 0;
            margin: 0;
            h2{
                font-size: 1.2857rem;
                border-left: 0.25rem solid;
                line-height: 1.2em;
                padding-left: 0.5em;
            }
        }
    }
    .title, .optionals{
        display: none;
    }
}