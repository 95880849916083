@import "../../scss/colors.scss";

.__my_account_menu {
  position: relative;
  width: 150px;
  .__list {
    position: absolute;
    width: 100%;
    border-radius: 8px;

    .__list_item {
      width: 100%;
      height: 48px;
      padding: 13px 0 12px 11px;
      background-color: $lightGrey;
      border-bottom: 1px solid $black;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey2;
      cursor: pointer;
      text-transform: none;
      .list_li {
        width: 100%;
        height: 48px;
        padding: 13px 0 12px 11px;
        background-color: $lightGrey;
        border-bottom: 1px solid $black;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
        cursor: pointer;
        text-transform: none;
      }
    }

    .__list_item:hover {
      background-color: #6890bd;
    }

    .__list_item_selected {
      background-color: #6890bd;
    }

    .__list_item_border_radius_top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .__list_item_border_radius_bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

@media (max-width: 1200px) {
  .__my_account_menu {
    left: 25%;
  }
}
