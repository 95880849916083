.OuterDiv {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    .shareToLockTitle {
        padding-top: 1px;
        padding-right: 2px;
        padding-left: 5px;
    }
    .buttonsDiv {
        display: flex;
        flex: 1;
        justify-content: center;
        padding-bottom: 10px;
    }
}