@import "../../scss/colors.scss";

.select_container {
  display: flex;
  flex-direction: column;

  select {
    outline: none;
    padding: 20px;
    appearance: none;
    width: 100%;
    height: 100%;
    border: 2px solid $lightGrey2;
    border-radius: 6px;
    background: url("../../assets/down-arrow.png") $white no-repeat 96%;
    background-size: 17px;
  }

  .white {
    background-color: $white;
  }
}
