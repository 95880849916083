@import "../../scss/colors.scss";

.search_form {
  display: flex;
  align-items: center;
  // width: 45%;
  margin: 0;
  padding: 0;

  span {
    background-color: $lightBlack5;
    padding: 2px 10px;
    border-radius: 12px;
    margin: 0 10px 0 0;
    width: 318px;
    display: flex;
    align-items: center;

    svg {
      margin: 0 10px;
    }

    input {
      border: none;
      outline: none;
      background-color: transparent;
      height: 46px;
      width: 84%;
      color: $white;
      font-size: 14px;
    }
  }

  .search_dropdown {
    background-color: $lightBlack5;
    padding: 13px 20px;
    border-radius: 12px;
    font-size: 14px;
    position: relative;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .arrow {
      position: absolute;
      border: solid $white;
      border-radius: 0;
      border-width: 0 2px 2px 0;
      padding: 3px;
      display: inline-block;
      width: 8px;
    }

    .down {
      transform: rotate(45deg) translate(36px, -37px);
    }

    .up {
      transform: rotate(-135deg) translate(-36px, 37px);
    }

    .search_dropdown_menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: $lightBlack5;
      width: 100%;
      border-radius: 0 0 12px 12px;
      top: 53px;
      right: 0;
      font-size: 14px;
      z-index: 5;
      text-align: center;

      span {
        width: 100%;
        padding: 13px 30px;
        border-radius: 0;

        &:last-child {
          border-radius: 0 0 12px 12px;
        }

        &:hover {
          cursor: pointer;
          background-color: $darkblack;
          transition: background-color 1s;
        }

        &.active {
          cursor: default;
          background-color: $darkblack;
        }
      }
    }
  }
}

@media (max-width: 2400px) {
  .search_form {
    width: 50% ;
  }
}
.form_search{
  width: 50%  !important;
}
@media (max-width: 1900px) {
  .search_form {
    width: 50% ;

    span {
      background-color: $lightBlack5;
      padding: 6px 15px;
      width: 518px;

      svg {
        width: 20px;
        height: 20px;
      }

      input {
        height: 50px;
        font-size: 18px;
      }
    }

    .search_dropdown {
      padding: 18px 20px 18px 10px;
      border-radius: 12px;
      font-size: 18px;
      width: 180px;
      position: relative;

      .arrow {
        border-width: 0 3px 3px 0;
        padding: 4px;
        right: 3.3em;
      }

      .down {
        transform: rotate(45deg) translate(33px, -35px);
      }

      .up {
        transform: rotate(-135deg) translate(-36px, 30px);
      }

      .search_dropdown_menu {
        font-size: 18px;
        z-index: 9999;

        span {
          width: 100%;
          padding: 13px 30px;
          border-radius: 0;

          &:last-child {
            border-radius: 0 0 12px 12px;
          }

          &:hover {
            cursor: pointer;
            background-color: $darkblack;
            transition: background-color 1s;
          }

          &.active {
            cursor: default;
            background-color: $darkblack;
          }
        }
      }
    }
  }
}

@media (max-width: 1680px) {
  .search_form {
    width: 63% ;

    span {
      background-color: $lightBlack5;
      padding: 3px 15px;
      width: 100%;

      svg {
        width: 20px;
        height: 20px;
      }

      input {
        height: 50px;
        font-size: 14px;
      }
    }

    .search_dropdown {
      padding: 14px 20px 14px 10px;
      border-radius: 12px;
      font-size: 16px;
      width: 180px;
      position: relative;

      .arrow {
        right: 3.7em;
      }

      .down {
        transform: rotate(45deg) translate(33px, -35px);
      }

      .up {
        transform: rotate(-135deg) translate(-36px, 30px);
      }

      .search_dropdown_menu {
        font-size: 16px;
        z-index: 9999;
        top: 43px;

        span {
          width: 100%;
          padding: 13px 30px;
          border-radius: 0;

          &:last-child {
            border-radius: 0 0 12px 12px;
          }

          &:hover {
            cursor: pointer;
            background-color: $darkblack;
            transition: background-color 1s;
          }

          &.active {
            cursor: default;
            background-color: $darkblack;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .search_form {
    span {
      background-color: $lightBlack5;
      padding: 2px 10px;
      width: 480px;

      svg {
        width: 16px;
        height: 16px;
      }

      input {
        height: 45px;
        font-size: 16px;
      }
    }

    .search_dropdown {
      padding: 13px 20px 13px 10px;
      font-size: 14px;
      width: 150px;

      .arrow {
        right: 4em;
        border-width: 0 2px 2px 0;
        padding: 3px;
      }

      .down {
        transform: rotate(45deg) translate(33px, -35px);
      }

      .up {
        transform: rotate(-135deg) translate(-36px, 30px);
      }

      .search_dropdown_menu {
        font-size: 14px;
        top: 38px;

        span {
          width: 100%;
          padding: 13px 30px;
          border-radius: 0;

          &:last-child {
            border-radius: 0 0 12px 12px;
          }

          &:hover {
            cursor: pointer;
            background-color: $darkblack;
            transition: background-color 1s;
          }

          &.active {
            cursor: default;
            background-color: $darkblack;
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .search_form {
    width: 53% ;

    span {
      padding: 4px 7px;
      width: 100%;

      svg {
        margin: 0 7px;
        width: 14px;
        height: 14px;
      }

      input {
        height: 40px;
        font-size: 14px;
      }
    }

    .search_dropdown {
      padding: 12px 15px 12px 8px;
      font-size: 12px;
      width: 130px;

      .arrow {
        right: 4.4em;
        border-width: 0 2px 2px 0;
        width: 3px;
      }

      .down {
        transform: rotate(45deg) translate(32px, -33px);
      }

      .up {
        transform: rotate(-135deg) translate(-34px, 30px);
      }

      .search_dropdown_menu {
        font-size: 12px;
        text-align: center;
        top: 33px;

        span {
          width: 100%;
          padding: 13px 30px;
          border-radius: 0;

          &:last-child {
            border-radius: 0 0 12px 12px;
          }

          &:hover {
            cursor: pointer;
            background-color: $darkblack;
            transition: background-color 1s;
          }

          &.active {
            cursor: default;
            background-color: $darkblack;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .search_form {
    width: 50%;

    span {
      padding:7px;
      width: 100%;

      svg {
        margin: 0 7px;
        width: 13px;
        height: 13px;
      }

      input {
        height: 35px;
        font-size: 16px;
      }
    }

    .search_dropdown {
      padding: 10px 15px 10px 8px;
      font-size: 12px;
      width: 130px;

      .arrow {
        right: 4.4em;
        border-width: 0 2px 2px 0;
        width: 3px;
      }

      .down {
        transform: rotate(45deg) translate(32px, -33px);
      }

      .up {
        transform: rotate(-135deg) translate(-34px, 30px);
      }

      .search_dropdown_menu {
        font-size: 12px;
        text-align: center;
        top: 28px;

        span {
          width: 100%;
          padding: 13px 30px;
          border-radius: 0;

          &:last-child {
            border-radius: 0 0 12px 12px;
          }

          &:hover {
            cursor: pointer;
            background-color: $darkblack;
            transition: background-color 1s;
          }

          &.active {
            cursor: default;
            background-color: $darkblack;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .search_form {
    width: 100%;
    margin-bottom: 14px;

    span {
      padding: 7px;
      width: 100%;
      margin: 0;

      svg {
        margin: 0 7px;
        width: 12px;
        height: 12px;
      }

      input {
        height: 35px;
        font-size: 14px;
      }
    }

    .search_dropdown {
      padding: 10px 15px 10px 8px;
      font-size: 12px;
      width: 35%;

      .arrow {
        right: 4.4em;
        border-width: 0 2px 2px 0;
        width: 2px;
      }

      .down {
        transform: rotate(45deg) translate(32px, -33px);
      }

      .up {
        transform: rotate(-135deg) translate(-34px, 30px);
      }

      .search_dropdown_menu {
        font-size: 12px;
        text-align: center;
        top: 27px;

        span {
          width: 100%;
          padding: 13px 30px;
          border-radius: 0;

          &:last-child {
            border-radius: 0 0 12px 12px;
          }

          &:hover {
            cursor: pointer;
            background-color: $darkblack;
            transition: background-color 1s;
          }

          &.active {
            cursor: default;
            background-color: $darkblack;
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .search_form {
    display: flex;
    align-items: center;
    width: 48% !important;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom:10px;

    span {
      background-color: $lightBlack5;
      padding: 2px 0px;
      border-radius: 12px;
      margin: 0 0px 0 0;
      width: 100%;
      display: flex;
      align-items: center;

      svg {
        margin: 0 10px;
      }

      input {
        border: none;
        outline: none;
        background-color: transparent;
        height: 46px;
        width: 100%;
        color: $white;
        font-size: 14px;
      }
    }

    .search_dropdown {
      background-color: $lightBlack5;
      padding: 13px 20px;
      border-radius: 12px;
      font-size: 14px;
      position: relative;
      min-width: 133px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        border: solid $white;
        border-radius: 0;
        border-width: 0 2px 2px 0;
        padding: 3px;
        display: inline-block;
        width: 8px;
      }

      .down {
        transform: rotate(45deg) translate(36px, -37px);
      }

      .up {
        transform: rotate(-135deg) translate(-36px, 37px);
      }

      .search_dropdown_menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: $lightBlack5;
        width: 100%;
        border-radius: 0 0 12px 12px;
        top: 53px;
        right: 0;
        font-size: 14px;
        z-index: 5;
        text-align: center;

        span {
          width: 100%;
          padding: 13px 30px;
          border-radius: 0;

          &:last-child {
            border-radius: 0 0 12px 12px;
          }

          &:hover {
            cursor: pointer;
            background-color: $darkblack;
            transition: background-color 1s;
          }

          &.active {
            cursor: default;
            background-color: $darkblack;
          }
        }
      }
    }
  }
}
@media(max-width:575px){
  .search_form{
    margin: 0 !important;
  }
}
