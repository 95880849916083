.prizeGridChasewrp {
    .__point_system_date_time {
        position: absolute;
        font-size: 12px;
        left: 10px;
        top: 5px;
    }
    .AceCenterLogo {
        width: 75px !important;
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .leftImage {
        width: 175px !important;
        height: 32px;
        margin-top: 30px;
        display: flex;
        margin-left: 15px;
    }
    .__prize_grid_scroll_bar {
        margin-top: 26px;
        margin-bottom: 15px;
       p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: #f2f2f2;
            span {
                color: #fb6e00;
            }
       }
    }
    ul{
        list-style: none;
    margin-top: 25px;
    width: 250px;
        li{
          display: flex;
          align-items: flex-start;
          margin-right: 24px;
          margin-bottom: 10px;
            
          p{
            font-family: Poppins;
    font-size: 14px;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #f2f2f2;
    text-align: left;
          }
          span{
            margin: 0 16px 0 0;
            font-family: Teko;
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #fb6e00;
          }
        }
      }
}