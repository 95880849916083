@import "../../scss/colors.scss";

.list_container {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  margin: 0 auto;

  .list_item {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 2px solid $lightBlack3;

    .verify_button {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      width: 184px;
      height: 42px;
      padding: 9px 46px 10px 47px;
      border-radius: 12px;
      box-shadow: 0 3px 27px -2px rgba(251, 110, 0, 0.38);
      background-color: #fb6e00;
    }

    .edit_input {
      display: inline-block;
      border-radius: 4px;
      background-color: $lightGrey7;
      border: none;
      outline: none;
      padding-left: 5px;

      span {
        padding-right: 5px;
        border-right: 1px solid $grey13;
      }

      input {
        background-color: $grey12;
        border-radius: 4px;
        outline: none;
        border: none;
        color: $grey13;
        padding-left: 5px;
      }
    }

    @media screen and (max-width: 640px) {
      .edit_input {
        display: inline-block;
        border-radius: 4px;
        background-color: $lightGrey7;
        border: none;
        outline: none;
        padding-left: 5px;

        span {
          padding-right: 5px;
          border-right: 1px solid $grey13;
        }

        input {
          background-color: $grey12;
          border-radius: 4px;
          outline: none;
          border: none;
          color: $grey13;
          padding-left: 5px;
        }
      }
      .list_container{
        max-width: 100%;
      }
    }

    button {
      border: none;
      outline: none;
      background-color: transparent;
      color: $primary;
      margin: 0 0 0 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .list_item:last-child {
    border-bottom: none;
  }

  .__delete_account_modal {
    width: 600px;
    height: 400px;
    border-radius: 12px;
    box-shadow: 0 2px 24px 0 $black5;
    background-color: $lightBlack;

    .__close_icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      padding-top: 10px;
      margin-top: 20px;
      margin-right: 20px;
      cursor: pointer;
    }

    .__confirmation_message_div {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .__message {
        width: 400px;
        font-family: Teko;
        font-size: 42px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
      }
    }

    .__confirmation_info_div {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .__info {
        width: 531px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
      }
    }

    .__input_and_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 54px;

      .__password_input {
        width: 280px;
        height: 48px;
        margin: 0 20px 0 0;
        padding: 12px 0px 13px 12px;
        border-radius: 8px;
        background-color: $lightGrey7;
        border: none;
        outline: none;
        opacity: 0.4;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
      }

      .__delete_account_btn {
        height: 48px;
        padding: 0px 23px 0px 24px;
        border-radius: 8px;
        border: solid 1px $grey2;
        background-color: $lightBlack;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $grey2;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .__change_password_modal {
    width: 600px;
    height: 550px;
    border-radius: 12px;
    box-shadow: 0 2px 24px 0 $black5;
    background-color: $lightBlack;

    .__close_icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      padding-top: 10px;
      margin-top: 20px;
      margin-right: 20px;
      cursor: pointer;
    }

    .__confirmation_message_div {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .__message {
        width: 400px;
        font-family: Teko;
        font-size: 42px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
      }
    }

    .__info {
      align-self: center;
      width: 531px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }

    .__input_and_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 54px;

      .__password_input {
        width: 280px;
        height: 48px;
        margin: 0 20px 0 0;
        padding: 12px 0px 13px 12px;
        border-radius: 8px;
        background-color: $lightGrey7;
        border: none;
        outline: none;
        opacity: 0.4;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
      }

      .__delete_account_btn {
        height: 48px;
        padding: 0px 23px 0px 24px;
        border-radius: 8px;
        border: solid 1px $grey2;
        background-color: $lightBlack;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $grey2;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .__alertMsg {
    font-size: 16px;
  }

  @media screen and (max-width: 640px) {
    .__delete_account_modal {
      width: 90vw;
      min-height: 350px;
      border-radius: 12px;
      box-shadow: 0 2px 24px 0 $black5;
      background-color: $lightBlack;

      .__close_icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        padding-top: 10px;
        margin-top: 20px;
        margin-right: 20px;
        cursor: pointer;
      }

      .__confirmation_message_div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding: 0 20px;

        .__message {
          width: 400px;
          font-family: Teko;
          font-size: 28px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $white;
        }
      }

      .__confirmation_info_div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding: 0 20px;

        .__info {
          width: 531px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $white;
        }
      }

      .__input_and_btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 54px;
        padding: 0 20px;

        .__password_input {
          width: 100%;
          height: 48px;
          margin: 0 0 20px 0;
          padding: 12px 0px 13px 12px;
          border-radius: 8px;
          background-color: $lightGrey7;
          border: none;
          outline: none;
          opacity: 0.4;
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }

        .__delete_account_btn {
          height: 48px;
          width: 100%;
          padding: 0px 23px 0px 24px;
          border-radius: 8px;
          border: solid 1px $grey2;
          background-color: $lightBlack;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $grey2;
          outline: none;
          cursor: pointer;
        }
      }
    }

    .__change_password_modal {
      width: 90vw;
      min-height: 350px;
      overflow-y: auto;
      border-radius: 12px;
      box-shadow: 0 2px 24px 0 $black5;
      background-color: $lightBlack;

      .__close_icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        padding-top: 10px;
        margin-top: 20px;
        margin-right: 20px;
        cursor: pointer;
      }

      .__confirmation_message_div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding: 0 20px;

        .__message {
          width: 400px;
          font-family: Teko;
          font-size: 28px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $white;
        }
      }

      .__input_and_btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 24px;
        padding: 0 20px;

        .__info {
          width: 100%;
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $white;
          text-align: start;
          margin-bottom: 5px;
        }

        .__password_input {
          width: 100%;
          height: 48px;
          padding: 12px 0px 13px 12px;
          border-radius: 8px;
          background-color: $lightGrey7;
          border: none;
          outline: none;
          opacity: 0.4;
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }

        .__delete_account_btn {
          width: 100%;
          height: 48px;
          padding: 0px 23px 0px 24px;
          border-radius: 8px;
          border: solid 1px $grey2;
          background-color: $lightBlack;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $grey2;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .list_container{
    max-width: 100%;
  }
}