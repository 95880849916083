@import "../../scss/colors.scss";

.__contest_rules {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 14px;

  .__contest_rules_main_title {
    width: 100%;
    height: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey2;
    text-decoration: underline;
    text-align: center;
  }

  .__contest_rules_main_title_pwrs{
    width: 100%;
    height: 23px;
    margin-bottom: 15px;
    font-family: Poppins;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey2;
    text-decoration: none;
    text-align: left;
    font-size: 18px;
    color: #f2f2f2;
  }

  .__contest_rules_main_title_pwrs_chase, .__contest_rules_main_title_chase {
    margin: 0 98px 4px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f2f2f2;
  }

  ul {
    list-style-image: url("../../assets/correct-copy-3.png");
    li {
      margin: 15px 0px;
      &::marker {
        height: 10px;
      }
      span {
        color: #688fbd;
      }
    }
  }
  .pwrs {
    list-style-image: url("../../assets/correct-copy-4.png");
  }
  a {
    color: #688fbd !important;
  }
  a.orangeColor {
    color: #fb6e00 !important;
  }
}
.__contest_rules_gamecenter {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 12px;
  text-align: left;
  .__pwers_header_title {
    width: 129px !important;
    height: 38px;
    margin: 6px 1px 4px 40px;
    object-fit: contain;
    position: absolute;
    right: 12px;
    top: 14px;
  }
  .__point_system_date_time {
    width: 100%;
    height: 17px;
    margin-top: 10px;
    text-shadow: 0 2px 4px $black5;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey11;
    text-align: left;
  }
  .__contest_rules_main_title {
    width: 100%;
    height: 23px;
    //margin-top: 10px;
    margin-bottom: 15px;
    font-family: Poppins;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey2;
    text-decoration: none;
    text-align: left;
    font-size: 18px;
    color: #f2f2f2;
    &.__contest_rules_main_title_nopadding {
      margin-top: 0px;
    }
    .subtitle {
      opacity: 0.5;
      font-size: 14px;
    }
  }

  ul {
    list-style-image: url("../../assets/correct-copy-3.png");
    padding-left: 20px;
    &.pwrs {
      list-style-image: url("../../assets/correct-copy-4.png");
    }
    li {
      margin: 10px 0px;
      &::marker {
        height: 10px;
      }
      span {
        color: #688fbd;
      }
    }
  }
  
  a {
    color: #688fbd !important;
    text-align: right !important;
    text-decoration: underline !important;
    display: block;
    margin-top: 20px;
  }
}
