@import "../../scss/colors.scss";

.__power_center_card {
  width: 337px;
  height: 260px;
  border-radius: 8px;
  background-color: $black2;

  .__power_center_card_container {
    display: flex;
    flex-direction: row;
    .fantasyCard {
      .__power_center_card_date_time_fantasy {
        width: 100%;
        height: 17px;
        margin-top: 10px;
        text-shadow: 0 2px 4px $black5;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: $grey11;
        text-align: left;
        margin-left: 15px;
        margin-top: 7px;
      }
      .__power_center_card_you_have_power {
        right: 0;
        top: 0;
        border-radius: 0px 8px 0px 12px;
        font-family: Teko;
        width: fit-content;
        color: #f2f2f2;
        position: absolute;
        background-color: #d8d8d81a;
        font-size: 18px;
        line-height: 16px;
        text-align: right;
        padding: 9px 16px 5px 9px;
      }
      .__my_game_center_card_prize_pool_text {
        opacity: 0.6;
        font-weight: normal !important;
        font-family: Poppins;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.59;
        letter-spacing: normal;
        color: #f2f2f2;
        text-align: left;
        padding-top: 13px;
        padding-left: 15px;
      }
      .__power_center_card_powerdfs_title {
        /*width: 150px; */
        /* height: 28px; */
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fb6e00;
        padding-left: 15px;
        margin-top: 0px;
        text-align: left;
        .__power_center_card_powerdfs_title_first {
          color: $grey2;
        }
      }
      .__power_center_card_prize_pool {
        .manage_your_team_to {
          width: 129px;
          height: 32px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: bold !important;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $primary;
          margin-left: 15px;
          margin-top: 6px;
          text-align: start;
        }
        .__power_center_card_prize_pool_price {
          font-family: Poppins;
          font-size: 34px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.59;
          letter-spacing: normal;
          color: #f2f2f2;
          text-align: left;
          padding-top: 13px;
          padding-left: 15px;
          font-weight: bold !important;
        }
      }
      .__power_center_card_enter {
        button {
          width: 305px;
          height: 38px;
          margin: 0 auto;
          margin-left: 15px;
          margin-top: 20px;
        }
      }
      .__power_center_card_game_id {
        font-family: Poppins;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: rgba(242, 242, 242, 0.75);
        position: absolute;
        bottom: 0px;
        left: 15px;
      }
    }
    .__power_center_card_content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      padding-right: 10px;

      .__pwers__date_time {
        position: absolute;
        right: 12px;
        top: 61px;
        font-family: Poppins;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: rgba(242, 242, 242, 0.8);
      }
      .__pwers_title {
        width: 123px;
        height: 52px;
        margin: 0 51px 20px 5px;
        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
        font-family: Teko;
        font-size: 26px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #fb6e00;
        text-align: left;
        margin-left: 22px;
      }
      .____PWRSCardInfo {
        text-align: left;
        margin: 0 22px;
        ul {
          padding: 0;
          list-style: none;
        }

        ul li {
          margin-bottom: 10px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
        }

        ul li img {
          height: 20px;
          width: 20px;
          object-fit: cover;
          margin-right: 10px;
        }
      }
      .__power_center_card_game_id {
        font-size: 11px;
        color: #f2f2f2;
        opacity: 0.6;
      }
      .__power_center_card_date_time {
        width: 100%;
        height: 17px;
        margin-top: 10px;
        text-shadow: 0 2px 4px $black5;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: $grey11;
        text-align: left;
        margin-left: 15px;
        margin-top: 15px;
      }

      .__power_center_card_powerdfs {
        display: flex;
        justify-content: center;
        margin-top: 0;
        margin-left: 9px;

        .__power_center_card_powerdfs_title {
          width: 150px;
          height: 28px;
          font-family: Poppins;
          font-size: 20px;
          font-weight: bold !important;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $primary;

          .__power_center_card_powerdfs_title_first {
            color: $grey2;
          }
        }
      }

      .__power_center_card_prize_pool {
        .__power_center_card_prize_pool_common {
          width: 100%;
          height: 40px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: $grey2;
        }

        .__power_center_card_prize_pool_price {
          font-size: 34px;
          font-weight: bold !important;
          margin-bottom: 5px;
        }

        .__power_center_card_prize_pool_text {
          opacity: 0.6;
        }
      }

      .__power_center_card_enter {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }

      .__power_center_card_total {
        p {
          width: 100%;
          height: 25px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $white;

          span {
            font-size: 14px;
            font-weight: 300;
            opacity: 0.6;
            img {
              width: 22px;
              vertical-align: 0;
            }
          }
        }
      }
    }
    .AceCenterLogo {
      width: 135px;
      height: 75px;
      margin: 0px 9px 5px 102px;
      object-fit: contain;
      box-shadow: 0 0 24px 0 #000;
    }
    .prizePoolAce {
      width: 57px;
      height: 16px;
      margin: 0 auto;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #f2f2f2;
    }
    .prizeAce {
      margin: 5px 0 0;
      font-family: Teko;
      font-size: 32px;
      font-weight: bold !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #fb6e00;
    }
    .bottomCornerText {
      color: #fff;
      text-align: end;
      padding-right: 10px;
      position: absolute;
      right: 0;
      bottom: 0px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: right;
    }
    .__power_center_card_total_ace {
      position: absolute;
      left: 15px;
      top: 5px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #fff;
    }
    .cardsRemaining {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: right;
      color: #fff;
      padding-right: 10px;
    }
  }

  .__power_center_card_container_pwrs {
    display: flex;
    flex-direction: column;
    .__power_center_card_date_time_pwrs {
      color: #f2f2f2bf;
      text-align: left;
      margin: 16px;
    }
    .__PWRSEnterImage {
      position: absolute;
      width: 68px;
      height: 78px;
      object-fit: cover;
      top: 10px;
      right: 10px;
    }
    .__PWRSBannerImageDiv {
      width: 100%;
      .__PWRSBannerImage {
        width: 210px;
        height: 74px;
        margin: 40px auto 0px;
        object-fit: contain;
      }
      p {
        color: #111;
        transform: rotate(-4deg);
        position: absolute;
        top: 115px;
        left: 110px;
        font-family: Poppins;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        span {
          color: #fff;
        }
      }
    }
    .__PWRS_prize_pool_prize {
      line-height: 34px;
      font-size: 32px;
      font-weight: bold !important;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin: 5px !important;
      }
    }
    .__PWRS_prize_pool_text {
      margin: 0px 55px 0px 62px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #f2f2f2;
    }
    .__pwrs_card_total {
      margin-top: 15px;
      p {
        width: 100%;
        height: 25px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
        text-align: left;
        margin-left: 16px;

        span {
          font-size: 14px;
          font-weight: 300;
          opacity: 0.6;
          img {
            width: 22px;
            vertical-align: 0;
          }
        }
      }
    }
  }
}

.__pwers_header_title {
  img {
    position: absolute;
    width: 100%;
    height: 62px;
    margin: 6px 1px 4px 40px;
    object-fit: contain;
    top: 0px;
    left: 49px;
  }
  p {
    position: absolute;
    right: 25px;
    top: 24px;
    transform: rotate(-4deg);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }
}
