@import '../../scss/colors.scss';

.__liveGame{
    margin: 1.8em 0 1.8em 1.8em;
    .__main{
        box-shadow: 0 4px 8px 0 rgba(4, 4, 4, 0.4);
        border-radius: 5px;
        background-color: #202124;
        border: 2px solid $primary;
        padding: 1.2em 1.2em 1em 1.2em;
        display: grid;
        grid-template-columns: 1fr auto 1fr auto 1fr;
        grid-gap: 2em;
        .__border{
            border: solid 1px #111;
            height: 120px;
        }
    }
    .__LiveGame-btn{
        padding: 3px 1em;
        border-radius: 1em;
        border: 1.5px solid;
        text-align: center;
        &:first-child{
            color: $primary;
        }
        &:nth-child(2){
            background: linear-gradient(to left, rgba(242, 242, 242, 0.8), #f2f2f2 0%);
            color: #202124;
            border: 1px solid #f2f2f2;
        }
        &:nth-child(3){
            background: linear-gradient(to left, #fa3800, #fb6e00 0%);
            border-color: transparent;
        }
    }
    .__board{
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 0.5em;
        align-items: center;
        .__pts{
            display: none;
        }
        .__title-wrapper{
            grid-column: 1/3;
            margin-bottom: 0.2em;
            .__title{
                text-decoration: underline;
            }
            svg{
                width: 1.2em;
                margin-right: 0.2em;
            }
        }
        &.__score-board{
            .__title{
                text-decoration: none;
            }
        }
        &.__deactive{
            color: rgba(242, 242, 242, 0.6);
            align-items: flex-start;
            grid-gap: 0;
            >.__no-wagar{
                grid-row: span 4;
            }
            .__pts{
                display: block;
                margin-left: 0.3em;
            }
        }   
    }
    .__vs{
        -webkit-text-stroke: 1px #f2f2f2;
        text-stroke: 1px #f2f2f2;
        color: transparent;
        font-family: Teko;
        text-shadow: 0 2px 6px rgba(4, 4, 4, 0.5);
        margin: auto;
    }
    .__live-footer{
        border-top: 2px solid #111111;
        grid-column: 1/6;
        margin: 0 5%;
        color: rgba(242, 242, 242, 0.6);
        font-family: 'IBM Plex Sans', sans-serif;
        padding-top: 1.2em;
        >div{
            >div{
                &:first-child{
                    margin: 0 1em 0.1em 1em;
                }
                &:nth-child(2){
                    padding: 0.1em 0.5em;
                    color: #fff;
                    background: rgba(242, 242, 242, 0.2);
                    border-radius: 1em;
                }
            }
        }
    }
    .__total-wagered{
        margin: 4.2em 2.2em auto 2.2em;
    }
}