@import "../../scss/colors.scss";

.blur {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 9999;
}

body {
  position: relative;
}

.root {
  z-index: 10;
  display: flex;
  color: #0000;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
}

.contentWrapper {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background-color: #ffffff;
  border-radius: 0.43rem;
  width: 76.643rem;
  overflow: hidden;
  margin: auto;
}

.header {
  padding: 1rem 2.85rem;
  background: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.main {
  padding: 2.85rem;
  display: flex;
  justify-content: space-between;
  color: #000;
}

.mainTitle {
  font-size: 1.857rem;
  margin: 0;
  line-height: 1em;
  font-weight: 500;
}

.formSection h6,
.QRCodeWrapper h6,
.title,
.title2 {
  color: $grey9;
  font-size: 16px;
  margin: 0 0 0.375rem 0;
}

.title2 {
  color: $primary;
}

.cardSectionn {
  margin-bottom: 2.857rem;
  color: #000;
}

.crossicon {
  font-size: 1rem;
  cursor: pointer;
  padding: 1em;
  border-radius: 50%;
  transition: 0.2s all;
  position: relative;
  right: -1em;

  &:hover {
    background: rgba($color: $lightGrey6, $alpha: 0.2);
  }

  &:active {
    background: $lightGrey6;
  }

  span {
    line-height: 1em;
    width: 1em;
    height: 1em;
    display: block;
    position: relative;

    &::after,
    &::before {
      content: "";
      height: 100%;
      position: absolute;
      width: 0.072em;
      background: #fff;
      left: 50%;
      top: 0;
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(135deg);
    }
  }
}

.customCloseIcon {
  
  &::after,
  &::before {
    background: #212529 !important;
  }
}

.form {
  width: initial;
  max-width: initial;
  margin: 0;
}

.formSection {
  margin-bottom: 4.285rem;
  color: #000;

  >div {
    display: flex;
    padding-left: 0.5rem;

    >* {
      margin-right: 0.7143rem;
    }
  }

  .Coingate {
    width: 4.285rem;
    margin: auto 2.142rem;
  }
}

.creditdebitcardImg {
  width: 2.857rem;
  margin: 0.6rem 0;
}

// .visaCard {
//   width: 16px;
//   height: 20px;
//   background-color: #293688;
// }
.PayPal,
.ECheck,
.cvvImage,
.cvvInput {
  max-width: 5.714rem;
}

.BitCoin,
.EFTImage,
.interactImage {
  width: 3.428rem;
}

.cardDetails {
  display: block;

  div {
    justify-content: space-between;
    display: flex;
    margin-bottom: 0.3rem;
  }

  input {
    border: solid 0.0714rem $lightGrey2;
    border-radius: 0.125rem;
    outline: none;
    background: #fff;
    display: block;
    width: 100%;
    padding: 0.7142rem 0 0.7142rem 1rem;
    // background: url('../../assets/visa.png') no-repeat 0.857rem center;
    background-size: auto 0.929rem;
    color: $lightBlack;
    margin-bottom: 10px;
  }

  select {
    border: solid 0.0714rem $lightGrey2;
    border-radius: 0.125rem;
    outline: none;
    background: #fff;
    display: block;
    width: 100%;
    padding: 0.7142rem 0 0.7142rem 1rem;
    // background: url('../../assets/visa.png') no-repeat 0.857rem center;
    background-size: auto 0.929rem;
    color: $lightBlack;
    margin-bottom: 10px;
  }

  a {
    color: $primary;
  }
}

.buyCoinBtn,
.submitbtn {
  height: 3.6rem;
  border-radius: 0.571rem;
  font-weight: 600;
  position: relative;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-top: auto;

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    padding-top: 100%;
    top: 50%;
    transition: 0.2s all;
    transform: translateY(-50%) scale(0);
  }

  &:hover::before {
    background: rgba($color: #fff, $alpha: 0.05);
    transform: translateY(-50%) scale(1);
  }

  &:active::after {
    background: rgba($color: $primary, $alpha: 0.1);
    transform: translateY(-50%) scale(1);
  }
}

.submitbtn {
  width: 22.14rem;
  background: $primary;
  border: 1px solid $primary;
}

.submitbtn:disabled{
  background-color: #ffb47a;
  border: 1px solid #ffb47a;
}

.buyCoinBtn {
  border: 1px solid;
  color: #688fbd;
  min-width: 16.43rem;
  margin-right: auto;
  background: transparent;
}

.QRCodeWrapper {
  >div {
    display: grid;
    grid-template-columns: 10.857rem 1fr;
    grid-gap: 2rem;

    .qrImage {
      grid-row: span 2;
      width: 100%;
    }
  }
}

.inputField {
  position: relative;

  label {
    line-height: 1em;
    margin-bottom: 0.4em;
    display: block;
  }

  input {
    border: solid 0.0714rem $lightGrey2;
    border-radius: 0.142rem;
    outline: none;
    background: #fff;
    display: block;
    width: 100%;
    padding: 0.7142rem 1rem;
    background-size: auto 0.929rem;
    color: $lightBlack;

    &:focus {
      border-color: $primary;
    }
  }
}

.copyImage {
  position: absolute;
  z-index: 1;
  top: 2.16rem;
  right: 0.7142rem;
  width: 1.428rem;
  cursor: pointer;
}

.leftsidebg{
  background-color: #fafafa;
}

.leftSection {
  // width: 20rem;
  // background-color: #fafafa;
  padding: 2.5rem;
}

.cvvImage {
  margin-left: 1rem;
}

.currentBalanceTitle {
  font-size: 1.571rem;
  color: $primary;
  font-weight: 700;
  margin: 0;
  line-height: 1em;
}

.creditCardDetails {
  margin: 2.5rem 0 2.857rem 0;
}
.updateDepositSection h4{
  font-weight: 500;
}
.RightSideSection{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}
.submitbtnlabel{
  padding-top: 1.25rem;
  opacity: 0.6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #202124;
}

.card_wrp{
  position: relative;
  padding-bottom: 3.75rem;
}
.card_field{
  position: relative;
  margin-bottom: 1.25rem;
}
.card_field h6{
  color: #7f7f7f;
  font-size: 16px;
  margin: 0 0 0.375rem 0;
  white-space: pre;
}
.card_field input{
  width: 100%;
  height: auto;
  border: solid 0.0715rem #c7cad1;
  border-radius: 0.125rem;
  color: #c7cad1;
  padding: 0.625rem;
}
.card_field select{
  width: 100%;
  height: auto;
  border: solid 0.0715rem #c7cad1;
  border-radius: 0.125rem;
  color: #c7cad1;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background: url(../../assets/arrow-down.png) no-repeat;
    background-position: 96%;
}
.card_field input::placeholder,
.card_field select::placeholder{
  color: #c7cad1;
}
.card_field input:focus,
.card_field select:focus{
  outline: none;
}
.weaccept{
  display: flex;
}
.weaccept span{
  width: 5.8rem;
  height: 2.9rem;
  background-color: #fff;
  border: solid 0.0715rem #c7cad1;
  border-radius: 0.125rem;
  display: flex;
  margin-right: 0.625rem;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

@media (max-height: 800px) {
  .root {
    position: absolute;
    margin: 3rem 0;
  }

  .contentWrapper {
    margin: auto auto 0 auto;
    bottom: 3rem;
  }
}


.customizeBackBtn {
  top: 15px;
  left: 15px;

  &:hover {
    text-decoration: none;
    box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.225);
  }

  &.btnFlat {
    box-shadow: none !important;
    background-color: transparent !important;

    &:hover {
      background-color: #dedede !important;
      box-shadow: none !important;
    }

    &.disabled:hover,
    &[disabled]:hover {
      background-color: transparent !important;
    }
  }
}


.customDepositBtn {
  bottom: -43px !important;
  color: #111111 !important;
  font-weight: bold;
}

.despositeModalWrp {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 80px 30px 120px 30px;
}

.despositeModalWrp .successPic {
  display: block;
  margin-bottom: 20px !important;
}
.despositeModalWrp .successDesc h3{
  color: #fb6e00;
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.despositeModalWrp .successDesc h2{
  color: #111111;
  font-family: Poppins;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 30px 0;
}
.despositeModalWrp .successDesc p{
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #111111;
}

.despositeModalWrp .successBtn{
margin-top: 30px;
text-align: center;
}
.despositeModalWrp .successBtn button{
  border: transparent;
  background-color: #fb6e00;
  display: inline-block;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 15px 169px 14px 168px;
}

.errorMessage{
  color: red !important;
  font-size: 12px;
  text-align: right;
  @media (max-height: 1199px) {
    text-align: left;
  }
}

@media (min-width:481px) {
  .customDepositBtn {
    bottom: -75px !important;
  }
}


.depositSection {
  text-align: center;
  padding: 132px 15px 170px 15px;
  background-color: #000000;
  .depositSectionInner {
    // position: relative;
    .depositSectionIcon {
      // position: relative;
    }
  }
  .depositSectionDetail {
    padding: 70px 0;
    h3 {
      font-size: 32px;
      color: #fb6e00;
      font-weight: bold;
      margin-bottom: 60px;
      line-height: normal;
    }
    h4 {
      font-size: 22px;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 25px;
    }
    p {
      font-family: Poppins;
      font-size: 18px;
      line-height: 2.11;
    }
  }
  .depositSectionButton {
    max-width: 340px;
    margin: 0 auto;
    width: 100%;
    .depositButton {
      width: 100%;
    color: #111;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 15px 42px 14px 41px;
    border-radius: 8px;
    background: #fb6e00;
    border: none;
    }
    .gameCenterButton {
      width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #f2f2f2;
    background: transparent;
    padding: 16px 42px 16px 41px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    margin-top: 20px;
    }
  }
}
