.modalWrapper {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.3);
  background-color: #44464d;

  padding: 30px 18px;
  width: 347px;
  height: 240px;

  h1 {
    font-family: Teko;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: #fb6e00;
  }

  p {
    font-family: Teko;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: #f2f2f2;
  }

  .close__box {
    position: absolute;
    top: 25px;
    right: 25px;
    color: #f2f2f2;
    font-size: 15px;
    cursor: pointer;
  }
}
