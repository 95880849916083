@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko&display=swap");

@import "./colors.scss", "./flex.scss", "./layouts.scss", "./styles.scss",
  "./stylescolors.scss", "./custom.scss";

@font-face {
  font-family: "Gotthard";
  src: url("../assets/fonts/gotthard.ttf");
}

@font-face {
  font-family: "StereoGothic";
  src: url("../assets/fonts/Stereo Gothic W01 800.ttf");
}

* {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none !important;
}
html,
body {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background: $black;
  color: #fff;
  background-color: #202124;
}
.__h2,
.__h3,
.__h4,
.__h5 {
  font-weight: 500;
}
.__small-line-height {
  line-height: 1.2em;
}
.__h2 {
  font-size: 2.85em;
}
.__h3 {
  font-size: 30px;
}
.__h3s {
  font-size: 28px;
}
.__h4 {
  font-size: 24px;
}
.__h5 {
  font-size: 20px;
}
.__h6 {
  font-size: 18px;
}
.__light-bold {
  font-weight: 500;
}
.__light {
  font-weight: 300;
}
.__bold {
  font-weight: 600;
}
.__bolder {
  font-weight: 700;
}
.__mediam {
  font-size: 16px;
}
.__small {
  font-size: 15px;
}
.__smaller {
  font-size: 12px;
}
.__light-black-color {
  color: $lightBlack;
}
.__default-font-size {
  font-size: 14px;
}
.__defaul-font-weight {
  font-weight: 400;
}
.__text-in-one-line {
  white-space: nowrap;
}

@import "./responsive.scss";

@media (min-width: 1800px) {
  html,
  body {
    font-size: 18px;
  }
}
input,
button {
  font-family: inherit;
}

.__font-family-teko {
  font-family: Teko;
}
.__letter-spacing {
  letter-spacing: 0.46rem;
}
.main-title {
  font-size: 4.42rem;
}
.__title-1 {
  font-size: 3rem;
}
.title-2 {
  font-size: 2.286rem;
}
.__title-3 {
  font-size: 1.714rem;
}
.__title-4 {
  font-size: 1.286rem;
}
.__title-5 {
  font-size: 1.428rem;
}
.__title-6 {
  font-size: 1.143rem;
}
.modal-animation {
  animation: 0.3s Modal forwards;
}
@keyframes Modal {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.carousel .slider-wrapper.axis-horizontal .slider li:nth-child(1){
  background: none;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  // background: rgba(0,0,0,0.2);
  background: none;
  padding-bottom: 15px;
}

.disabled {
  opacity: 0.5;
}

.section-nav li.active > a {
	color: #fb6e00;
	font-weight: 500;
}

/* 3. ScrollSpy active styles (see JS tab for activation) */


