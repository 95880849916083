@import '../../scss/colors.scss';

.__prize_grid {
    .__pwers_header_title {
        width: 129px !important;
        height: 38px;
        margin: 6px 1px 4px 40px;
        object-fit: contain;
        position: absolute;
        right: 12px;
        top: 14px;
    }

    .__prize_grid_date_time {
        width: 100%;
        height: 17px;
        margin-top: 10px;
        text-shadow: 0 2px 4px $black5;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey11;
        text-align: left;
        padding-left: 20px;
    }

    .__prize_grid_powerdfs {
        display: flex;
        flex-direction: row;
        margin-left: -17px;

        .__prize_grid_powerdfs_title {
            width: 150px;
            height: 28px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $primary;

            .__prize_grid_powerdfs_title_first {
                color: $grey2;
            }

            .__prize_grid_title {
                width: 65px;
                height: 16px;
                font-family: Poppins;
                text-shadow: 0 2px 4px $black5;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: $grey2;
                opacity: 0.5;
            }
        }
    }

    .__prize_grid_data_container {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        margin-left: -17px;

        .__prize_grid_data_content {
            flex: 1;
            .__prize_grid_data {
                display: flex;
                flex-direction: row;
                padding-bottom: 5px;
                
                .__prize_grid_data_title_div {
                    display: flex;
                    flex: 1;
        
                    .__prize_grid_data_title {
                        width: 100%;
                        height: 18px;
                        margin-bottom: 5px;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.29;
                        letter-spacing: normal;
                        color: $grey2;
                        margin-left: 22px !important;
                        opacity: 0.6;
                    }
                }
        
                .__prize_grid_data_value_div {
                    display: flex;
                    flex: 1;
        
                    .__prize_grid_data_value {
                        width: 100%;
                        height: 18px;
                        margin-bottom: 5px;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 600 !important;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.29;
                        letter-spacing: normal;
                        color: $lightBlue;
                    }
                }
            }
        }
    }

    .__my_game_center_card_powerdfs {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        padding: 0px 20px;
    
        .__my_game_center_card_powerdfs_title {
          // width: 150px;
          height: 28px;
          margin: 10 13px 20px;
          font-family: Poppins;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: start;
          color: $primary;
    
          .__my_game_center_card_powerdfs_title_first {
            color: $grey2;
          }
          .__my_game_center_card_powerdfs_subtitle {
            opacity: 0.6;
          }
        }
      }
}

