.thankYouDiv{
    width: 600px;
    height: 400px;
    margin: 7px 420px 45px 21px;
    padding: 24px 24px 112px 106px;
    border-radius: 12px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    background-color: #2d2f33;
    position: absolute;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
        position: absolute;
        top: 85px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .title {
        width: 388px;
        height: 40px;
        margin: 200px 62px 17px 0;
        font-family: Teko;
        font-size: 40px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }
    .subtitle {
        width: 342px;
        height: 23px;
        margin: 17px 85px 0 23px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }
}

@media (max-width: 414px) {
    .thankYouDiv{
        width: 95%;
        height: 400px;
        margin: 7px 420px 3px 0px;
        padding: 0px 0px 0 0px;
        border-radius: 12px;
        //box-shadow: 0 2px 24px 0 rgb(0 0 0 / 50%);
        background-color: #2d2f33;
        position: absolute;
        top: 400px;
        left: 50%;
        transform: translate(-50%, -50%);
        .closeButton {
            position: absolute;
            right: 30px;
            top: 25px;
        }
        img {
            position: absolute;
            top: 85px;
            left: 50%;
            transform: translate(-50%, 0);
        }
        .title {
            width: auto;
            height: 40px;
            margin: 210px auto 0px;
            font-family: Teko;
            font-size: 40px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 42px;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
        }
        .subtitle {
            width: 200px;
            height: 23px;
            margin: 0 auto;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
        }
    }
}