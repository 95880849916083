@import "../../scss/colors.scss";

.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}
.__select_container {
  color: #000000;
  margin: 1.1em 0;
  label {
    margin-bottom: 0.5em;
    display: block;
    font-weight: 500;
  }
  select {
    padding: 1.22em 1.4em;
    height: 4.071rem;
    font-family: inherit;
    outline: none;
    appearance: none;
    width: 100%;
    border: 1px solid #e0e0e0;
    background: url("../../assets/down-arrow.png") $white no-repeat calc(100% - 1.5rem) center;
    background-size: 0.8rem;
    border-radius: 0.3em;
    &:focus{
      border-color: $primary;
    }
    @media (max-width: 550px) {
      padding: 1.22em 0.9em;
    }
  }
  .white {
    background-color: $white;
  }
  
}
