@import '../../scss/colors.scss';

.__view_results {
    width: 280px;
    height: 380px;
    border-radius: 8px;
    background-color: $black2;
    display: flex;
    flex-direction: column;
    position: relative;

    .__view_results_header {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .__view_results_header_hr {
            width: 30px;
            height: 1px;
            border: solid 2px $grey2;
        }

        .__view_results_header_hr_left {
            margin: 14px 13px 13px 0;
        }

        .__view_results_header_hr_right {
            margin: 14px 0 13px 13px;
        }

        .__view_results_header_title {
            width: 150px;
            height: 28px;
            margin: 10 13px 20px;
            font-family: Poppins;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $primary;

            .__view_results_header_title_first {
                color: $grey2;
            }
        }
    }

    .__view_results_main_title {
        width: 100%;
        height: 17px;
        margin-bottom: 10px;
        text-shadow: 0 2px 4px $black5;
        font-family: Poppins;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $grey11;
    }

    .__view_results_sub_title {
        width: 100%;
        height: 23px;
        margin-bottom: 10px;
        margin-left: 20px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
        text-decoration: underline;
    }

    .__view_results_data {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;

        .__view_results_data_number_div {
            
            p {
                width: 100%;
                height: 18px;
                margin-bottom: 6px;
                font-family: Poppins;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: $grey2;
                text-align: left;
            }
        }

        .__view_results_data_title_div {
            flex: 1;
            margin-left: 10px;

            p {
                width: 100%;
                height: 18px;
                margin-bottom: 6px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: normal;
                color: $grey2;
                text-align: left;
            }
        }

        .__view_results_data_value_div {
            
            p {
                width: 100%;
                height: 18px;
                margin-bottom: 6px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: normal;
                color: $lightBlue;
                text-align: right;
            }
        }

        .__view_results_data_arrow_div {
            width: 14px;
            height: 14px;
            margin: 2px 0 7px 3px;
            border-radius: 50%;
            line-height: 14px;
            text-align: center;
            color: $black;
            background-color: $lightBlue;
        }
    }

    .__view_results_back_link {
        display: flex;
        flex-direction: row;
        margin-right: 20px;
        margin-top: 7px;
        margin-left: 15px;
        flex: 1;

        .__view_results_back_link_text {
            height: 17px;
            margin: 0 0 0 8px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $primary;
            text-decoration: underline;
            cursor: pointer;
        }

        .__view_results_back_link_arrow {
            width: 4px;
            height: 6px;
            color: $primary;
        }
    }
}