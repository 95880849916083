@import "../../scss/colors.scss";

.__page_header {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-top: 100px;
  padding: 0 66px;
  justify-content: space-between;
  align-items: center;

  small {
    font-size: 18px;
  }

  .__page_header_leftside {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 56px;
      margin: 0;
      padding: 0;
    }

    p {
      font-size: 32px;
      margin: 0;
      padding: 0;
    }

    span {
      color: $primary;
    }

    .__page_header_leftside_sub {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .__page_header_btn {
      border-radius: 34px;
      height: 39px;
      font-size: 16.2px;
      width: 196px;
      text-transform: uppercase;
      margin: 0 1rem;
    }

    .__page_header_btn_new {
      width: 196px;
      height: 39px;
      margin: 0 1rem;
      border-radius: 8px;
      background-color: rgba(251, 110, 0, 0.15);
      text-transform: uppercase;
      border-width: 0px;
      color: $primary;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .__page_header_three_dots {
      background: transparent;
      width: 20px;
      outline: none;
      border: none;
      padding: 0;
      display: none;
      
      > span {
        width: 6px;
        height: 6px;
        margin: 0 0 6px;
        border-radius: 3px;
        background-color: $primary;
        display: block;
        margin-left: 7px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .__page_header {
    display: block;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 30px;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  
    small {
      font-size: 18px;
    }
  
    .__page_header_leftside {
      display: flex;
      flex-direction: row;

      .__page_header_leftside_mobile {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex: 3;
      }
  
      h1 {
        font-size: 38px;
        margin: 0;
        padding: 0;
      }
  
      p {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
  
      span {
        color: $primary;
      }
  
      .__page_header_leftside_sub {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
  
      .__page_header_btn {
        border-radius: 34px;
        height: 39px;
        font-size: 16.2px;
        width: 196px;
        text-transform: uppercase;
        margin: 0 1rem;
      }
  
      .__page_header_btn_new {
        width: 196px;
        height: 39px;
        margin: 0 1rem;
        border-radius: 8px;
        background-color: rgba(251, 110, 0, 0.15);
        text-transform: uppercase;
        border-width: 0px;
        color: $primary;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        display: none;
      }

      .__page_header_three_dots_mobile {
        display: flex;
        justify-content: flex-end;
        align-items: center; 
        margin-right: 50px;
        flex: 1;
      }

      .__page_header_three_dots {
        background: transparent;
        width: 20px;
        outline: none;
        border: none;
        padding: 0;
        display: block;
        
        > span {
          width: 6px;
          height: 6px;
          margin: 0 0 6px;
          border-radius: 3px;
          background-color: $primary;
          display: block;
          margin-left: 7px;
        }
      }
    }

    .__page_header_rightside {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .__page_header {
    display: block;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 30px;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  
    small {
      font-size: 18px;
    }
  
    .__page_header_leftside {
      display: flex;
      flex-direction: row;

      .__page_header_leftside_mobile {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex: 3;
      }
  
      h1 {
        font-size: 38px;
        margin: 0;
        padding: 0;
      }
  
      p {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
  
      span {
        color: $primary;
      }
  
      .__page_header_leftside_sub {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
  
      .__page_header_btn {
        border-radius: 34px;
        height: 39px;
        font-size: 16.2px;
        width: 196px;
        text-transform: uppercase;
        margin: 0 1rem;
      }
  
      .__page_header_btn_new {
        width: 196px;
        height: 39px;
        margin: 0 1rem;
        border-radius: 8px;
        background-color: rgba(251, 110, 0, 0.15);
        text-transform: uppercase;
        border-width: 0px;
        color: $primary;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        display: none;
      }

      .__page_header_three_dots_mobile {
        display: flex;
        justify-content: flex-end;
        align-items: center; 
        margin-right: 40px;
        flex: 1;
      }

      .__page_header_three_dots {
        background: transparent;
        width: 20px;
        outline: none;
        border: none;
        padding: 0;
        display: block;
        
        > span {
          width: 6px;
          height: 6px;
          margin: 0 0 6px;
          border-radius: 3px;
          background-color: $primary;
          display: block;
          margin-left: 7px;
        }
      }
    }

    .__page_header_rightside {
      display: none;
    }
  }
}

@media (max-width: 414px) {
  .__page_header {
    display: block;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 30px;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  
    small {
      font-size: 18px;
    }
  
    .__page_header_leftside {
      display: flex;
      flex-direction: row;

      .__page_header_leftside_mobile {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex: 3;
      }
  
      h1 {
        font-size: 38px;
        margin: 0;
        padding: 0;
      }
  
      p {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
  
      span {
        color: $primary;
      }
  
      .__page_header_leftside_sub {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
  
      .__page_header_btn {
        border-radius: 34px;
        height: 39px;
        font-size: 16.2px;
        width: 196px;
        text-transform: uppercase;
        margin: 0 1rem;
      }
  
      .__page_header_btn_new {
        width: 196px;
        height: 39px;
        margin: 0 1rem;
        border-radius: 8px;
        background-color: rgba(251, 110, 0, 0.15);
        text-transform: uppercase;
        border-width: 0px;
        color: $primary;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        display: none;
      }

      .__page_header_three_dots_mobile {
        display: flex;
        justify-content: center;
        align-items: center; 
        margin-right: 0px;
        flex: 1;
      }

      .__page_header_three_dots {
        background: transparent;
        width: 20px;
        outline: none;
        border: none;
        padding: 0;
        display: block;
        
        > span {
          width: 6px;
          height: 6px;
          margin: 0 0 6px;
          border-radius: 3px;
          background-color: $primary;
          display: block;
          margin-left: 7px;
        }
      }
    }

    .__page_header_rightside {
      display: none;
    }
  }
}
