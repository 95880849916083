@import "../../scss/colors.scss";

.root {
  background: linear-gradient(to bottom, $lightBlack, $black);
  background: $black2;
}
.container {
  padding: 2.7143rem 0;
  max-width: 57.1428rem;
  width: 90%;
  margin: auto;
}
.logo {
  width: 95px;
  position: relative;
  left: -0.5rem;
}
.linksWrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  gap: 1em;
  margin: 1.6428rem 0 2.8571rem 0;
  a {
    font-weight: 600;
    &:hover {
      color: $primary;
    }
  }
}
.copyWriteSection {
  text-align: center;
  font-size: 0.8571rem;
  line-height: 1.6em;
  letter-spacing: 0.125em;
  margin: 0;
}

.__hr_styles {
  width: 100;
  height: 1;
  opacity: 0.2;
}

@media (max-width: 800px) {
  .container {
    padding: 0.7143rem 0;
  }
  .linksWrapper {
    grid-template-columns: 1fr;
    text-align: center;
    span {
      display: none;
    }
  }
  .copyWriteSection {
    // width: 334px;
    text-align: center;
    font-size: 16px;
    line-height: normal;
    letter-spacing: 2px;
    margin: 0 auto;
    color: $white;
  }

  .__hr_styles {
    width: 100%;
    height: 1;
    opacity: 0.2;
  }
}
