@import '../../scss/colors.scss';
.subtitle{
    opacity: 0.5;
}
.card{
    box-shadow: 0 0.143rem 3.142rem 0 rgba(0, 0, 0, 0.5);
    border-radius: 0.571rem;
    padding: 1rem 1.42rem;
    background-color: #28292C;
    ul{
        padding: 0;
        list-style-type: none;
        margin: 0;
        display: grid;
        grid-gap: 0 2rem;
        grid-template-columns: 1fr auto;
    }
    li{
        color: #f2f2f2;
        &:nth-child(even){
            color: $primary;
            font-weight: 600;
        }
    }
}
.cardWrapper{
    display: grid;
    grid-template-columns: 16.571rem 17.286rem;
    grid-gap: 1.42rem;
    align-items: flex-start;
}
.powersSubSectionsWrapper{
    section{
        display: grid;
        grid-template-columns: 3.857rem 1fr;
        grid-column-gap: 1.428rem;
        margin: 1.8rem 0;
        &:first-child{
            margin-top: 1.3rem;
        }
        img, svg{
            grid-row: 1/3;
            width: 100%;
            margin-top: 1rem;
        }
        h4{
            color: $primary;
            font-size: 1.286rem;
            margin: 0;
            font-weight: 600;
        }
    }
}
.linupRequrementsList{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
        display: flex;
    }
    span{
        color: $primary;
        font-weight: 900;
        width: 1.42rem;
    }
    b{
        margin-right: 1rem;
        font-weight: 900;
        font-style: italic;
    }
}
.crossicon{
    font-size: 1rem;
    position: absolute;
    right: 1.071em;
    top: 1.071em;
    cursor: pointer;
    padding: 1em;
    border-radius: 50%;
    transition: 0.2s all;
    &:hover{
        background: $lightBlack11;
    }
    &:active{
        background: $lightGrey6;
    }
    span{
        line-height: 1em;
        width: 1em;
        height: 1em;
        display: block;
        position: relative;
        &::after, &::before{
            content: '';
            height: 100%;
            position: absolute;
            width: 0.072em;
            background: $grey2;
            left: 50%;
            top: 0;
        }
        &::after{
            transform: rotate(45deg);
        }
        &::before{
            transform: rotate(135deg);
        }
    }
}
.popupWrapper{
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%;
}
.popupInnerDiv {
    display: flex;
    width: 98vw;
    height: 100vh;
    align-items: center;
    flex: 1;
    position: relative;
    justify-content: center;
    img, svg {
        margin-top: 42px;
    }
    p {
        max-width: 368px;
        margin: 20px auto 0px auto;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
        span {
            color: $primary
        }
    }
    button {
        background-color: transparent;
    }
    .cancelButton {
        width: 80px;
        height: 32px;
        margin: 30px 16px 0 0;
        padding: 4px 11px;
        border-radius: 4px;
        border: solid 1px rgba(251, 110, 0, 0.5);
        color: $primary;
        font-size: 14px;
    }
    .challengePlayButton {
        width: 145px;
        height: 32px;
        margin: 0px 0px 0 0px;
        padding: 6px 10px 6px 12px;
        border-radius: 4px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.11px;
        text-align: center;
        color: #111111;
        background-color: $primary;
        border: solid 1px rgba(251, 110, 0, 0.5);
    }
    .reviewText {
        width: 226px;
        height: 28px;
        font-family: Teko;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $primary;
        text-transform: uppercase;
    }
    .timerText {
        height: 39px;
        font-family: Teko;
        font-size: 38px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
        text-transform: uppercase;
    }
}

.popup{
    border-radius: 0.8571rem;
    background: $lightBlack;
    box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
    width: 450px;
    z-index: 1;
    top: 0 !important;
    margin: 0;
    position: relative;
    top: 0;
    height: 300px;
    text-align: center;
}
.blur{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
}
.title{
    margin-top: -3rem;
}

@media (max-width: 1300px) {
    .popup{
        max-width: 80%;
    }
}
@media (max-width: 1100px) {
    .popup{
        max-width: 90%;
    }
}
@media (max-width: 997px) {
    .popup{
        padding: 0;   
    }
    .title{
        margin-top: -1rem;
        font-size: 3rem;
    }
}

@media (max-width: 700px) {
    .cardWrapper{
        grid-template-columns: 1fr;
    }
    .powersSubSectionsWrapper{
        section{
            grid-template-columns: 3rem 1fr;
            grid-column-gap: 1rem;
        }
    }
    .popupInnerDiv {
        img, svg {
            margin-top: 0;
        }
    }
    .popup {
        padding: 2rem;
    }
}