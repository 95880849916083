@import "../../scss/colors.scss";

.wrapper {
  display: flex;
  flex-direction: column;

  .currentState {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    color: $green2;
    font-family: Teko;
    font-size: 22px;
    font-weight: 600;
    margin-right: 31px;

    .orb {
      width: 10px;
      height: 10px;
      background-color: $green2;
      border-radius: 50%;
      margin-right: 7px;
      transform: translateY(-2px);
    }

    &.column {
      flex-direction: column;
      color: $primary;
      line-height: 18px;
      margin-top: 7px;
    }

    .span_text {
      opacity: 0.6;
      font-family: Poppins;
      font-size: 12px;
      color: $white;
      padding: 0;
      margin: 0;
      font-weight: normal;
    }
  }

  .container {
    display: flex;
    padding: 0 0 0 50px;
    position: relative;

    .container_left_side {
      flex: 0.68;
      margin-bottom: 50px;
      margin-left: 80px;

      > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }

      .left_side_footer {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        margin: 80px 0;

        img {
          width: 100%;
        }
      }
    }

    .sidebar_container {
      > div {
        padding: 0px 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .sliderWrapper {
        position: absolute;
        right: 70px;
        bottom: 0;
        background-color: $lightBlack;
        height: 480px;
        width: 290px;
        display: flex;
        flex-direction: column;
        z-index: 1;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
        border-radius: 14px;
        padding: 38px 22px;

        .slider_close_btn {
          position: absolute;
          border: none;
          padding: 5px;
          color: $grey;
          right: 16px;
          top: 16px;
        }

        h3 {
          align-self: flex-start;
          font-size: 28px;
          color: $white;
          font-family: Teko;
          font-weight: 600;
          text-align: center;
          width: 100%;

          span {
            color: $primary;
          }
        }

        .slider_container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          margin-top: 18px;

          .slider_left {
            display: flex;
            flex-direction: column;
            align-self: flex-end;

            span {
              font-size: 12px;
              width: 94px;
              margin-top: 14px;
              color: #f2f2f2;
            }
          }

          .slider_right {
            position: relative;
            display: flex;
            flex-direction: row;

            .cell_steps {
              display: flex;
              flex-direction: column;
              margin: 6px 0 0 20px;

              .cell_step1 {
                margin: 8.5px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;

                &:nth-child(odd) {
                  i {
                    border: solid 1px rgba(242, 242, 242, 0.2);
                    width: 6px;
                    height: 4px;
                    margin: 0 15px 0 0;
                    background-color: rgb(255, 0, 0);
                    display: none;
                  }
                }

                &:nth-child(even) {
                  i {
                    border: solid 1px rgba(242, 242, 242, 0.2);
                    width: 12px;
                    height: 1px;
                    margin: 0 15px 0 0;
                    background-color: aliceblue;
                  }
                }

                span {
                  opacity: 0.8;
                  font-size: 12px;
                  width: 47px;
                  text-align: left;
                }
              }
            }
          }
        }

        .slider_footer {
          display: flex;
          flex-direction: row;
          margin-top: 50px;
          justify-content: space-between;
          align-items: center;

          button {
            border: 1px solid $primary;
            width: 110px;
            height: 32px;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 600;
            color: $primary;

            &.fill {
              background-color: $primary;
              color: $black;
            }
          }
        }
      }

      .sidebar_content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        background-color: $black2;
        padding: 20px 5px;
        border-radius: 14px;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        p {
          font-family: Teko;
          font-size: 30px;
          font-weight: 600;
          color: $grey2;

          span {
            font-family: Teko;
            color: $primary;
          }
        }

        .sidebar_content_1 {
          display: flex;
          flex-wrap: wrap;
          width: 280px;
          min-height: 360px;
          justify-content: space-between;
          align-items: center;

          .sidebar_content_p {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 150px;
            flex-grow: 1;
            margin: 5px 15px;

            .time_p {
              font-size: 12px;
              font-weight: 500;
              opacity: 0.6;
              font-family: Poppins;
            }

            .sidebar_power_header {
              display: flex;
              align-items: flex-end;
            }

            .power_title {
              font-family: Teko;
              font-size: 20px;
              font-weight: 500;
              opacity: 1;
              color: $grey2;
              width: 95%;
              text-align: center;
            }

            .power_footer {
              display: flex;
              width: 100%;
              justify-content: space-evenly;

              p {
                font-family: Poppins;
                font-size: 12px;
                color: $white;
                opacity: 0.5;
                font-weight: normal;
                width: 50px;
                line-height: 14px;
              }

              > div {
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                  background-color: transparent;
                  border: none;
                  outline: none;
                  display: flex;
                  padding: 0;
                  margin: 0 2px;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }

              .power_footer_count {
                background-color: $lightBlue;
                opacity: 0.6;
                width: 80px;
                height: 28px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 600;
                font-family: Poppins;
                border-radius: 10px;

                span {
                  font-weight: normal;
                  font-size: 12px;
                  color: $white;
                  margin-left: 5px;
                  font-family: Poppins;
                }
              }
            }
          }
        }

        button {
          background-color: transparent;
          border: none;
          outline: none;
          margin: 0;
          padding: 0;
          text-transform: capitalize;
          color: $lightBlue;
          border-bottom: 1px solid $lightBlue;
          line-height: 10px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .batteryWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 30px;
    width: 72px;

    .flash {
      position: absolute;
    }
  }
}

@media (max-width: 1900px) {
  .wrapper {
    .currentState {
      font-size: 28px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 14px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.74;
        margin-left: 80px;

        > div {
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          width: 410px;
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width: 1680px) {
  .wrapper {
    .currentState {
      font-size: 24px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 12px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.72;
        margin-left: 80px;

        > div {
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          width: 380px;
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .wrapper {
    .currentState {
      font-size: 22px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 11px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.7;
        margin-left: 70px;

        > div {
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          width: 360px;
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .wrapper {
    .currentState {
      font-size: 22px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 11px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.7;
        margin-left: 60px;

        > div {
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          width: 340px;
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .wrapper {
    .currentState {
      font-size: 22px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 11px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.68;
        margin-left: 55px;

        > div {
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          width: 330px;
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper {
    .currentState {
      font-size: 22px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 11px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.68;
        margin-left: 45px;

        > div {
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          width: 320px;
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width: 1124px) {
  .wrapper {
    .currentState {
      font-size: 22px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 11px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.7;
        margin-left: 15px;

        > div {
          padding: 0;
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          width: 310px;
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    .currentState {
      font-size: 22px;
      margin-right: 31px;

      .orb {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        transform: translateY(-2px);
      }

      &.column {
        color: $primary;
        line-height: 18px;
        margin-top: 7px;
      }

      .span_text {
        font-size: 11px;
      }
    }

    .container {
      padding: 0;

      .container_left_side {
        flex: 0.95;
        margin: 0 auto;

        > div {
          padding: 0;
          width: 100%;
        }

        .left_side_footer {
          margin: 80px 0;

          img {
            width: 100%;
          }
        }
      }

      .sidebar_container {
        > div {
          display: none;
        }
      }
    }
  }
}
