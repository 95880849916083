@import "../../scss/colors.scss";

.__powers_available {
  margin-left: 20px;
  margin-right: 20px;

  .__powers_available_title_and_learn_more_div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .__powers_available_title_div {
      display: flex;
      flex: 1;
      justify-content: center;

      .__power_info_main{
        display: flex;
        position: relative;
        align-items: center;

        .__info_icon_mobile{
          width: 14px;
          height: 14px;
          cursor: pointer;
          & img {
            max-width: 14px !important;
            margin-left: 48px !important;
          }
        }
      
      }
      .__powers_not_available {
        text-align: center;
        margin-top: 96px;
        .__powers_not_available_title {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
          text-decoration: underline;
          text-align: center;
          padding-bottom: 19px;
        }
        .__powers_not_available_text{
          font-size: 14px;
          line-height: 24px;
        }
      }
      
      .__powers_available_title {
        height: 23px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
        text-decoration: underline;
      }


    }
  }

  .__power_scroll_bar {
    height: 240px;
    overflow-y: scroll;
    margin-top: 18px;
}
.__power_scroll_bar::-webkit-scrollbar {
    width: 8px;
    border-radius: 100px;
    overflow: hidden;
}
.__power_scroll_bar::-webkit-scrollbar-track {
    background: #202124;
    border-radius: 100px;
}
.__power_scroll_bar::-webkit-scrollbar-thumb {
    background: rgba(242, 242, 242, 0.06);
    border-radius: 100px;
}
.__power_scroll_bar::-webkit-scrollbar-thumb:hover {
    background: rgba(242, 242, 242, 0.06);
}
  .__powers_available_data {
    display: flex;
    flex-direction: row;

    .__powers_available_data_icon_div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .__powers_available_data_icon {
        width: 36px;
        height: 36px;
        object-fit: contain;
      }

      .__powers_available_data_power_count {
        width: 9px;
        height: 20px;
        margin: 8px 8px 36px 0px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fb6e00;
      }
    }

    .__powers_available_data_value_div {
      display: flex;
      flex: 1;
      margin-left: -5px;

      .__powers_available_data_value {
        width: 100%;
        height: 16px;
        margin: 27px 17px 21px 8px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: $grey2;
      }
    }

    .__powers_available_learn_more_div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      .__powers_available_learn_more_text {
        width: 68px;
        height: 16px;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: right;
        color: $lightBlue;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 540px) {
    .__powers_available_data {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .__powers_available_data_icon_div {
        display: flex;
        justify-content: center;
        align-items: center;

        .__powers_available_data_icon {
          width: 28px;
          height: 28px;
          object-fit: contain;
        }

        .__powers_available_data_power_count {
          width: 9px;
          height: 20px;
          margin: 8px 8px 36px 0px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fb6e00;
        }
      }

      .__powers_available_data_value_div {
        display: flex;
        flex: 1;
        margin-left: -5px;
        word-wrap: break-word;

        .__powers_available_data_value {
          width: 100%;
          height: 16px;
          margin: 0px 5px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: $grey2;
          word-wrap: break-word;
        }
      }

      .__powers_available_learn_more_div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .__powers_available_learn_more_text {
          width: 68px;
          height: 16px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: right;
          color: $lightBlue;
          text-decoration: underline;
        }
      }
    }

    .__my_game_center_card_powerdfs {
      display: flex;
      justify-content: flex-start;
      //   margin-bottom: 20px;
      //   padding: 0px 20px;

      .__my_game_center_card_powerdfs_title {
        // width: 150px;
        height: 28px;
        margin: 10 13px 20px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: start;
        color: $primary;

        .__my_game_center_card_powerdfs_title_first {
          color: $grey2;
        }
      }

      .__my_game_center_card_powerdfs_subtitle {
        //   width: 100%;
        height: 23px;
        //   margin-top: 10px;
        //   margin-bottom: 10px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        opacity: 0.6;
        text-decoration: unset;
        color: $grey2;
        //   text-align: center;
      }
    }
  }
}
