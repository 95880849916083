@import '../../scss/colors.scss';
.topHeader {
    height: 246px;
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 70px;
    position: relative;
    .innerDiv {
        max-width: 1770px;
        margin: 0 auto;
        .leagueName {
            font-family: Teko;
            font-size: 62px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 55px;
            letter-spacing: normal;
            color: #f2f2f2;
            margin-left: 50px;
            margin-top: 35px;
            display: inline-block;
            .gameType {
                color: $primary;
            }
        }
        .subTitle {
            font-family: Teko;
            color: #f2f2f2;
            padding-left: 50px;
            padding-bottom: 15px;
            p {
                font-size: 15px;
            }
        }
        .topButtons {
            padding-left: 50px;
            padding-bottom: 28px;
            button {
                padding: 0;
                opacity: 0.8;
                border-radius: 8px;
                background-color: #35363a;
                color: white;
                border: 0;
                margin-right: 10px;
                width: 166px;
                height: 38px;
            }
        }
        
    }
}
.balanceDiv {
    position: absolute;
    width: 100%;
    left: -20px;
    bottom: 0;
    //padding-left: -20px;
}
.footer {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
}
.container {
    display: flex;
    flex-direction: row;
    padding-left: 125px;
    .container_left_side {
        //display: flex;
        //flex: 1;
        flex-direction: column;
        margin-right: 50px;
        .footer {
            text-align: right;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .brandImage {
            margin: 80px auto;
            text-align: center;
        }
    }
    .sidebar_container {
        display: flex;
    }
}
.teamManagerCardsList {
    display: flex;
    width: 100%;
    // border: 1px solid;
    // border-radius: 5px;
    // padding: 40px 0 40px 0;
    // border-radius: 11px;
    // border: solid 2px #000;
    // background-color: #17181a;
    justify-content: center;
    margin-top: 40px;
    .list {
        display: flex;
        flex-wrap: wrap;
        max-width: 1100px;
        justify-content: center;

    }
    .singleView {
        display: flex;
        flex-direction: row;
        .singleLeftSide {
            display: flex;
            flex-direction: row;
            flex: 1;
        }
        .singleRightSide {
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: center;
            justify-content: center;
            .leftArrow {
                display: inline-block;
                border: solid #688fbd;
                border-width: 0 7px 7px 0;
                margin: 0;
                width: 35px;
                height: 35px;
                opacity: 0.25;
                transform: rotate(135deg);
            }
            .rightArrow {
                display: inline-block;
                border: solid #688fbd;
                border-width: 0 7px 7px 0;
                margin: 0;
                width: 35px;
                height: 35px;
                opacity: 0.25;
                transform: rotate(-45deg);
            }
        }
    }
}
.teamManagerMainPageCard{
    margin-bottom: 39px !important;
}
.TeamManagerCardMain {
    margin: 5px;
    min-width: 246px;
    display: flex;
    flex-direction: column;
 
    // flex: 1;
    .topLine {
        width: 28px;
        height: 1px;
        margin: 0 0 1px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(to bottom, #fda700, #fb6e00);
        border-image-slice: 1;
        border-radius: 33px;
        border-image-slice: 1;
        background: #fb6e00;
    }
    .playerPosition {
        height: 28px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.4;
        letter-spacing: normal;
        color: #f2f2f2;
        span {
            height: 28px;
            margin: 5px 0px 4px 10px;
            background-image: linear-gradient(124deg, #fb6e00 36%, #fa3800 72%);
            font-family: Poppins;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .TeamManagerCard {
        width: 236px;
        height: auto;
        margin: 6px 0 0;
        padding: 13px 8px 12px;
        border-radius: 8px;
        box-shadow: 0 2px 24px 0 black;
        background-color: #202124;
        position: relative;
        &.active {
            border: 1px solid $primary;
        }
        &.singleViewRight {
            width: 280px;
            height: 340px;
        }
        .starPlayerDiv {
            position: absolute;
            top: -6px;
            left: -6px;
            border-radius: 5px 0px 5px 0px;
            color: #202124;
            font-size: 13px;
            font-weight: 600;
            img {
                width: 100px;
            }
        }
        .topPortion {
            display: flex;
            .playerDiv{
                display: flex;
                flex: 1;
                align-items: center;
                .playerName {
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #8cc2ff;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            button {
                width: 28px;
                height: 28px;
                background: none;
                border: 0;
                display: flex;
                img {
                    width: 100%;
                }
            }
        }
        .middlePortion {
            display: flex;
            margin-top: 15px;
            .leftStats {
                width: 149px;
                //height: 56px;
                padding: 2px 0 0 10px;
                border-radius: 6px;
                background-color: #2a3038;
                display: flex;
                flex-direction: row;
                margin-right: 8px;
                &.singleViewRight {
                    width: 192px;
                    .powers {
                        justify-content: end;
                        padding-right: 20px;
                    }
                }
                .statistics {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    display: flex;
                    flex-direction: column;
                    p {
                        line-height: normal;
                        color: #688fbd;
                        margin-top: 1px;
                    }
                    .values {
                        line-height: 1.25;
                        color: #f2f2f2;
                    }
                }
                .statisticsNFLFantasy{
                    padding-top: 10px;
                    display: block !important;

                }
                .statisticsMLBFantasy {
                    display: flex;
                    flex-direction: row;
                    padding: 10px 0;
                    .pgpstext{
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #688fbd;
                        padding-bottom: 5px;
                    }
                    div {
                        width: 50px;
                        &.otherPlayer{
                            width: auto;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            p{
                                span{
                                    color: #8cc2ff;
                                }
                            }
                        }
                        p {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.43;
                            letter-spacing: normal;
                            color: #f2f2f2;
                            
                        }
                    }
                    
                }
                .powers {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    button {
                        width: 30px;
                        height: 30px;
                        margin: 0 0 0 5px;
                        object-fit: contain;
                        background: none;
                        border: 0;
                        &.notAvailable {
                            opacity: 0.5;
                        }
                        img{
                            width: 100%;
                        }
                        svg {
                            width: 100% !important;
                        }
                    }
                }
            }
            .rightStatsMLBFantasy {
                width: 63px;
                //height: 56px;
                padding: 2px 12px 7px 13px;
                border-radius: 6px;
                background-color: #2a3038;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                    margin: 0 0 2px;
                    background-image: linear-gradient(121deg, #fb6e00 36%, #fa3800 71%);
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .pointText {
                    font-size: 20px;
                    font-weight: bold !important;
                }
            }
            .rightStats {
                width: 63px;
                //height: 56px;
                padding: 2px 12px 7px 13px;
                border-radius: 6px;
                background-color: #2a3038;
                p {
                    margin: 0 0 2px;
                    background-image: linear-gradient(121deg, #fb6e00 36%, #fa3800 71%);
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .pointText {
                    font-size: 20px;
                    font-weight: bold !important;
                }
            }
            .middleItem {
                display: flex;
                flex-direction: row;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #f2f2f2;
                justify-content: space-between;
                flex: 1;
                .positions {
                    border-left: 2px solid #688fbd;
                    padding-left: 2px;
                }
                .other {
                    img {
                        margin-right: 5px;
                    }
                    span {
                        color: #688fbd;
                    }
                }
            }
        }
        .statusPortion {
            padding: 4px 0 3px 0;
            border-radius: 6px;
            background-color: #2a3038;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: rgba(242, 242, 242, 0.7);
            margin-top: 10px;
        }
        .bottomPortion {
            margin-top: 17px;
            margin-bottom: 38px;
            .bottomItem {
                img {
                    margin: 0 10px 0 0;
                    vertical-align: -2px;
                }
                svg {
                    margin: 0 10px 0 0;
                }
                font-family: Poppins;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                color: #979797;
                margin-bottom: 4px;
                span {
                    font-weight: bold;
                    color: #688fbd;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
   
        
  
        .sidebar_container {
          > div {
            display: none;
          }
        }
     
  }
  .left_side_disclaimer_text {
    width: fit-content;
    text-align: justify;
    text-align-last: center;
    margin: 25px auto;
    margin-bottom: 0;
  }
  .pgpbody{
      display: flex;
  }
  .pgpcolumns{
    padding-left: 24px;
  }