.box__wrapper {
  text-align: Center;

  .row {
    align-items: center;
  }

  .services {
    padding: 7px 5px 15px;
    border-radius: 8px;
    background-color: rgba(242, 242, 242, 0.06);
    margin: 0 3px 5px;
  }
  .first {
    padding-left: 15px !important;
  }

  .third {
    padding-right: 15px !important;

    img {
      padding-top: 9px;
      padding-bottom: 4px;
    }
  }

  .fourth {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  h2 {
    margin-bottom: 44px;
    font-family: "Teko", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;

    color: #fb6e00;
  }

  h4 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 200;
    color: #f2f2f2;
  }
}
