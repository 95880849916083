.bannerWrapper {
  padding-top: 40px;
  padding-bottom: 30px;
  text-align: center;

  h2 {
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
    font-family: Teko;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    color: #f2f2f2;
  }

  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #979797;
  }
}
