@import '../../scss/colors.scss';

.__sidebar_power_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
  }

  .__sidebar_up_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
  }

  .__sidebar_lock_item {
    display: flex;
    flex-direction: column;
  }

  .__sidebar_purchase_powers_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
  }

  .__sidebar_to_power_up {
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: Teko;
    font-size: 32px;
    font-weight: 600;
    color: $primary;
    margin: 0;
  }

@media (max-width: 1024px) {
  .__sidebar_power_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
  }

  .__sidebar_up_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
  }

  .__sidebar_lock_item {
    display: flex;
    flex-direction: row;
  }

  .__sidebar_purchase_powers_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
  }

  .__sidebar_to_power_up {
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: Teko;
    font-size: 32px;
    font-weight: 600;
    color: $primary;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .__sidebar_power_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
  }

  .__sidebar_up_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
  }

  .__sidebar_lock_item {
    display: flex;
    flex-direction: row;
  }

  .__sidebar_purchase_powers_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
  }

  .__sidebar_to_power_up {
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: Teko;
    font-size: 32px;
    font-weight: 600;
    color: $primary;
    margin: 0;
  }
}

@media (max-width: 414px) {
  .__sidebar_power_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
  }

  .__sidebar_up_title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
  }

  .__sidebar_lock_item {
    display: flex;
    flex-direction: row;
  }

  .__sidebar_purchase_powers_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
  }

  .__sidebar_to_power_up {
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: Teko;
    font-size: 32px;
    font-weight: 600;
    color: $primary;
    margin: 0;
  }
}