@import "../../scss/colors.scss";

.__power_center_card_details {
  width: 280px;
  height: 380px;
  border-radius: 8px;
  background-color: $black2;
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: -46px 18px;
  //background-image: url(../../assets/opacity-player.png);
  background-size: cover;
}

.__pwers_header_title {
  img {
    width: 100%;
    height: 60px;
    margin: 6px 13px 0px 60px;
    object-fit: contain;
    text-align: right;
  }
  p {
    transform: rotate(-4deg);
    position: absolute;
    top: 24px;
    right: 26px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600 !important;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }
}

@media (max-width: 540px) {
  .__power_center_card_details {
    width: unset;
  }
}
