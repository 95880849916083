@import "../../scss/colors.scss";
.mainContent {
    position: relative;
    padding-top: 75px;
    .backButton {
        margin-left: 20px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(242, 242, 242, 0.75);
    }
    .topButtons {
        display: flex;
        flex-direction: row;
        padding-top: 30px;
        .leftButtons {
            display: flex;
            flex: 1;
            button {
                width: 100px;
                height: 48px;
                margin: 0 6px 0 0;
                border-radius: 12px;
                background-color: rgba(99, 99, 99, 0.1);
                border: 1px;
                span {
                    opacity: 0.5;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                }
            }
        }
        .closeButton {
            padding-top: 14px;
        }
    }
    .title {
        margin-top: 30px;
        margin-left: 20px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f2f2f2;
        margin-right: 20px;
        max-width: 213px;
        white-space: nowrap;
        span {
            color: $primary;
        }
        .dateTime {
            color: #f2f2f2;
            opacity: 0.6;
            font-size: 13px;
            font-weight: 500;
        }
        .teamMessage {
            font-size: 14px;
            line-height: 20px;
            display: block;
            font-weight: 500;
        }
        .prizeSpan {
            color: #f2f2f2;
            font-size: 30px;
        }
    }
    .gamePrize {
        margin-top: 4px;
        margin-left: 20px;
        .prize {
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
            font-family: Poppins;
            font-size: 44px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $primary;
            margin-right: 10px;
        }
        .giveaway {
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
            font-family: Poppins;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: rgba(242, 242, 242, 0.6);
        }
    }
    .rectangle {
        margin: 28px 20px 13px 20px;
        padding: 20px 10px 10px 10px;
        border-radius: 12px;
        background-color: rgba(17, 17, 17, 0.6);
        .youHaveThePower{
            font-family: Teko;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $primary;
        }
        .powers {
            display: flex;
            flex-direction: row;
            margin-top: 18px;
            &.margin {
                margin-top: 10px !important;
            }
            .power {
                display: block;
                font-family: Teko;
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                width: 50%;
                svg {
                    width: 30px !important;
                    height: 30px !important;
                    margin-right: 10px;
                }
                .orange {
                    color: $primary !important;
                }
            }
        }
    }
    .extraDesc {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        border-left: 3px solid $primary;
        padding-left: 15px;
        p {
            opacity: 0.6;
            span {
                font-weight: bold;
            }
        }
    }
    .rules {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.6);
        margin-right: 20px;
        margin-top: 46px;
        margin-bottom: 60px;
        .Oval {
            width: 10px;
            height: 10px;
            background-color: $primary;
            border-radius: 100px;
            display: inline-block;
            margin-right: 10px;
            width: 10px;
            height: 10px;
            background-color: #fb6e00;
            border-radius: 100px;
            display: inline-block;
            margin-right: 10px;
            margin-top: 5px;
          }
        .litext {
            display: flex;
            flex: 1;
        }
        li {
            margin-bottom: 10px;
            display: flex;
        }
        ul {
            list-style: none;
            padding-left: 20px;
        }
    }
    .bottomButton {
        text-align: center;
        margin-top: 46px;
        button {
            height: 48px;
            padding: 13px 28px 12px;
            border-radius: 10px;
            background-color: $primary;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #111;
            border: 1px solid $primary;
        }
    }
    .freeEntry {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #f2f2f2;
        /* position: absolute; */
        /* right: 30px; */
        /* bottom: 0px; */
        opacity: 0.5;
        text-align: center;
        margin-top: 30px;
    }
}
.topButtonsFree {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 16px;
    padding-left: 30px;
    padding-right: 30px;
    .leftButtons {
        display: flex;
        flex: 1;
        button {
            width: 160px;
            height: 48px;
            margin: 0 10px 0 0;
            border-radius: 12px;
            background-color: rgba(99, 99, 99, 0.1);
            border: 1px;
            span {
                opacity: 0.8;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
            }
        }
    }
    .closeButton {
        padding-top: 14px;
        cursor: pointer;
    }
}
.modalHeading {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 51px;
    padding-top: 80px;
    .backButton {
        width: auto;
        height: 23px;
        margin: auto;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
        background: none;
        border: 0;
        margin-bottom: 40px;
    }
    p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $primary;
    }
}
.topButtonsFreeModal{
    justify-content: space-between; 
    align-items: center;
    display: flex;
    padding-top: 100px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 60px;
    div{
        color:rgba(242, 242, 242, 0.75)        ;
        background-color: none;
        font-family: Poppins;
    }
}
.modalForm {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    .formElem {
        padding-bottom: 18px;
        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $primary;
            margin-bottom: 3px;
        }
        select {
            width: 100%;
            height: 48px;
            padding: 14px 20px;
            border-radius: 12px;
            background-color: rgba(99, 99, 99, 0.15);
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #f2f2f2;
            border: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAIAgMAAACJ7Uc5AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURfLy8vPz80dwTPLy8ip0g4kAAAADdFJOU+w/AAJQxyYAAAA1SURBVAjXDcQhAQAwCEXBH2F6gfBrME0bGiAwvIDT48TJoJcC6qqcSPXGUljvOcrnOsz9yA/7txYxlII3ewAAAABJRU5ErkJggg==');
            background-repeat: no-repeat;
            background-position-x: 95%;
            background-position-y: 20px;
        }
    }
    .bottomButtons {
        padding-top: 64px;
        display: flex;
        flex-direction: column;
        .backButton {
            width: 83px;
            height: 23px;
            margin: 40px auto;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #f2f2f2;
            background: none;
            border: 0;
        }
        .entryButton {
            width: 100%;
            height: 48px;
            border-radius: 10px;
            background-color: $primary;
            border: 0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #111;
            &:disabled {
                background-color: #76451f;
            }
        }
        
    }
}
.entryButton {
    width: 200px;
    height: 48px;
    border-radius: 10px;
    background-color: $primary;
    border: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111;
    &:disabled {
        background-color: #76451f;
    }
}
.entryModalTitle{
    text-align: center;
    padding-bottom: 70px !important;
    padding-left: 30px;
    padding-right: 30px;
    h2{
        font-size: 36px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #fb6e00;
    }
    p{
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
    }
}
.entryModalButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button{
        margin-bottom: 16px;
        
        padding: 11px 20px;
        height: auto;
        width: 370px;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #111;
    }
}
.entrybottomButtons {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 320px;
    margin: 0 auto;
    padding-top: 98px;
    .backButton{
        font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(242, 242, 242, 0.75);;
    background: transparent;
    border: 0;
    margin-top: 30px;
    }
    .entryButton{max-width: 370px;width: 370px;}
}

.modalContentWrp{
    position: relative;
    padding-top: 50px;
    h2{
        font-family: Teko;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fb6e00;
        margin-bottom: 25px;
        text-align: center;
        padding-top: 40px;
    }
    span{
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #f2f2f2;
        display: block;
        margin-bottom: 20px;
        p{
            color: #fb6e00;
            margin: 0;
            display: inline-block;
            
        }
    }
    ul{
        padding: 0;
        list-style-type: none;
        position: relative;
        li{
            list-style-type: none;
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            color: #f2f2f2;
            margin: 10px 0;
            position: relative;
            padding-left: 20px;
            em{
                color: #fb6e00;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    label{
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #f2f2f2;
        margin-bottom: 10px;
        a{
            color: #8cc2ff;
            text-decoration: underline;
        }
        p{
            color: #fb6e00;
            display: inline-block;
        }
    }
    input{
        border: 1px solid #fb6e00;
        color: #fb6e00;
        background: transparent;
        border-radius: 6px;
        padding: 12px 10px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 3.5px;
        text-align: center;
        display: block;
        width: 100%;
        margin: 40px auto 0;
    }
    input::placeholder {
        color: #fb6e00;
    }
    input:focus-visible {
        outline: none;
    }
}
.bottomButtonsEssay{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding-top: 40px;
    flex-direction: column-reverse;
    .backButtonEssay{
        width: 83px;
        height: 23px;
        margin: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        background: none;
        border: 0;
    }
    .entryButtonEssay {
        height: 48px;
        border-radius: 10px;
        background-color: #fb6e00;
        border: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #111;
        width: 100%;
        margin-bottom: 20px;
        &:disabled {
            background-color: #76451f;
        }
    }
}
.backButtontop{
    background-color: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
}

.codegeneratemodal{
    position: relative;
    padding:35px 40px;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.5);
    background-color: #2d2f33;
    h1{
        font-family: Teko;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fb6e00;
        text-align: center;
    }
    p{
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
        width: 100%;
        margin: 0 auto;
    }
    .codegeneratebuttonwrp {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 312px;
        margin: 0 auto;
        padding-top: 40px;
        .codegeneratebutton{
            width: 100%;
            height: 48px;
            border-radius: 0px;
            background-color: #fb6e00;
            border: 0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #111;
        }
    }
}