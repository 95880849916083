@import "../../scss/colors.scss";

.checkbox_container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
  }

  .checkbox {
    width: 19px;
    height: 19px;
    appearance: none;
    box-shadow: 0px 0px 0px 1px $primary;
    border-radius: 6px;
    position: relative;
    outline: none;
    margin: 0 10px 0 0;

    &:checked {
      background-color: transparent;
    }

    &:checked:after {
      content: "";
      width: 5px;
      display: block;
      position: absolute;
      height: 10px;
      border: solid $primary;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      left: 6px;
      top: 1.5px;
    }
  }
}
