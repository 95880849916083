@import "../../scss/colors.scss";

:root {
  --header_width: 290px;
}
.refresh_round {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.sidebar_header {
  width: var(--header_width);
  display: flex;
  flex-direction: column;
  background-color: $black2;
  padding: 37px 20px 10px 20px;
  border-radius: 14px;
  margin-top: 30px;

  .header_rank {
    background-color: $danger3;
    padding: 0px 20px;
    height: 38px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(calc(var(--header_width) * 0.125), -55px);
    font-size: 16px;

    p {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;

      .live_dot {
        width: 10px;
        height: 10px;
        background-color: $white;
        border-radius: 50%;
        margin: 0 10px 0 0;
      }

      .separater {
        width: 1px;
        height: 20px;
        margin: 0 10px;
        background-color: $danger4;
      }

      strong {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .sidebar_header_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: uppercase;

    p {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $primary;
      text-transform: capitalize;
      width: 110px;
    }

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .sidebar_header_p1 {
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f2f2f2;
        text-transform: capitalize;
        width: 89px;
      }

      .sidebar_header_p2 {
        font-size: 30px;
        display: flex;
        margin: 0;
        padding: 0;
        font-size: 22px;
      }

      .sidebar_header_left {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;

        .sidebar_header_1 {
          display: flex;
          align-items: flex-end;
          width: 100%;
          flex: 1;
          flex-direction: column;
          align-items: center;

          .sidebar_header_p2_1 {
            font-family: Teko;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $primary;
            text-align: left;
            width: 110px;
          }
        }

        .sidebar_header_2 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .sidebar_left {
            display: flex;
            flex-direction: column;
            flex: 1;

            > div {
              display: flex;
              width: 70%;
              align-items: flex-end;
              justify-content: space-between;

              .sidebar_header_p1_1 {
                display: flex;
                flex: 0.37;
                font-family: Teko;
                font-size: 24px !important;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                width: 89px;
              }
            }
          }

          svg {
            padding: 0;
            margin: -10px 0 0 0;
          }
        }
      }

      .sidebar_header_right {
        display: flex;
        flex-direction: column;
        flex: 0.1;

        p {
          display: flex;
          flex: 1;
        }
      }
    }
  }

  button {
    background-color: transparent;
    outline: none;
    color: $white;
    border: 1px solid $grey2;
    padding: 8px 36px;
    border-radius: 8px;
    margin: 25px 0 5px 0;
    font-size: 14px;
    opacity: 0.8;

    &:hover {
      cursor: pointer;
    }
  }
}
