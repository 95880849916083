@import '../../scss/colors.scss';

.root{
    width: 90%;
    margin: auto auto 7.857rem auto;
    max-width: 70.8572rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.leftSection{
    img{
        max-width: 32rem;
        margin-top: 5.71421rem;
        display: block;
    }
}
.titleWrapper {
    display: inline-block;
  
    h3 {
      font-size: 24px;
      color: $primary;
      margin: 0.656em 0 0.3em 0;
    }
    h4 {
      font-size: 20px;
      font-weight: 500 !important;
      font-weight: normal;
      margin: 0;
      text-align: center;
    }
  }

.backButton{
    border: none;
    background: transparent;
    font-family: inherit;
    opacity: 0.6;
    cursor: pointer;
}

.titleWrappersForMobileOnly{
    display: none;
}
.personaLogo{
    width: 15.714rem;
    display: block;
    margin: 6.143rem auto;
}

.cardTitleWrapper{
    gap: 1rem;
    margin: 2.6rem 0 0 0;
    text-align: center;
    h2{
        font-weight: normal;
        font-size: 1.125rem;
        margin: 0;
    }
    h1{
        font-size: 1.7143rem;
        color: $purple;
        margin: 0;
    }
}
.buttonWrappers{
    display: grid;
    gap: 1.714rem;
    margin: 5rem 0;
    button{
        border-width: 0.0714rem ;
        border-style: solid;
        outline: none;
        cursor: pointer;
        position: relative;
        border-radius: 0.8571rem;
        height: 4.85rem;
        overflow: hidden;
        &::before, &::after{
            content: '';
            position: absolute;
            width: 100%;
            padding-top: 100%;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: 0.2s transform;
            opacity: 0;
        }
        &::before{
            background: rgba(0, 0, 0, 0.1);
        }
        &::after{
            background: rgba(0, 0, 0, 0.05);
        }
        &:hover::after{
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
        &:active::before{
            animation: buttonAnimation 0.2s forwards;
        }
    }
}
.privacyBlog{
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 -1em 0;
    font-size: 0.8571rem;
    text-align: center;
    a{
        color: $purple;
    }
}
.verifyIdentityButton{
    font-size: 1.5714rem;
    background-color: $purple;
    color: $white;
    border-color: $purple;
}
.verifyLaterButton{
    color: $purple;
    background: transparent;
    border-color: $grey16;
}

@media (max-width: 1050px) {
    .leftSection{
        img{
            max-width: 25rem;
        }
    }
}
@media (max-width: 950px) {
    .root{
        display: block;
    }
    .titleWrapper{
        display: block;
        h4{
            text-align: left;
        }
    }
    .leftSection{
        display: flex;
        gap: 1rem;
        img{
            margin: 0;
            flex: 1;
            max-width: 50%;
        }
    }
}
@media (max-width: 550px) {
    .blogSection{
        position: static;
        display: block;
        text-align: right;
    }
    .titleWrappersForMobileOnly{
        display: initial;
        text-align: center;
        h2{
            margin: 0;
            font-size: 1.486rem;
            color: $primary;
        }
        h3{
            margin: 0 0 2rem 0;
            font-size: 1.14286rem;
            font-weight: 500;
        }
    }
    .titleWrapper {
        h3 {
          font-size: 20px;
        }
        h4 {
          font-size: 16px;
          font-weight: 500 !important;
        }
      }
}

@media (max-width: 345px) {
    .root{
        width: 92%;
    }
    .titleWrappersForMobileOnly{
        h2{
            font-size: 1.36rem;
        }
        h3{
            font-size: 1.1rem;
        }
    }
}
@keyframes buttonAnimation{
    0%{
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    90%{
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.customizeBackBtn {
    top: 15px;
    left: 15px;

    &:hover {
        text-decoration: none;
        box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.225);
    }

    &.btnFlat {
        box-shadow: none !important;
        background-color: transparent !important;

        &:hover {
            background-color: #dedede !important;
            box-shadow: none !important;
        }

        &.disabled:hover,
        &[disabled]:hover {
            background-color: transparent !important;
        }
    }
}
