@import "../../scss/colors.scss";

.cell_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;
}

.cell_high {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  span {
    color: $green;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: 0;
  }
}

.cell_medium {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  span {
    color: $yellow;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: 0;
  }
}

.cell_low {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  span {
    color: $orangeRed;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: 0;
  }
}

@media only screen and (max-width: 1224px) {
  .cell_container {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    height: 80px;
    width: 112px;
    font-size: 12px;
  }

  .cell_high {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    span {
      color: $green;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      padding: 0.5rem 1rem;
      margin: 0;
    }
  }

  .cell_medium {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    span {
      color: $yellow;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      padding: 0.5rem 1rem;
      margin: 0;
    }
  }

  .cell_low {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    span {
      color: $orangeRed;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      padding: 0.5rem 1rem;
      margin: 0;
    }
  }
}
