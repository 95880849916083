.easyplay {
  padding-bottom: 150px;
  .play__heading {
    margin-top: 30px;
    h2 {
      font-size: 4.43rem;
      font-weight: 900;
      text-align: center;
      font-family: Teko;
      color: #fb6e00;
      margin: 1.29em 0 0.95em;
      @media (max-width: 767px) {
        font-family: Teko;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #fb6e00;
      }
    }
  }
  .rechargeSection {
    background-image: url(../../assets/group-11@2xNew.png);
    height: 360px;
    text-align: center;
    background-size: cover;
    background-position: center;
    .subtext {
      width: 259px;
      height: 46px;
      margin: 8px 0 29px;
      text-shadow: 0 2px 4px var(--black-50);
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #f2f2f2;
    }
    img {
      margin-top: 32px;
    }
    p{
      width: 165px;
      height: 47px;
      /* margin: 0 47px 8px; */
      font-family: Teko;
      font-size: 46px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fb6e00;
      margin: 60px auto;
      display: inline-block;
      margin-bottom: 10px;
    }
    a {
      width: 258px;
      height: 52px;
      margin: 10px 1px 0 0;
      padding: 14px 87px 15px 89px;
      border-radius: 8px;
      background-color: #fb6e00;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #111;
      display: inline-block;
    }
    .learnMoreLink {
      width: 80px;
      height: 20px;
      margin: 40px 150px 0;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #f2f2f2;
      padding: 0;
      background: none;
      display: block;
      text-decoration: underline !important;
    }
  }
}

.easyplayrecharge {
  .play__heading {
    margin-top: 50px;
    text-align: center;
    img {
      width: 252px;
    }
    p {
      margin: 4px 74px 0 54px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: 1px;
      text-align: center;
      color: #f2f2f2;
      text-transform: uppercase;
    }
  }

  .paragraphSection {
    width: 301px;
    margin: 63px 29px 37.3px 30px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #f2f2f2;
  }

  .section1 {
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 81px;
    p {
      width: 182px;
      height: 364px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #f2f2f2;
    }
    img {
      width: 145px;
    }
  }

  .section2 {
    .topSection {
      display: flex;
      img {
        height: 361px;
        object-fit: contain;
        margin-left: 10px;
      }
      h2 {
        width: 146px;
        height: 114px;
        font-family: Teko;
        font-size: 34px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: #fb6e00;
        margin-top: 75px;
        margin-right: 42px;
        margin-left: 22px;
      }
    }
    p {
      width: 303px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #f2f2f2;
      margin-left: 30px;
      margin-top: 10px;
      margin-right: 30px;
    }
  }

  .section3 {
    margin-top: 60px;
    .topSection {
      display: flex;
      img {
        height: 361px;
        object-fit: contain;
        margin-right: 40px
      }
      h2 {
        width: 146px;
        height: 114px;
        font-family: Teko;
        font-size: 34px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: #fb6e00;
        margin-top: 75px;
        margin-right: 42px;
        margin-left: 30px;
      }
    }
    p {
      width: 303px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #f2f2f2;
      margin-left: 30px;
      margin-top: 10px;
      margin-right: 30px;
    }
  }

  .rechargeSection {
    background-image: url(../../assets/group-11@2x.png);
    height: 360px;
    text-align: center;
    background-size: cover;
    img {
      margin-top: 32px;
    }
    p{
      width: 290px;
      //height: 46px;
      margin: 16px auto;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #f2f2f2;
      margin-bottom: 42px
    }
    a {
      width: 258px;
      height: 52px;
      margin: 42px 16px 24px;
      padding: 16px 39px 16px 40px;
      border-radius: 8px;
      background-color: #fb6e00;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #111;
    }
  }
}