@import "../../scss/colors.scss";

.__power_center_card {
  width: 280px;
  height: 380px;
  border-radius: 8px;
  background-color: $black2;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 24px 24px;
  position: relative;
  .topId {
    margin: 4px 0px 0px 0px;
    color: rgb(242, 242, 242);
    opacity: 0.6;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .__win_power {
    width: 138px;
    height: 32px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $primary;
    position: absolute;
    right: 10px;
    text-align: end;
    .__win_power_span {
      color: $primary;
    }
  }
  .__start_end_date {
    width: fit-content;
    height: 15px;
    margin: 0 auto;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(242, 242, 242, 0.75);
  }
  .__power_center_card_powerdfs {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .__power_center_card_powerdfs_hr {
      width: 30px;
      height: 1px;
      border: solid 1px #f2f2f2;
      border-bottom: 0;
    }

    .__power_center_card_powerdfs_hr_left {
      margin: 14px 13px 13px 0;
    }

    .__power_center_card_powerdfs_hr_right {
      margin: 14px 0 13px 13px;
    }

    .__power_center_card_powerdfs_title {
      width: 160px;
      height: 28px;
      margin: 10 13px 20px;
      font-family: Poppins;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $primary;

      font-weight: bold !important;

      .__power_center_card_powerdfs_title_first {
        color: $grey2;
      }
    }
  }

  .__power_center_card_prize_pool {
    position: absolute;
    top: 135px;
    text-align: center;
    margin: 0 auto;
    left: 24px;
    right: 24px;

    .__power_center_card_prize_pool_common {
      width: 100%;
      height: 40px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $grey2;
    }

    .__power_center_card_prize_pool_price {
      font-size: 32px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-weight: bold !important;
      justify-content: center;
    }

    .__power_center_card_prize_pool_text {
      opacity: 0.6;
      font-weight: bold !important;
      margin-top: 10px;
    }
  }

  .__power_center_card_enter {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .__power_center_card_date_time {
    width: 100%;
    height: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-shadow: 0 2px 4px $black5;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $grey11;
  }

  .__power_center_card_total {
    .__power_center_card_total_entries {
      width: 100%;
      height: 17px;
      margin: 1px 0 -7px 8px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $lightBlue;
    }
    p {
      width: 100%;
      height: 25px;
      margin: 0 12px 3px 20px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white;

      span {
        font-size: 14px;
        font-weight: 300;
        opacity: 1;
        vertical-align: 1px;
        color: #a2a3a3;
      }
    }
  }

  .__card_button {
    margin-bottom: 11px;
    position: absolute;
    bottom: 60px;
  }

  .__power_center_card_status_and_details {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 50px; */
    position: absolute;
    bottom: 18px;
    right: 24px;
    width: 100%;
    padding-left: 24px;

    .__power_center_card_details {
      display: flex;
      flex-direction: row;
      flex: 1;
      //margin-right: 20px;

      .__power_center_card_details_link {
        width: 100%;
        height: 17px;
        margin: 0 5px 0 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $primary;
        text-decoration: underline;
        cursor: pointer;
      }
      .__power_center_card_details_link_cards{
        width: 100%;
        height: 17px;
        margin: 0 5px 0 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
        cursor: pointer;
      }

      .__power_center_card_details_link_forward_arrow {
        //width: 4px;
        //height: 6px;
        color: $primary;
      }
    }
  }
}

.__power_center__challenge_card {
  width: 280px;
  height: 380px;
  border-radius: 8px;
  background-color: $black2;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 24px 24px;
  position: relative;
  .topId {
    margin: 4px 0px 0px 0px;
    color: rgb(242, 242, 242);
    opacity: 0.6;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .__win_power {
    width: 138px;
    height: 32px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $primary;
    position: absolute;
    right: 10px;
    text-align: end;
    .__win_power_span {
      color: $primary;
    }
  }
  .__card_title {
    .__card_title_text {
      //height: 44px;
      margin: 8px 56px 7px 0;
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: #f2f2f2;
      width: 212px;
      .__card__title_first {
        color: #fb6e00;
      }
    }
  }
  .__start_end_date {
    width: fit-content;
    height: 15px;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(242, 242, 242, 0.75);
  }
  
  
  .__current_jackpot {
    position: absolute;
    top: 135px;
    width: calc(100% - 60px);
    .__current_jackpot_text {
      width: 158px;
      display: block;
      //height: 30px;
      margin: 0px 40px 12px 0;
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
      font-family: Poppins;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #fb6e00;
    }
    .__current_jackpot_amount {
      margin-bottom: 25px;
      font-family: Poppins;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.59;
      letter-spacing: normal;
      color: #f2f2f2;
    }
    .__power_center_card_prize_pool_common {
      width: 100%;
      height: 40px;
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: $grey2;
    }
    .__power_center_card_prize_pool_text {
      opacity: 0.6;
      margin-top: -10px;
    }
  }

  .__card_button {
    margin-bottom: 11px;
    position: absolute;
    bottom: 60px;
    width: 88%;
  }

  .__power_center_card_total {
    .__power_center_card_total_entries {
      width: 100%;
      height: 17px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fb6e00;
    }
    .__power_center_card_total_entries_chase {
      width: 100%;
      height: 17px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
    }
    p {
      width: 100%;
      height: 25px;
      margin: 0 12px 0px 0px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white;

      span {
        font-size: 14px;
        font-weight: 300;
        opacity: 1;
        vertical-align: 0px;
        color: #a2a3a3;
      }
    }
  }

  .__power_center_card_status_and_details {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 50px; */
    position: absolute;
    bottom: 18px;
    right: 24px;
    width: 100%;
    padding-left: 24px;
    padding-left: 24px;
    left: 0;
    padding-right: 24px;
    .__power_center_card_details {
      display: flex;
      flex-direction: row;
      flex: 1;
      // margin-right: 20px;

      .__power_center_card_details_link {
        width: 100%;
        height: 17px;
        margin: 0 5px 0 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $primary;
        text-decoration: underline;
        cursor: pointer;
      }
      .__power_center_card_details_link_cards {
        width: 100%;
        height: 17px;
        margin: 0 5px 0 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
        cursor: pointer;
      }

      .__power_center_card_details_link_forward_arrow {
        //width: 4px;
        //height: 6px;
        color: $primary;
      }
    }
  }
}

.__PWRSEnterImage {
  position: absolute;
  width: 68px;
  height: 78px;
  object-fit: cover;
  top: 10px;
  right: 10px;
}
.__PWRSBannerImage {
  img {
    height: 100%;
    width: 229px;
    margin: 68px 13px 17px 4px;
    object-fit: contain;
  }
  p {
    color: #111;
    transform: rotate(-4deg);
    position: absolute;
    top: 174px;
    left: 68px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    span{
      color:#fff
    }
  }
}
.__PWRSBannerImage_Hover {
  img {
    width: 206px;
    height: 65px;
    margin: 0 17px 6px 18px;
  }
  p {
    color: #111;
    transform: rotate(-4deg);
    position: absolute;
    top: 40px;
    left: 86px;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    span{
      color:#fff
    }
  }
}
.__PWRS_jackpot_text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #fb6e00;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
.____PWRSCardInfo {
  ul {
    padding: 0;
    list-style: none;
    margin-top: 22px;
  }

  ul li {
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
  }

  ul li img {
    height: 20px;
    width: 20px;
    object-fit: cover;
    margin-right: 10px;
  }
}
.no_purchase_text{
  text-align: center;
  position: absolute;
  bottom: 45px;
  align-self: center;
  display: flex;
  justify-content: center;
}

 
.rapidCard{
  
}
.rapidImageText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    margin-right: 8px;
  }
}
.rapidText{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #111;
  margin-top: 2px;
}
.rapidDuration{
  width: 150px;
  height: 36px;
  margin: 16px 0 21px 75px;
  padding: 2px 9px 4px 18px;
  border-radius: 1px;
  background-image: linear-gradient(to right, #fb6e00 80%, #fa3800);
  clip-path: polygon(100% 0%, 100% 100%, 0 100%, 17% 0%);
  position: absolute;
  right: -23px;
  top: 70px;
}
.chaseTheAce{
  margin: 46px auto;

}
.chaseTheAceTitle{
  font-size: 39px;
  font-family: Teko;
  line-height: 1;
  font-weight: 600 !important;
  text-transform: uppercase;

  span{
    color: #fb6e00;
  }
}
.chaseTheAcesubTitle{
  font-size: 56px;
  font-family: Teko;
  line-height: 53px;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.chaseTheAcePrize{
  flex-direction: column;
  left: 30px;
  top: 173px !important;
  display: flex;
  text-align: center;
    p{
      margin: 0 36px 14px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #f2f2f2;
    }
    .chaseTheAcePrizeAmount{
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: Teko;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.44;
  letter-spacing: normal;
  text-align: center;
  color: #fb6e00;
    }
}
.chasePrizePoolImage{
  position: absolute;
  left: -30px;
  top: 81px;
}
.chaseTheAce_Hover{
  
  .chaseTheAceTitleWrp{
    margin: 0 28px 32px;
    text-align: center;
    text-shadow: 0 2px 20px var(--black-50), 0 0 24px #000;
    font-family: Teko;
    font-size: 28px;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #f2f2f2;
    text-transform: uppercase;
    span{
      span{
        color: #fb6e00;
      }
    }
  }
  .chaseTheAceList{
    margin-top: 32px;
    li{
      text-align: left;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #f2f2f2;
      span{
        color: #fb6e00;
      }
     
    }
  }
  .chaseTheAceSubList{
    padding: 0;
    list-style: none;
    li{
      display: flex;
      align-items: center;
      padding-left: 10px;
      img{
        width: 20px;
    height: 20px;
    margin: 8px 10px 6px 0;
    object-fit: contain;
      }
      .__start_end_date_chase{
        color: #fff;
      }
    }
  }
 
}