@import '../../scss/colors.scss';

.root{
    a{
        display: block;
        font-size: 0.8571rem;
        padding: 0.934em 1.833em;
        border-left: 0.3333em solid transparent;
        text-transform: uppercase;
        cursor: pointer;
        &:hover, &.active{
            transition: 0.2s all;
            color: $primary;
            border-color: currentColor;
        }
    }
    min-width: 24.285rem;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
    background-color: #1b1c1f;
    border-radius: 0.5714rem;
    list-style-type: none;
    padding: 1rem 0;
    position: sticky;
    margin: 0 0 auto 0;
    top: 7.3rem;
    max-height: calc(100vh - 12rem);
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0.572rem;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(242, 242, 242, 0.06);
        border-radius: 1em;
    }
  
    &::-webkit-scrollbar-thumb {
        background-color: rgba(242, 242, 242, 0.06);
        border-radius: 1em;
        &:hover{
            background-color: rgba(242, 242, 242, 0.09);
        }
    }
    @media (max-width: 997px) {
        min-width: 20rem;
        top: 5.7rem;
    }
    @media (max-width: 770px) {
        display: none;
    }
}