@import "../../scss/colors.scss";

@import "../../scss/colors.scss";

.root {
  background: url("../../assets/homePageBGImageNew.png") no-repeat center
    center;
  background-size: cover;
}
.container {
  width: 73%;
  margin: auto;
  // height: 100vh;
  display: flex;
  flex-direction: column;
}
.titlesWrapper {
  margin: 15rem auto 6rem 0;
  text-align: left;
  
  p {
    // background-image: linear-gradient(
    //   98deg,
    //   #ffffff 12%,
    //   #b3b3b3 30%,
    //   #fefefe 33%,
    //   #f8f8f8 74%,
    //   #bdbdbd 77%,
    //   #ffffff 89%
    // );
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    font-size: 52px;
    font-family: "Teko";
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
    margin: 0;
    line-height: 1.21;
    letter-spacing: 0.37px;
    text-transform: uppercase;
    span{
      color: #fb6e00;
    }
  }
  .haveAccount{
    margin: 20px 255px 0 0;
    opacity: 0.8;
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: 0.1px;
    text-align: left;
    color: #fff;
    text-transform: inherit;
    a{
      text-decoration: underline !important;
      text-decoration-color: white !important
    }
  }
}
.title {
  width: 41.714rem;
}
.subtitle {
  margin: 27px 178px 66px 0px;
  text-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.11px;
  color: #fff;
}
.powerBtn {
  // box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.5);
  // border: solid 0.1em $primary;
  // background: rgba($color: $primary, $alpha: 0.6);
  // font-size: 1.429rem;
  // height: 2.4em;
  // width: 11.8em;
  // display: grid;
  // place-items: center;
  // border-radius: 0.3em;
  // margin: 5em auto 0 auto;
  width: 312px;
  height: 48px;
  margin: 5em auto 0 auto;
  padding: 11px 124px 10px 126px;
  border-radius: 6px;
  background-color: $primary;
  font-family: Teko;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #17181a;
}
.blogSection {
  margin-bottom: 1.2em;
  text-align: right;
  font-style: italic;
}

@media (max-width: 767px) {
  .title {
    max-width: 22.5714rem;
    width: 100%;
  }
  .titlesWrapper {
    margin: 40.3571rem auto 2.3571rem auto;
    text-align: center;
    p:first-child {
      // font-size: 32px;
    }
    p {
      // font-family: "StereoGothic";
      // font-size: 36px;
      // background: linear-gradient(
      //   90deg,
      //   rgba(246, 246, 246, 1) 0%,
      //   rgba(215, 215, 215, 1) 25%,
      //   rgba(246, 246, 246, 1) 78%,
      //   rgba(215, 215, 215, 1) 79%,
      //   rgba(246, 246, 246, 1) 100%
      // );
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    .haveAccount{
      margin: 20px auto 67px auto;
      text-align: center;
    }
  }
  .blogSection,
  .subtitle {
    margin: 20px auto 67px auto;
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .subtitle br {
    display: none;
  }
  .powerBtn {
    font-size: 1rem;
    margin: 18em auto 0 auto;
    height: 4em;
    width: 15em;
  }
  
  .root {
    background: url("../../assets/home-page-hero-image_mobile-new.png") no-repeat
      center center;
    background-size: cover;
    background-position: center 67px;
  }
  .container {
    justify-content: initial;
    width: inherit;
  }
}

@media (max-width: 540px) {
  .root{
    background-position: center 67px;
  }
  .titlesWrapper {
    margin: 25.3571rem auto 2.3571rem auto;
    p {
    margin: 0 34px 28px 34px;
    font-family: Teko !important;
    font-size: 30px !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.2px;
    text-align: center;
    color: #fff;
    width: 255px;
    height: 96px;
    background: none !important;
    -webkit-text-fill-color: currentColor !important;
    span {
      color: $primary !important;
    }
    }
    .haveAccount {
      width: 316px !important;
      height: 20px !important;
      margin: 20px 0 0 !important;
      font-family: Poppins !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.43 !important;
      letter-spacing: normal !important;
      text-align: center !important;
      color: #f2f2f2 !important;
    }
  }
  .subtitle {
    width: 241px !important;
    height: 66px !important;
    margin: 28px 34px 67px 41px !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.57 !important;
    letter-spacing: 0.1px !important;
    text-align: center !important;
    color: #fff !important;
  }
  .powerBtn
  {
    width: 248px !important;
    height: 48px !important;
    margin: 176px 34px 20px !important;
    padding: 11px 69px 10px 70px !important;
    border-radius: 6px !important;
    border: solid 2px #fb6e00 !important;
    background-color: #fb6e00 !important;
    font-family: Teko !important;
    font-size: 26px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #111 !important;
  }
  
}