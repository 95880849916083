@import "../../scss/colors.scss";

.__sidebar_container {
  position: absolute;
  flex: 1;
  height: 100%;
  right: 0;
  flex-direction: column;
  background-color: $lightBlack;
  box-shadow: -4px 0 38px 0 rgba(4, 4, 4, 0.58);
  margin: 0;
  padding: 1rem;

  .__sidebar_container_2 {
    position: relative;
    display: flex;
    height: 90%;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
  }
}

@media (max-width: 1024px) {
  .__sidebar_container {
    position: inherit;
    flex: 1;
    height: 100%;
    right: 0;
    flex-direction: column;
    background-color: $lightBlack;
    box-shadow: -4px 0 38px 0 rgba(4, 4, 4, 0.58);
    margin: 0;
    padding: 0rem;
    width: 100%;

    .__sidebar_container_2 {
      position: relative;
      height: 90%;
      flex-direction: column;
      align-items: center;
      margin-top: 0px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 768px) {
  .__sidebar_container {
    position: inherit;
    flex: 1;
    height: 100%;
    right: 0;
    flex-direction: column;
    background-color: $lightBlack;
    box-shadow: -4px 0 38px 0 rgba(4, 4, 4, 0.58);
    margin: 0;
    padding: 0rem;
    width: 100%;

    .__sidebar_container_2 {
      position: relative;
      height: 90%;
      flex-direction: column;
      align-items: center;
      margin-top: 0px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 414px) {
  .__sidebar_container {
    position: inherit;
    flex: 1;
    height: 100%;
    right: 0;
    flex-direction: column;
    background-color: $lightBlack;
    box-shadow: -4px 0 38px 0 rgba(4, 4, 4, 0.58);
    margin: 0;
    padding: 0rem;
    width: 100%;

    .__sidebar_container_2 {
      position: relative;
      height: 90%;
      flex-direction: column;
      align-items: center;
      margin-top: 0px;
      margin-bottom: 30px;
    }
  }
}
