@import "../../scss/colors.scss";

.content_card_grid_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;

  .content_card_grid_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    .content_card_grid_header_left {
      display: flex;
      flex-direction: row;
      flex: 0.4;
      justify-content: space-between;

      span {
        padding: 0 0.5rem;
        margin: 0 0.5rem;
        color: $grey2;
        font-size: 14px;
      }
    }

    .content_card_grid_header_right {
      display: flex;
      flex-direction: row;
      flex: 0.4;

      .content_card_grid_header_right_sub {
        display: flex;
        flex: 0.4;
        margin: 0 0.5rem;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .content_card_grid_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(4, 4, 4, 0.4);
    border-radius: 5px;
    background-color: #202124;

    .content_card_body {
      display: flex;
      flex-direction: row;

      .content_card_body_left {
        display: flex;
        flex-direction: column;
        flex: 0.64;
        border-right: 1px solid $black;

        h1 {
          margin: -10px 35px -10px 0;
          padding: 0;
          font-size: 32px;
          color: $black;
          text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white,
            1px 1px 0 $white;
        }

        .content_card_body_top {
          display: flex;
          flex-direction: row;
          // flex: 1;
          flex-wrap: wrap;
          padding: 1rem 0.7rem;

          .content_card_body_left_sub {
            display: flex;
            flex: 0.46 1 10%;
            flex-direction: column;

            h2 {
              padding: 0;
              margin: 0;
              width: 100%;
              font-size: 17px;
              color: $primary;
              z-index: 1;
            }

            .divider {
              border-bottom: 0.5px solid $primary;
              width: 165px;
              margin: -3px 0 0 0;
            }

            .content_card_body_info {
              display: grid;
              grid-template-columns: repeat(2, minmax(80px, 86px));
              gap: 10px 0;
              margin: 15px 0 0 0;

              span {
                font-size: 14px;
              }
            }
          }
        }

        .content_card_body_left_footer {
          border-top: 2px solid $black;
          margin: 20px 0 0 0;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 0.6rem;

          .content_card_body_left_footer_info {
            display: flex;
            justify-content: center;

            span {
              font-size: 14px;
              margin: 0 7px;
            }
          }
        }
      }

      .content_card_body_right {
        display: flex;
        flex: 0.42;

        .content_card_body_right_grid {
          flex: 0.34;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .content_card_footer {
      display: flex;
      padding: 0.7rem 0;
      border: none;
      border-radius: 0 0 5px 5px;
      border-top: 1px solid $darkblack;
      background-color: $lightBlack2;
      justify-content: space-evenly;
      align-items: center;

      .content_card_footer_left {
        display: flex;
        flex: 0.64;
        justify-content: center;
        align-items: center;

        span {
          margin: 0 1rem;
        }
      }

      .content_card_footer_right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0.41;

        span {
          font-weight: 600;
          font-size: 18px;
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .content_card_grid_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;

    .content_card_grid_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-shadow: none;
      background-image: linear-gradient(to bottom, #111111, #232529);
      border: 2px solid $black2;
      border-radius: 10px;

      .content_card_body {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;

        .content_card_body_left {
          display: flex;
          flex-direction: column;
          flex: 1;
          border-right: none;

          h1 {
            left: 41%;
            margin: 0px 11px 0 0;
            padding: 0;
            font-size: 20px;
            color: $black;
            text-shadow: -1px -1px 0 $white, 1px -1px 0 $white,
              -1px 1px 0 $white, 1px 1px 0 $white;
          }

          .content_card_body_top {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 0;

            .content_card_body_left_sub {
              display: flex;
              flex: 1;
              flex-direction: column;

              h2 {
                padding: 0;
                margin: 0;
                width: 100%;
                font-size: 12px;
                color: $primary;
              }

              .divider {
                border-bottom: 0.5px solid $primary;
                width: 120px;
                margin: -3px 0 0 0;
              }

              .content_card_body_info {
                display: grid;
                grid-template-columns: 55px 75px;
                margin: 5px 0 0 0;

                span {
                  font-size: 12px;
                }
              }
            }
          }

          .content_card_body_left_footer {
            border-top: 2px solid $black;
            margin: 20px 0 0 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0.6rem;

            .content_card_body_left_footer_info {
              display: flex;

              span {
                font-size: 14px;
                margin: 0 7px;
              }
            }
          }
        }

        .content_card_body_right {
          display: flex;
          margin: 10px 0;
          border-top: 2px solid $black;

          .content_card_body_right_grid {
            flex: 0.34;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 5px 0;
            padding: 0;

            .content_card_body_right_grid_title {
              font-size: 12px;
              margin: 0;
              color: #dddddd;
            }
          }
        }
      }

      .content_card_footer {
        display: flex;
        flex-direction: column-reverse;
        padding: 0.7rem 0;
        border: none;
        border-radius: 0 0 5px 5px;
        border-top: 2px solid $black2;
        background-color: $lightBlack2;
        justify-content: space-between;
        align-items: center;

        .content_card_footer_left {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;

          span {
            margin: 0 1rem;
          }
        }

        .content_card_footer_right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 0 0 10px 0;

          span {
            font-weight: 600;
            font-size: 18px;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
