/* 1. Enable smooth scrolling */
html {
	scroll-behavior: smooth;
}

/* 2. Make nav sticky */
.ContestRulesPageDesktop  {
    nav{
        position: sticky;
        top: 7rem;
        align-self: start;
        width: 340px;
        height: 613px;
        padding: 14px 50px 24px 0;
        border-radius: 8px;
        background-color: #1b1c1f;
        ol {
            list-style: none;
            li {
                opacity: 0.7;
                font-family: Poppins;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.17px;
                color: #fff;
                /* margin-top: 25px; */
                margin-bottom: 25px;
                text-transform: uppercase;
            }
        }
    }
}

.ContestRulesPageDesktop {
    display: flex;
    margin-top: 60px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 60px;
    .contentPart {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-left: 60px;
        padding-right: 75px;
        h1 {
            font-family: Teko;
            font-size: 48px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            color: #fb6e00;
        }
        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.57;
            letter-spacing: normal;
            color: #fff;
        }
        .light {
            font-weight: 400 !important;
            font-style: normal;
            margin-bottom: 10px;
        }
        section {
            margin-top: 25px;
            h2 {
                color: #fb6e00
            }
            li {
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
                margin-bottom: 10px;
            }
            .tableHeader {
                width: 480px;
                height: 60px;
                padding: 19px 15px 19px 20px;
                border-radius: 8px 8px 0px 0px; 
                background-color: #111;
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .headerTitle{
                    display: flex;
                    flex: 1;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #f2f2f2;
                }
            }
            .tableRow {
                width: 480px;
                padding: 19px 15px 19px 20px;
                display: flex;
                flex-direction: row;
                padding: 16px 15px 16px 20px;
                background-color: #1b1c1f;
                .section{
                    display: flex;
                    flex: 1;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #f2f2f2;
                }
            }
            .tableRowEven {
                width: 480px;
                padding: 19px 15px 19px 20px;
                display: flex;
                flex-direction: row;
                padding: 16px 15px 16px 20px;
                background-color: #17181a;
                .section{
                    display: flex;
                    flex: 1;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #f2f2f2;
                }
            }
        }
    }
}

.mobileContestRules {
    button {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #f2f2f2;
        height: 60px;
        background: #17181a;
        width: 100%;
        border: 0;
        text-align: left;
        padding: 20px;
        border: solid 1px #333;
        border-left: 0;
        border-right: 0;
        cursor: pointer;
    }
    h1 {
        font-family: Teko;
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        color: #fb6e00;
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.57;
        letter-spacing: normal;
        color: #fff;
    }
    .light {
        font-weight: 400 !important;
        font-style: normal;
        margin-bottom: 10px;
    }
    section {
        padding-top: 25px;
        background: black;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        h2 {
            color: #fb6e00;
            margin-top: 20px;
        }
        li {
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            color: #f2f2f2;
            margin-bottom: 10px;
        }
        .tableHeader {
            max-width: 480px;
            height: 60px;
            padding: 19px 8px 19px 10px;
            border-radius: 8px 8px 0px 0px; 
            background-color: #111;
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            .headerTitle{
                display: flex;
                flex: 1;
                font-family: Poppins;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
            }
        }
        .tableRow {
            max-width: 480px;
            padding: 19px 8px 19px 10px;
            display: flex;
            flex-direction: row;
            background-color: #1b1c1f;
            .section{
                display: flex;
                flex: 1;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
            }
        }
        .tableRowEven {
            max-width: 480px;
            padding: 19px 8px 19px 10px;
            display: flex;
            flex-direction: row;
            padding: 16px 15px 16px 20px;
            background-color: #17181a;
            .section{
                display: flex;
                flex: 1;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
            }
        }
    }
}


