@import "../../scss/colors.scss";

.__svg {
  display: flex;
  flex: 1;
  margin: 20px auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  height: 65px;
}

.__svg_circle_bg {
  fill: none;
}

.__svg_circle {
  fill: none;
}

.__svg_circle_text {
  font-size: 22px;
  text-anchor: middle;
  fill: $white;
  font-weight: bold;
}

.__svg_circle_text_2 {
  font-size: 12px;
  text-anchor: middle;
  fill: $white;
}

@media (max-width: 1024px) {
  .__svg {
    display: flex;
    flex: 1;
    margin: 10px auto;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    height: 70px;
  }
  
  .__svg_circle_bg {
    fill: none;
  }
  
  .__svg_circle {
    fill: none;
  }
  
  .__svg_circle_text {
    font-size: 14px;
    text-anchor: middle;
    fill: $white;
    font-weight: bold;
  }
  
  .__svg_circle_text_2 {
    font-size: 12px;
    text-anchor: middle;
    fill: $white;
  }
}

@media (max-width: 768px) {
  .__svg {
    display: flex;
    flex: 1;
    margin: 10px auto;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    height: 70px;
  }
  
  .__svg_circle_bg {
    fill: none;
  }
  
  .__svg_circle {
    fill: none;
  }
  
  .__svg_circle_text {
    font-size: 14px;
    text-anchor: middle;
    fill: $white;
    font-weight: bold;
  }
  
  .__svg_circle_text_2 {
    font-size: 12px;
    text-anchor: middle;
    fill: $white;
  }
}

@media (max-width: 414px) {
  .__svg {
    display: flex;
    flex: 1;
    margin: 10px auto;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    height: 65px;
  }
  
  .__svg_circle_bg {
    fill: none;
  }
  
  .__svg_circle {
    fill: none;
  }
  
  .__svg_circle_text {
    font-size: 12px;
    text-anchor: middle;
    fill: $white;
    font-weight: bold;
  }
  
  .__svg_circle_text_2 {
    font-size: 10px;
    text-anchor: middle;
    fill: $white;
  }
}
