@import '../../scss/colors.scss';

.__contest{
    margin: 1.8em 0 1.8em 1.8em;
    .__main{
        box-shadow: 0 4px 8px 0 rgba(4, 4, 4, 0.4);
        border-radius: 5px;
        background-color: #202124;
        border: 2px solid #202124;
        &.__active{
            border-color: $primary;
        }
        .__border{
            border-left: solid 1px #111;
            height: 160px;
        }
    }
    .__contest-btn{
        padding: 3px 1em;
        border-radius: 1em;
        border: 1.5px solid;
        text-align: center;
        &:first-child{
            color: $primary;
        }
        &:nth-child(2){
            background: linear-gradient(to left, rgba(242, 242, 242, 0.8), #f2f2f2 0%);
            color: #202124;
            border: 1px solid #f2f2f2;
        }
        &:nth-child(3){
            background: linear-gradient(to left, #fa3800, #fb6e00 0%);
            border-color: transparent;
        }
    }
    .__board{
        padding: 1.2em;
        .__title{
            border-bottom: 1px solid;
            line-height: 1em;
        }
        .__wagar{
            border: solid 1.5px rgba(242, 242, 242, 0.6);
            padding: 1.2px 38px 3px 10px;
            border-radius: 1em;
        }
        .__wagar-badge-wrapper{
            color: rgba(242, 242, 242, 0.6);
        }
        &.__active{
            .__title, .__checkbox:checked ~ *{
                color: $primary;
            }
            .__return{
                color: #f2f2f2;
            }
            .__wagar{
                color: #fff;
                border-color: $primary;
                >div{
                    color: $primary;
                }
            }
        }
    }
    .__contest-footer{
        border-top: 2px solid #000;
        padding: 0.5em;
    }
    .__vs{
        -webkit-text-stroke: 1px #f2f2f2;
        text-stroke: 1px #f2f2f2;
        color: transparent;
        font-family: Teko;
        text-shadow: 0 2px 6px rgba(4, 4, 4, 0.5);
    }
}