@import "../../scss/colors.scss";

.card_wrapper {
  // padding: 22px;
  border: 2px solid #000;
  border-radius: 14px;
  background-color: $black2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 50px;
}

.card_shadow {
  box-shadow: inset 0 1px 0 0 rgba(242, 242, 242, 0.6);
}

.myScoreDiv {
  //width: 200px !important;
  height: 70px;
  margin: 3px 0px -15px 87.7%;
  padding: 8px !important;
  border-radius: 10px;
  border: solid 2px #000;
  background-color: #17181a;
  flex-direction: row !important;
  position: absolute;
  top: -67px;
  right: -2px;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 1px 24px 0 rgba(0, 0, 0, 0.5);

  .scoreTitle {
    //flex: 0.8;
    position: relative;
    color: $primary;
    font-size: 14px;
    text-align: right;
    font-family: Poppins;
    width: 70px;
  }

  .scoreText {
    //width: 35px;
    font-family: Teko;
    font-size: 2vw;
    font-weight: bold;
    text-align: left;
    margin-left: 10px;
    color: $primary;
    margin-right: 10px;
  }

  img {
    margin-top: -10px;
  }
}

@media only screen and (max-width: 1224px) {
  .card_wrapper {
    // padding: 10px;
    border: 2px solid $black;
    border-radius: 14px;
    background-color: $black2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 100%;
  }

  .card_shadow {
    box-shadow: inset 0 1px 0 0 rgba(242, 242, 242, 0.6);
  }
}

@media (max-width: 414px) {
  .card_wrapper {
    // padding: 22px;
    border: none;
    border-radius: 14px;
    background-color: $lightBlack;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card_shadow {
    box-shadow: none;
  }
}
