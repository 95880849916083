@import "../../scss/colors.scss";

@mixin button_back {
  display: flex;
  align-items: center;
  min-width: 220px;
  justify-content: space-evenly;
  transform: translate(-10px, 0);
  padding-left: 0;
  border: none;
  outline: none;
  color: $white;
  font-family: Poppins;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.9;
    color: $primary;
  }
}

%button_back {
  @include button_back();
}

.container_header {
  display: flex;
  flex: 0.88;
  flex-direction: column !important;
  margin: 20px 0;

  .bg_transparent {
    background-color: transparent;
    width: 220px;
  }

  .button_back {
    @include button_back();
  }

  .container_nav {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;

    ul {
      display: flex;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 20px;

      li {
        // padding: 20px;
        margin: 0 20px;

        &:first-child {
          margin-left: 0;
        }

        a {
          padding: 20px 0;
          font-weight: normal;

          &.active {
            border-bottom: 1px solid $primary;
            font-weight: 600;
            color: $primary;
          }

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .right_menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 313px;
      background-color: $lightBlack5;
      border-radius: 12px;
      height: 38px;
      opacity: 0.8;
      padding: 0 !important;
      margin: 0;

      .button {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 7px 15px;
        color: $white;
        border-radius: 12px;
        font-size: 14px;

        &:hover {
          cursor: pointer;
          color: $primary;
        }

        &.active {
          background-color: $lightBlue;
          pointer-events: none;
        }
      }
    }
    .btn_single {
      @include button_back();

      min-width: 160px;
      background-color: $lightBlack5;
      border-radius: 8px;
      padding: 8px 0;
    }
  }
}

@media (max-width: 1900px) {
  .container_header {
    display: flex;
    flex: 0.88;
    flex-direction: column !important;
    margin: 20px 0;

    .bg_transparent {
      background-color: transparent;
      width: 220px;
    }

    .button_back {
      @include button_back();

      font-size: 18px;
      min-width: 260px;
    }

    .container_nav {
      margin-top: 0;
      margin-bottom: 0;

      ul {
        font-size: 26px;

        li {
          &:first-child {
            margin-left: 0;
          }

          a {
            padding: 20px 0;
          }
        }
      }

      .right_menu {
        width: 360px;
        height: 48px;

        .button {
          height: 48px;
          font-size: 18px;
        }
      }
      .btn_single {
        @include button_back();

        min-width: 160px;
        padding: 8px 0;
      }
    }
  }
}

@media (max-width: 1680px) {
  .container_header {
    flex: 0.95;
    margin: 20px 0;
    // max-height: 100px;
    .bg_transparent {
      width: 200px;
    }

    .button_back {
      @include button_back();

      font-size: 16px;
      min-width: 235px;
    }

    .container_nav {
      margin-top: 0;
      margin-bottom: 0;

      ul {
        font-size: 24px;

        li {
          &:first-child {
            margin-left: 0;
          }

          a {
            padding: 20px 0;
          }
        }
      }

      .right_menu {
        width: 313px;
        height: 44px;

        .button {
          height: 100%;
          font-size: 16px;
        }
      }
      .btn_single {
        @include button_back();

        min-width: 160px;
        padding: 8px 0;
      }
    }
  }
}

@media (max-width: 1440px) {
  .container_header {
    flex: 0.88;
    margin: 20px 0;

    .bg_transparent {
      width: 200px;
    }

    .button_back {
      @include button_back();

      font-size: 14px;
    }

    .container_nav {
      margin-top: 0;
      margin-bottom: 0;

      ul {
        font-size: 20px;

        li {
          &:first-child {
            margin-left: 0;
          }

          a {
            padding: 20px 0;
          }
        }
      }

      .right_menu {
        width: 313px;
        height: 38px;

        .button {
          height: 100%;
          font-size: 14px;
        }
      }
      .btn_single {
        @include button_back();

        min-width: 160px;
        padding: 8px 0;
      }
    }
  }
}

@media (max-width: 1124px) {
  .container_header {
    flex: 0.88;
    margin: 20px 0;

    .bg_transparent {
      width: 200px;
    }

    .button_back {
      @include button_back();

      font-size: 12px;
    }

    .container_nav {
      margin-top: 0;
      margin-bottom: 0;
      ul {
        font-size: 18px;

        li {
          &:first-child {
            margin-left: 0;
          }

          a {
            padding: 20px 0;
          }
        }
      }

      .right_menu {
        width: 313px;
        height: 34px;

        .button {
          height: 100%;
          font-size: 12px;
        }
      }
      .btn_single {
        @include button_back();

        min-width: 160px;
        padding: 8px 0;
      }
    }
  }
}
