.mt-30,
.my-30,
.m-30 {
    margin-top: 2.142rem !important;
}

.mb-30,
.my-30,
.m-30 {
    margin-bottom: 2.142rem !important;
}

.ml-30,
.mx-30,
.m-30 {
    margin-left: 2.142rem !important;
}

.mr-30,
.mx-30,
.m-30 {
    margin-right: 2.142rem !important;
}

.mt-50,
.my-50,
.m-50 {
    margin-top: 3.571rem !important;
}

.mb-50,
.my-50,
.m-50 {
    margin-bottom: 3.571rem !important;
}

.ml-50,
.mx-50,
.m-50 {
    margin-left: 3.571rem !important;
}

.mr-50,
.mx-50,
.m-50 {
    margin-right: 3.571rem !important;
}

.mt-auto,
.my-auto,
.m-auto {
    margin-top: auto !important;
}

.mb-auto,
.my-auto,
.m-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto,
.m-auto {
    margin-left: auto !important;
}

.mr-auto,
.mx-auto,
.m-auto {
    margin-right: auto !important;
}

@media (max-width: 700px) {

    .mt-sm-30,
    .my-sm-30,
    .m-sm-30 {
        margin-top: 2.142rem !important;
    }

    .mb-sm-30,
    .my-sm-30,
    .m-sm-30 {
        margin-bottom: 2.142rem !important;
    }

    .ml-sm-30,
    .mx-sm-30,
    .m-sm-30 {
        margin-left: 2.142rem !important;
    }

    .mr-sm-30,
    .mx-sm-30,
    .m-sm-30 {
        margin-right: 2.142rem !important;
    }
}

.p-normal {
    padding: 0.1px;
}

.d-block {
    display: block;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.bg-orange {
    background-color: #fb6e00 !important;
}

.text-orange {
    color: #fb6e00 !important;
}

.bg-none {
    background: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}


.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}