@import "../../scss/colors.scss";
.subtitle {
  opacity: 0.5;
  margin-top: 25px;
}
.card {
  box-shadow: 0 0.143rem 3.142rem 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.571rem;
  padding: 1rem 1.42rem;
  background-color: #28292c;
  text-transform: capitalize;
  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    display: grid;
    grid-gap: 0 2rem;
    grid-template-columns: 1fr auto;
  }
  li {
    color: #f2f2f2;
    &:nth-child(even) {
      color: $primary;
      font-weight: 600;
    }
  }
}
.cardWrapper {
  display: grid;
  grid-template-columns: 20.571rem 17.286rem;
  grid-gap: 1.42rem;
  align-items: flex-start;
}
.powersSubSectionsWrapper {
  section {
    display: grid;
    grid-template-columns: 3.857rem 1fr;
    grid-column-gap: 1.428rem;
    margin: 1.8rem 0;
    &:first-child {
      margin-top: 1.3rem;
    }
    img {
      grid-row: 1/3;
      width: 100%;
      margin-top: 1rem;
    }
    h4 {
      color: $primary;
      font-size: 1.286rem;
      margin: 0;
      font-weight: 600;
    }
  }
}
.linupRequrementsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    display: flex;
  }
  span {
    color: $primary;
    font-weight: 900;
    width: 1.42rem;
  }
  b {
    margin-right: 1rem;
    font-weight: 900;
    font-style: italic;
  }
}
.crossicon {
  font-size: 1rem;
  position: absolute;
  right: 1.071em;
  top: 1.071em;
  cursor: pointer;
  padding: 1em;
  border-radius: 50%;
  transition: 0.2s all;
  &:hover {
    background: $lightBlack11;
  }
  &:active {
    background: $lightGrey6;
  }
  span {
    line-height: 1em;
    width: 1em;
    height: 1em;
    display: block;
    position: relative;
    &::after,
    &::before {
      content: "";
      height: 100%;
      position: absolute;
      width: 0.072em;
      background: $grey2;
      left: 50%;
      top: 0;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(135deg);
    }
  }
}
.popupWrapper {
  position: absolute;
  z-index: 10000;
  top: 0;
}
.popup {
  padding: 7.142rem;
  border-radius: 0.8571rem;
  background: $lightBlack;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  width: 72.36%;
  z-index: 1;
  top: 0 !important;
  margin: 5.5rem auto 8rem auto;
  position: relative;
  top: 0;
}
.blur {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.5);
}
.title {
  margin-top: -3rem;
}

@media (max-width: 1300px) {
  .popup {
    max-width: 80%;
  }
}
@media (max-width: 1100px) {
  .popup {
    max-width: 90%;
  }
}
@media (max-width: 997px) {
  .popup {
    padding: 3rem;
  }
  .title {
    margin-top: -1rem;
    font-size: 3rem;
  }
}

@media (max-width: 700px) {
  .popup {
    max-width: 90%;
    width: 90%;
    max-height: 665px;
    overflow: auto;
  }
  .cardWrapper {
    grid-template-columns: 1fr;
  }
  .powersSubSectionsWrapper {
    section {
      grid-template-columns: 3rem 1fr;
      grid-column-gap: 1rem;
    }
  }
}

@media (max-width: 500px) {
  .popup {
    position: fixed;
    margin: 25% 5% 25%;
  }
}
