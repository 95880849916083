@import "../../scss/colors.scss";

.__Header {
  background: #1111116e;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 68px;
  .__container {
    height: 100%;
  }
  .__brand-logo > img {
    width: 75px;
    position: relative;
    top: 0.2em;
  }
  .__navlinks {
    list-style-type: none;
    margin: 0.66em 0;
    .__my_account_li {
      width: 150px;
    }
    > li {
      margin: 0 1.5em;
      text-transform: uppercase;
      &:last-child {
        margin-right: 0;
      }
      a:not(.__header-btn) {
        &:hover,
        &.active {
          color: $primary;
        }
      }
    }
  }
  .__landing-page_title {
    height: 100%;
    margin: 6px 0;
    justify-content: center;

    strong {
      font-size: 22px;
    }
  }
  .__header-btn {
    padding: 0.329em;
    min-width: 122px;
    background-color: $black;
    border: 1px solid $white;
    a.active,
    a:hover {
      color: #fff;
    }
  }
  @media (max-width: 1200px) {
    background-color: #1e1e1e;
    padding: 1em 0;
    .__menu-icon {
      background: transparent;
      outline: none;
      border: none;
      padding: 0;
      > span {
        width: 35px;
        height: 7px;
        background: $primary;
        border-radius: 2px;
        display: block;
        &:nth-child(2) {
          margin: 2px 0;
        }
      }
      // &:focus ~ .__navlinks {
      //   max-height: 1000px;
      // }
    }

    .__menu-icon_clicked {
      ~ .__navlinks {
        height: 1000px;
      }
    }

    .__navlinks {
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      top: 100%;
      text-align: center;
      background-color: #17181a;
      margin: 0;
      height: 0;
      overflow: hidden;
      transition: linear 0.3s max-height;
      li {
        margin: 1em 0;
        font-size: 1.15em;
        &:first-child {
          margin-top: 1.5em;
        }
        &:last-child {
          margin-bottom: 4em;
        }
        .__header-btn {
          background: transparent;
          border: none;
          margin: 0;
        }
        &:not(:last-child)::after {
          content: "";
          border-bottom: 1px solid rgba(242, 242, 242, 0.2);
          display: block;
          width: 5em;
          margin: 1.5em auto;
        }
      }

      .__my_account_li {
        width: unset;
      }
    }

    .__landing-page_title {
      height: 100%;
      margin: 6px 0;
      justify-content: flex-end;

      strong {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 540px) {
  .__navlinks {
    padding-inline-start: 0px;
    li {
      &:not(:last-child)::after {
        content: "";
        border-bottom: 1px solid rgba(242, 242, 242, 0.2);
        display: block;
        width: unset !important;
        margin: 1.5em auto;
      }
    }
  }

  .__Header {
    background: #1e1e1e;
    z-index: 300;
  }
}
