@import "../../scss/colors.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $lightBlack14;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
