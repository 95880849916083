@import '../../scss/colors.scss';

.__team_roster {
    .AceCenterLogo {
        width: 75px !important;
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .__pwers_header_title {
        width: 129px !important;
        height: 38px;
        margin: 6px 1px 4px 40px;
        object-fit: contain;
        position: absolute;
        right: 12px;
        top: 14px;
    }
    .topTitle {
        margin: 10px 4px 6px 10px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f2f2f2;
        text-align: left;
    }
    .__team_roster_date_time {
        width: 100%;
        height: 17px;
        margin-top: 10px;
        text-shadow: 0 2px 4px $black5;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey11;
        text-align: left;
        padding-left: 20px;
    }

    .__team_roster_powerdfs {
        display: flex;
        flex-direction: row;

        .__team_roster_powerdfs_title {
            width: 150px;
            height: 28px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $primary;

            .__team_roster_powerdfs_title_first {
                color: $grey2;
            }

            .__team_roster_title {
                width: 65px;
                height: 16px;
                font-family: Poppins;
                text-shadow: 0 2px 4px $black5;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: $grey2;
                opacity: 0.5;
            }
        }
    }

    .__team_roster_heading {
        width: 100%;
        height: 23px;
        margin-left: 10px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $lightBlue;
        text-align: left;
    }

    .__team_roster_data_container {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        margin-top: 10px;
        
        .__team_roster_data_content {
            .__team_roster_data {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
        
                .__team_roster_data_title_div {
                    display: flex;
                    position: relative;
        
                    .__team_roster_data_title {
                        height: 18px;
                        font-family: Poppins;
                        font-size: 16px;
                        font-stretch: normal;
                        line-height: 1.29;
                        letter-spacing: normal;
                        font-weight: 900;
                        font-style: italic;
                        color: $lightBlue;
                    }

                    .__team_roster_data_count_div {
                        margin-left: 5px;
                        
                        .__team_roster_data_count {
                            right: 0;
                            margin-bottom: 13px;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            color: $grey2;
                        }
                    }
                }
        
                .__team_roster_data_value_div {
                    margin-left: 8px;
                    margin-right: 40px;
                    padding-top: 2.5px;
                    .__team_roster_data_value {
                        height: 18px;
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.29;
                        letter-spacing: normal;
                        color: $grey2;
                    }
                }
            }
        }
    }

    .__my_game_center_card_powerdfs {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        padding: 0px 20px;
    
        .__my_game_center_card_powerdfs_title {
          // width: 150px;
          height: 28px;
          margin: 10 13px 20px;
          font-family: Poppins;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: start;
          color: $primary;
    
          .__my_game_center_card_powerdfs_title_first {
            color: $grey2;
          }
          .__my_game_center_card_powerdfs_subtitle {
            opacity: 0.6;
          }
        }
      }
}