@import "../../scss/colors.scss";

.container_top_2 {
  display: flex;
  // flex-wrap: wrap;

  button {
    border: none;
    outline: none;
    background-color: $lightBlack5;
    color: $white;
    margin: 0 22px;
    width: 47px;
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 17px;
    border-radius: 8px;
    text-transform: uppercase;

    &.selected {
      background-color: $primary;
    }

    &.border {
      border: 1px solid $primary;
    }

    .tick {
      display: none;
    }

    span {
      position: absolute;
      transform: translate(0, 30px);
      background-color: $darkblack;
      width: 60px;
      font-size: 14px;
      border-radius: 6px;
      text-transform: lowercase;
    }

    &:nth-child(1) {
      margin: 0 10px 0 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: $primary;
      font-weight: 600;

      .tick {
        display: unset;
        position: absolute;
        transform: translate(0, 27px);
      }

      span {
        display: none;
      }
    }
  }
}

@media (max-width: 1900px) {
  .container_top_2 {
    button {
      margin: 0 12px;
      width: 67px;
      height: 67px;
      font-size: 22px;

      span {
        position: absolute;
        bottom: 0;
        transform: translate(0, 14px);
        background-color: $darkblack;
        width: 80px;
        font-size: 16px;
        border-radius: 6px;
        text-transform: lowercase;
      }

      &:nth-child(1) {
        margin: 0 10px 0 0;
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: $primary;
        font-weight: 600;

        .tick {
          display: unset;
          position: absolute;
          bottom: 0;
          transform: translate(0, 25px);

          svg {
            margin: 0;
            padding: 0;
            width: 27px;
            height: 27px;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1680px) {
  .container_top_2 {
    button {
      margin: 0 12px;
      width: 57px;
      height: 57px;
      font-size: 18px;

      span {
        position: absolute;
        bottom: 0;
        transform: translate(0, 14px);
        width: 70px;
        font-size: 14px;
      }

      &.active {
        .tick {
          display: unset;
          position: absolute;
          bottom: 0;
          transform: translate(0, 20px);

          svg {
            width: 22px;
            height: 22px;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .container_top_2 {
    button {
      margin: 0 18px;
      width: 47px;
      height: 47px;
      font-size: 16px;

      span {
        width: 60px;
      }

      &.active {
        .tick {
          transform: translate(0, 17px);

          svg {
            width: 18px;
            height: 18px;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .container_top_2 {
    button {
      margin: 0 14px;
      width: 47px;
      height: 47px;
      font-size: 14px;

      span {
        position: absolute;
        bottom: 0;
        transform: translate(0, 14px);
        font-size: 12px;
      }

      &.active {
        .tick {
          transform: translate(0, 16px);
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .container_top_2 {
    button {
      margin: 0 14px;
      width: 47px;
      height: 47px;
      font-size: 14px;

      span {
        position: absolute;
        bottom: 0;
        transform: translate(0, 14px);
        font-size: 12px;
      }

      &.active {
        .tick {
          transform: translate(0, 16px);
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .container_top_2 {
    button {
      margin: 0 10px;
      width: 43px;
      height: 43px;
      font-size: 14px;

      span {
        position: absolute;
        bottom: 0;
        transform: translate(0, 14px);
        font-size: 12px;
      }

      &.active {
        .tick {
          transform: translate(0, 16px);
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .container_top_2 {
    button {
      margin: 0 14px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      margin: 0 10px;

      span {
        position: absolute;
        bottom: 0;
        transform: translate(0, 10px);
        font-size: 12px;
        width: 50px;
      }

      &.active {
        .tick {
          transform: translate(0, 12px);

          svg {
            width: 14px;
            height: 14px;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .container_top_2 {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: nowrap;

    button {
      margin: 0 14px;
      width: 47px;
      height: 47px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 900;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 5px;

      // &.selected {
      //   background-color: $lightBlack5;
      //   color: $primary;
      // }

      span {
        position: absolute;
        bottom: 0;
        transform: translate(0, 10px);
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        width: 50px;
      }

      &.active {
        .tick {
          transform: translate(0, 12px);

          svg {
            width: 14px;
            height: 14px;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}
