@import "../../scss/colors.scss";

.wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $darkblack;

  .container {
    // width: 55%;

    h2 {
      font-size: 24px;
      color: $white;
      width: 100%;
      text-align: left;
      margin: 116px 0 30px 0;
    }

    .container_tabs {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      ul {
        list-style: none;
      }

      .tabs_header {
        display: flex;
        margin: 0;
        padding: 0;
        font-size: 16px;
        justify-content: space-between;
        border-bottom: 2px solid $grey;

        li {
          padding: 10px 0;
          // margin: 0 15px;
          min-width: fit-content;
        }

        li:hover {
          cursor: pointer;
        }

        li.active {
          border-bottom: 3px solid $primary;
          color: $primary;
          font-weight: bold;
          font-style: italic;
        }

        span {
          padding: 2px 8px;
          margin: 0 5px;
          border-radius: 50%;
          background-color: $primary;
          color: $white;
          font-style: normal;
        }
      }

      .tab_body {
        margin: 70px 0;
      }
    }
  }
}

@media (max-width: 540px) {
  .wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $darkblack;

    .container {
      // width: 100%;

      h2 {
        display: none;
      }

      .container_tabs {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;

        ul {
          list-style: none;
        }

        .tabs_header {
          // display: flex;
          // flex: 1;
          // justify-content: center;
          align-items: center;
          font-size: 16px;
          border-bottom: none;
          margin: 0;
          padding: 20px 0;
          background-color: $black;
          width: 100%;
          // position: fixed;
          overflow: auto;

          li {
            padding: 0px;
            margin: 0 15px;
            min-width: fit-content;
          }

          li:hover {
            cursor: pointer;
          }

          li.active {
            border-bottom: 2px solid $primary;
            color: $primary;
            font-weight: bold;
            font-style: normal;
          }

          span {
            padding: 1px 6.3px;
            margin: 0 2px;
            border-radius: 50%;
            background-color: $primary;
            color: $white;
            font-style: normal;
            font-size: 12px;
          }
        }

        .tab_body {
          margin: 30px auto;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .wrapper {
    .container {
      .container_tabs {
        .tabs_header {
          li {
            margin: 0 5px;
          }
        }
      }
    }
  }
}
