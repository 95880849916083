$primary: #fb6e00;
$lightPrimary: #fb6e001a;
$primaryLight: #fb6e0033;
$primaryLight2: #fb6e0099;
$primaryLight3: #fb6e0061;
$primaryLight4: #e63f2f26;
$primaryLight5: #fb6e004d;
$primaryLight6: #fb6e001a;
$primaryLight7: #c7570033;
$primaryLight8: #fb6d0025;
$primaryLight9: #291200;
$yellow: #fda700;
$black: #111111;
$darkblack: #000;
$lightBlack: #202124;
$lightBlack2: #3b3c3e;
$lightBlack3: #2b2b2b;
$lightBlack4: #232529;
$lightBlack5: #35363a;
$lightBlack6: #f2f2f233;
$lightBlack7: #7f7f7f0f;
$lightBlack8: #00000080;
$lightBlack9: #00000057;
$lightBlack10: #6363633d;
$lightBlack11: #292a2e;
$lightBlack12: #00000024;
$lightBlack13: #00000014;
$lightBlack14: #00000066;
$lightGrey: #303133;
$lightGrey1: #d8d8d8;
$lightGrey2: #c7cad1;
$lightGrey3: #f7f7f7;
$lightGrey4: #a7a7a7;
$lightGrey5: #ffffff0d;
$lightGrey6: #f2f2f21f;
$lightGrey7: #d8d8d824;
$lightGrey8: #ffffff57;
$black2: #17181a;
$black3: #111111cc;
$black4: #11111199;
$black5: #00000080;
$black6: #17181a80;
$white: #fff;
$white1: #ffffff99;
$grey: #979797;
$grey2: #f2f2f2;
$grey3: #a9a9a9;
$grey4: #1a1a1a;
$grey5: #f2f2f214;
$grey6: #f2f2f299;
$grey7: #4d5255;
$grey8: #3e4345;
$grey9: #7f7f7f;
$grey10: #f2f2f21a;
$grey11: #f2f2f2bf;
$grey12: #1f1f1f;
$grey13: #ffffff80;
$grey14: #e4e4e4;
$grey15: #f2f2f2b3;
$grey16: #b3b3b3;
$grey17: #fefefe;
$grey18: #f8f8f8;
$grey19: #bdbdbd;
$grey20: #eeefff;
$lightGreen: #1bbc8633;
$alertPrimary: #fb6e0026;
$alertSuccess: #1bbc8626;
$alertDanger: #e7304e26;
$danger: #e7304e;
$danger2: #fa3800;
$danger3: #e03c2d;
$danger4: #ad2e23;
$danger5: #e63f2f;
$danger6: #cc2929;
$success: #1bbc86;
$successLight: #2b69304d;
$successLight2: #2b69301a;
$successLight3: #214f2480;
$successLight4: #214f24;
$successLight5: #1a331e;
$green: #4bb654;
$green2: #3f9946;
$yellow: #fbbc15;
$orangeRed: #f44336;
$offwhite: #e0e0e0;
$lightBlue: #688fbd;
$lightBlue2: #8cc2ff;
$lightBlue3: #6890bd7c;
$lightBlue4: #6890bde5;
$lightBlue5: #6890bd3f;
$lightBlue6: #688fbd33;
$lightBlue7: #6890bd60;
$lightBlue8: #2a3038;
$divider: #efefef;
$purple: #5600ed;
