@import "../../scss/colors.scss";

.__team_roster {
  margin-left: 20px;
  margin-right: 20px;

  .__team_roster_main_title {
    width: 100%;
    height: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey2;
    text-decoration: underline;
    text-align: center;
  }

  .__team_roster_main_title_pwrs {
    width: 100%;
    height: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey2;
    text-decoration: none;
    text-align: left;
  }

  .__team_roster_main_title_chase, .__team_roster_main_title_pwrs_chase {
    margin: 0 98px 4px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f2f2f2;
  }

  .__team_roster_data {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .__team_roster_data_count_div {
      display: flex;

      .__team_roster_data_count {
        width: 100%;
        height: 18px;
        margin-bottom: 5px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $grey2;
      }
    }

    .__team_roster_data_title_div {
      display: flex;
      margin-left: 8px;

      .__team_roster_data_title {
        width: 100%;
        height: 18px;
        font-family: Poppins;
        font-size: 14px;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        font-weight: 900;
        font-style: italic;
        color: $lightBlue;
      }
    }

    .__team_roster_data_value_div {
      display: flex;
      margin-left: 8px;

      .__team_roster_data_value {
        width: 100%;
        height: 18px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $grey2;
      }
    }
  }

  .__team_roster_heading {
    width: 100%;
    height: 23px;
    margin: 0 134px 12px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $lightBlue;
  }

  @media (max-width: 540px) {
    .__my_game_center_card_powerdfs {
      display: flex;
      justify-content: flex-start;
      //   margin-bottom: 20px;
      //   padding: 0px 20px;

      .__my_game_center_card_powerdfs_title {
        // width: 150px;
        height: 28px;
        margin: 10 13px 20px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: start;
        color: $primary;

        .__my_game_center_card_powerdfs_title_first {
          color: $grey2;
        }
      }

      .__my_game_center_card_powerdfs_subtitle {
        //   width: 100%;
        height: 23px;
        //   margin-top: 10px;
        //   margin-bottom: 10px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        opacity: 0.6;
        text-decoration: unset;
        color: $grey2;
        //   text-align: center;
      }
    }

    .__team_roster_heading {
      width: unset;
      height: 23px;
      margin: unset;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $lightBlue;
      text-align: start;
      margin: 10px 0px;
    }

    .__team_roster_data2 {
      display: flex;
      flex-direction: row;
    }

    .__team_roster_data1 {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .__team_roster_data_title {
      font-size: 12px !important;
    }
    .__team_roster_data_value {
      font-size: 12px !important;
    }
  }
}
