// @import "../../scss/colors.scss";

// .container_body_card {
//   background-color: $lightBlack;
//   border-radius: 5px;
//   padding: 17px 16px;
//   box-shadow: 0 8px 25px 0 $lightBlack7;
//   width: 463px;
//   height: 200px;
//   margin: 10px 0px;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   // align-items: flex-start;
  
//   &.inset {
//     overflow: hidden;
//     padding-top: 21px;
//   }

//   &.activeBorder {
//     border: 1px solid $primary;
//   }

//   .container_body_card_1 {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: flex-end;
//     position: relative;

//     .container_body_left {
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       align-items: flex-start;
//       min-height: 100%;
//       width: 100%;
//       position: relative;

//       .card_mlb_vs {
//         margin: 0;
//         padding: 0;

//         p {
//           margin: 0;
//           padding: 0;
//         }
//       }

//       .team_vs {
//         margin-bottom: 0px;
//         &.selectedColor {
//           p {
//             color: $primary !important;
//           }
//         }
//         p {
//           color: #688fbd;
//           font-weight: 500 !important;
//         }
//       }

//       .container_body_card_header {
//         display: flex;
//         align-items: center;
//         height: 30px;

//         &.header_flex {
//           display: flex;
//           align-items: center;
//           width: 100%;
//         }

//         .container_selected {
//           display: flex;
//           flex: 1;
//         }

//         .container_selected_p {
//           font-size: 18px;
//           padding: 0;
//           margin: 0;
//           color: $lightBlue;
//           font-family: Poppins;
//           font-weight: 600;

//           span {
//             // color: $grey3;
//             // font-size: 12px;
//             // font-weight: normal;
//             font-weight: 600;
//           }

//           &.active {
//             color: $primary;
//           }
//         }

//         button {
//           background-color: $lightBlack6;
//           border: none;
//           outline: none;
//           color: $grey2;
//           font-size: 12px;
//           width: 84px;
//           height: 28px;
//           border-radius: 4px;
//           margin-left: 10px;
//           opacity: 0.6;
//           font-family: Poppins;
//           font-weight: normal;

//           &:hover {
//             cursor: pointer;
//           }

//           &.disabled {
//             background-color: $lightGrey4;
//             cursor: not-allowed;
//             opacity: 0.2;
//           }
//         }

//         .container_selected {
//           padding: 0;
//           margin: 0 0 0 6px;
          

//           .container_selected_p_1 {
//             display: flex;
//             flex-direction: row;
//             margin: 0;
//             padding: 0;
//             justify-content: center;
//             align-items: center;
//             flex: 1;
//             align-items: center;
//             justify-content: end;

//             img {
//               width: 24px;
//               height: 24px;
//               margin-left: 5px;

//               &:hover {
//                 cursor: pointer;
//               }
//             }

//             svg {
//               margin-right: 5px;
//             }
//           }

//           img {
//             width: 30px;
//             display: flex;
//             align-self: normal;
//           }
//         }
//       }

//       .container_body_card_state {
//         margin: 5px 0 4px 0;
//         width: 260px;

//         &.border {
//           border-left: 3px solid $lightBlue;
//         }

//         &.active {
//           border-color: $primary;
//         }

//         &.card_state_no_data {
//           padding-left: 10px;
//         }

//         .card_state_mlb {
//           display: flex;
//           margin: 2px 0 0 10px;
//           padding: 0;
//           border-left: 3px solid $lightBlue;

//           &.active {
//             border-color: $primary;
//           }

//           > div {
//             display: flex;
//             flex-direction: column;
//             margin: 0 14px 0 16px;

//             span {
//               font-size: 14px;
//               opacity: 0.6;
//               color: $grey2;
//               font-weight: normal !important;
//             }

//             p {
//               padding: 0;
//               margin: 0 0 2px 0;
//               font-size: 14px;
//               font-weight: 600;
//               color: $grey2;
//             }
//           }
//         }

//         .card_state {
//           display: flex;
//           flex-direction: column;
//           justify-content: space-evenly;
//           border-left: 3px solid $lightBlue;

//           &.active {
//             border-color: $primary;
//           }

//           .card_state_title {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-evenly;

//             span {
//               color: $grey2;
//               font-size: 10px;
//               text-transform: uppercase;
//               opacity: 0.6;
//               text-align: center;
//               flex: 1;
//             }
//           }

//           .card_state_values {
//             display: flex;
//             justify-content: space-evenly;
//             width: 100%;

//             span {
//               text-align: center;
//               flex: 1;
//               font-weight: 600;
//               font-size: 16px;
//             }
//           }
//         }
//       }
//     }

//     .container_body_right {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       transform: translate(0, -6px);

//       .right_img {
//         background-color: $white;
//         display: flex;
//         width: 92px;
//         height: 92px;
//         border-radius: 8px;
//         border: 1px solid $primary;
//         overflow: hidden;
//         box-shadow: 0 12px 24px 0 $primaryLight4;
//       }
//       .img_badge {
//         position: absolute;
//         width: 24px;
//         height: 24px;
//         transform: translate(45px, -12px);
//         border-radius: 50%;
//       }

//       p {
//         margin: 5px 0 0 0;
//         padding: 0;
//         font-family: Teko;
//         font-weight: 600;
//         font-size: 14px;
//         border-bottom: 1px solid $white;
//       }
//     }
//   }

//   p {
//     font-size: 14px;
//     margin-top: 0px;

//     span {
//       font-weight: 600;
//       font-size: 20px;
//     }

//     .teamA {
//       font-size: 14px;
//     }
//   }

//   .card_state_main_container {
//     display: flex;
//     flex-direction: row;
//     font-family: Poppins;

//     &.space_evenly {
//       justify-content: space-evenly;
//       align-items: center;
//     }

//     .card_state_left {
//       display: flex;
//       flex-direction: column;
//       padding-top: 20px;
//       font-size: 14px;

//       strong {
//         display: flex;
//         flex: 1;
//         font-weight: normal;
//         text-transform: capitalize;
//       }
//     }

//     img {
//       width: 93%;
//       height: 76px;
//       margin: 25px auto;
//     }
//   }

//   .container_card_footer_main {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-end;
//     justify-content: flex-end;
//     margin: 5px 0 0 0;

//     .card_footer {
//       display: flex;
//       flex-direction: column;
//       flex: 1;

//       .container_body_footer {
//         width: 100%;
//         align-self: center;
//         display: flex;
//         justify-content: space-evenly;
//         align-items: center;
//         padding: 0;
//         margin: 0;

//         span {
//           display: flex;
//           font-size: 14px;
//           font-weight: normal;
//           color: $grey2;
//           opacity: 0.6;

//           svg {
//             margin-right: 5px;
//           }
//         }
//       }
//     }

//     .forwardArrow {
//       &:hover {
//         cursor: pointer;
//       }
//     }
//   }
//   .divider {
//     border: 0.4px solid $divider;
//     opacity: 0.1;
//     padding: 0;
//     margin: 5px 0;
//     bottom: 0;
//     width: 220px;
//     transform: translate(100px, 0);
//     position: absolute;
//   }

//   .container_body_card_start_power {
//     border-radius: 6px;
//     background-image: linear-gradient(110deg, #fda700, #fb6e00 72%);
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     width: 100px;
//     height: 22px;
//     padding: 5px;
//     font-size: 14px;
//     color: $darkblack;
//     position: absolute;
//     font-family: Teko;
//     transform: translate(0, -25px);

//     &.inset {
//       border-radius: 2px;
//       background-image: linear-gradient(110deg, $primary 60%, $yellow);
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       justify-content: center;
//       width: 130px;
//       height: 16px;
//       position: absolute;
//       transform: translate(-18px, -23px);
//       box-shadow: 0 0px 8px 4px $black;
//       font-size: 13px;
//       text-align: center;
//       text-transform: uppercase;
//       font-weight: 550;
//       color: $darkblack;
//       font-family: Teko;
//       margin: 3px 0 0 0;
//       padding: 0;

//       svg {
//         padding: 0;
//         margin: 0;
//         margin-right: 2px;
//       }
//     }

//     img {
//       width: 20px;
//       height: 20px;
//       margin-right: 5px;
//     }
//   }
// }

// @media (max-width: 1900px) {
//   .container_body_card {
//     width: 49%;
//     height: 214px;

//     &.inset {
//       overflow: hidden;
//       padding-top: 21px;
//     }

//     .container_body_card_1 {
//       .container_body_left {
//         .card_mlb_vs {
//           margin: 0;
//           padding: 0;

//           p {
//             margin: 0;
//             padding: 0;
//           }
//         }

//         .team_vs {
//           margin-bottom: 0px;
//           &.selectedColor {
//             p {
//               color: $primary !important;
//             }
//           }
//         }

//         .container_body_card_header {
//           display: flex;
//           align-items: center;
//           height: 30px;

//           .container_selected_p {
//             font-size: 20px;
//           }

//           button {
//             font-size: 14px;
//             width: 94px;
//           }

//           .container_selected {
//             padding: 0;
//             margin: 0 0 0 14px;

//             .container_selected_p_1 {
//               font-size: 16px;
//               img {
//                 width: 28px;
//                 height: 28px;
//                 margin-left: 5px;
//                 padding: 0;
//               }

//               svg {
//                 margin-right: 5px;
//                 width: 20px;
//                 height: 20px;
//               }
//             }
//           }
//         }

//         .container_body_card_state {
//           margin: 5px 0 4px 0;
//           width: 360px;

//           &.card_state_no_data {
//             padding-left: 10px;
//           }

//           .card_state_mlb {
//             > div {
//               span {
//                 font-size: 14px;
//               }

//               p {
//                 font-size: 20px;
//               }
//             }
//           }

//           .card_state {
//             .card_state_title {
//               span {
//                 font-size: 14px;
//               }
//             }

//             .card_state_values {
//               span {
//                 font-size: 18px;
//               }
//             }
//           }
//         }
//       }

//       .container_body_right {
//         .right_img {
//           width: 102px;
//           height: 102px;
//         }
//         .img_badge {
//           width: 32px;
//           height: 32px;
//         }

//         p {
//           font-size: 17px;
//         }
//       }
//     }

//     p {
//       font-size: 16px;

//       span {
//         font-weight: 600;
//         font-size: 20px;
//       }

//       .teamA {
//         font-size: 14px;
//       }
//     }

//     .container_card_footer_main {
//       .card_footer {
//         .container_body_footer {
//           span {
//             font-size: 18px;
//             svg {
//               margin-right: 5px;
//               width: 20px;
//             }
//           }
//         }
//       }
//     }
//     .divider {
//       width: 340px;
//     }

//     .container_body_card_start_power {
//       width: 100px;
//       height: 22px;
//       padding: 5px;
//       font-size: 14px;
//       color: $darkblack;
//       position: absolute;
//       font-family: Teko;
//       transform: translate(0, -25px);

//       &.inset {
//         width: 150px;
//         height: 20px;
//         transform: translate(-18px, -24px);
//         font-size: 16px;

//         svg {
//           padding: 0;
//           margin: 0 2px 3px 0;
//           width: 18px;
//           height: 18px;
//         }
//       }

//       img {
//         width: 20px;
//         height: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
// }

// @media (max-width: 1680px) {
//   .container_body_card {
//     width: 49% !important;
//     height: 214px;

//     &.inset {
//       overflow: hidden;
//       padding-top: 21px;
//     }

//     .container_body_card_1 {
//       .container_body_left {
//         .card_mlb_vs {
//           margin: 0;
//           padding: 0;

//           p {
//             margin: 0;
//             padding: 0;
//           }
//         }

//         .team_vs {
//           margin-bottom: 0px;
//           p {
//             color: #688fbd;
//           }
//         }

//         .container_body_card_header {
//           display: flex;
//           align-items: center;
//           height: 30px;

//           .container_selected_p {
//             font-size: 20px;
//           }

//           button {
//             font-size: 14px;
//             width: 94px;
//           }

//           .container_selected {
//             padding: 0;
//             margin: 0 0 0 14px;

//             .container_selected_p_1 {
//               font-size: 16px;
//               img {
//                 width: 28px;
//                 height: 28px;
//                 margin-left: 5px;
//                 padding: 0;
//               }

//               svg {
//                 margin-right: 5px;
//                 width: 20px;
//                 height: 20px;
//               }
//             }
//           }
//         }

//         .container_body_card_state {
//           margin: 5px 0 4px 0;
//           width: 360px;

//           &.card_state_no_data {
//             padding-left: 10px;
//           }

//           .card_state_mlb {
//             > div {
//               span {
//                 font-size: 14px;
//               }

//               p {
//                 font-size: 20px;
//               }
//             }
//           }

//           .card_state {
//             .card_state_title {
//               span {
//                 font-size: 14px;
//               }
//             }

//             .card_state_values {
//               span {
//                 font-size: 18px;
//               }
//             }
//           }
//         }
//       }

//       .container_body_right {
//         .right_img {
//           width: 102px;
//           height: 102px;
//         }
//         .img_badge {
//           width: 32px;
//           height: 32px;
//         }

//         p {
//           font-size: 17px;
//         }
//       }
//     }

//     p {
//       font-size: 16px;
      
//       span {
//         font-weight: 600;
//         font-size: 20px;
//       }

//       .teamA {
//         font-size: 14px;
//       }
//     }

//     .container_card_footer_main {
//       .card_footer {
//         .container_body_footer {
//           span {
//             font-size: 16px;
//             svg {
//               margin-right: 5px;
//               width: 20px;
//             }
//           }
//         }
//       }
//     }
//     .divider {
//       width: 260px;
//     }

//     .container_body_card_start_power {
//       width: 100px;
//       height: 22px;
//       padding: 5px;
//       font-size: 14px;
//       color: $darkblack;
//       position: absolute;
//       font-family: Teko;
//       transform: translate(0, -25px);

//       &.inset {
//         width: 150px;
//         height: 20px;
//         transform: translate(-18px, -24px);
//         font-size: 16px;

//         svg {
//           padding: 0;
//           margin: 0 2px 3px 0;
//           width: 18px;
//           height: 18px;
//         }
//       }

//       img {
//         width: 20px;
//         height: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
// }

// @media (max-width: 1600px) {
//   .container_body_card {
//     width: 49% !important;
//     height: 214px;

//     &.inset {
//       overflow: hidden;
//       padding-top: 21px;
//     }

//     .container_body_card_1 {
//       .container_body_left {
//         .card_mlb_vs {
//           margin: 0;
//           padding: 0;

//           p {
//             margin: 0;
//             padding: 0;
//           }
//         }

//         .team_vs {
//           margin-bottom: 0px;
//           &.selectedColor {
//             p {
//               color: $primary;
//             }
//           }
//         }

//         .container_body_card_header {
//           display: flex;
//           align-items: center;
//           height: 30px;

//           .container_selected_p {
//             font-size: 20px;
//           }

//           button {
//             font-size: 14px;
//             width: 94px;
//           }

//           .container_selected {
//             padding: 0;
//             margin: 0 0 0 14px;

//             .container_selected_p_1 {
//               font-size: 16px;
//               img {
//                 width: 28px;
//                 height: 28px;
//                 margin-left: 5px;
//                 padding: 0;
//               }

//               svg {
//                 margin-right: 5px;
//                 width: 20px;
//                 height: 20px;
//               }
//             }
//           }
//         }

//         .container_body_card_state {
//           margin: 5px 0 4px 0;
//           width: 300px;

//           &.card_state_no_data {
//             padding-left: 10px;
//           }

//           .card_state_mlb {
//             > div {
//               span {
//                 font-size: 14px;
//               }

//               p {
//                 font-size: 20px;
//               }
//             }
//           }

//           .card_state {
//             .card_state_title {
//               span {
//                 font-size: 14px;
//               }
//             }

//             .card_state_values {
//               span {
//                 font-size: 18px;
//               }
//             }
//           }
//         }
//       }

//       .container_body_right {
//         .right_img {
//           width: 102px;
//           height: 102px;
//         }
//         .img_badge {
//           width: 32px;
//           height: 32px;
//         }

//         p {
//           font-size: 17px;
//         }
//       }
//     }

//     p {
//       font-size: 16px;

//       span {
//         font-weight: 600;
//         font-size: 20px;
//       }

//       .teamA {
//         font-size: 14px;
//       }
//     }

//     .container_card_footer_main {
//       .card_footer {
//         .container_body_footer {
//           span {
//             svg {
//               margin-right: 5px;
//               width: 20px;
//             }
//           }
//         }
//       }
//     }
//     .divider {
//       width: 260px;
//     }

//     .container_body_card_start_power {
//       width: 100px;
//       height: 22px;
//       padding: 5px;
//       font-size: 14px;
//       color: $darkblack;
//       position: absolute;
//       font-family: Teko;
//       transform: translate(0, -25px);

//       &.inset {
//         width: 150px;
//         height: 20px;
//         transform: translate(-18px, -24px);
//         font-size: 16px;

//         svg {
//           padding: 0;
//           margin: 0 2px 3px 0;
//           width: 18px;
//           height: 18px;
//         }
//       }

//       img {
//         width: 20px;
//         height: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
// }

// @media (max-width: 1366px) {
//   .container_body_card {
//     padding: 14px 12px;
//     width: 49% !important;
//     //height: 200px;

//     &.inset {
//       padding-top: 17px;
//     }

//     .container_body_card_1 {
//       .container_body_left {
//         .container_body_card_header {
//           height: 26px;

//           .container_selected_p {
//             font-size: 16px;
//           }

//           button {
//             font-size: 10px;
//             width: 76px;
//             height: 24px;
//             font-weight: 500;
//           }

//           .container_selected {
//             .container_selected_p_1 {
//               font-size: 12px;
//               img {
//                 width: 20px;
//                 height: 20px;
//                 margin-left: 3px;
//               }

//               svg {
//                 margin-right: 3px;
//                 width: 20px;
//                 height: 20px;
//               }
//             }
//           }
//         }

//         .container_body_card_state {
//           width: 220px;

//           .card_state_mlb {
//             > div {
//               margin: 0 12px 0 14px;

//               p {
//                 font-size: 14px;
//               }
//             }
//           }

//           .card_state {
//             .card_state_values {
//               span {
//                 font-weight: 500;
//                 font-size: 14px;
//               }
//             }
//           }
//         }
//       }

//       .container_body_right {
//         .right_img {
//           width: 82px;
//           height: 82px;
//         }
//         .img_badge {
//           width: 22px;
//           height: 22px;
//           transform: translate(40px, -12px);
//         }

//         p {
//           font-weight: 400;
//           font-size: 14px;
//         }
//       }
//     }

//     p {
//       font-size: 14px;

//       span {
//         font-weight: 500;
//         font-size: 18px;
//       }
//     }

//     .container_card_footer_main {
//       margin: 5px 0 0 0;

//       .card_footer {
//         .container_body_footer {
//           span {
//             font-size: 12px;

//             svg {
//               margin: 2px 3px 0 0;
//               width: 13px;
//               height: 13px;
//               padding: 0;
//             }
//           }
//         }
//       }
//     }
//     .divider {
//       width: 210px;
//       transform: translate(85px, 0);
//     }

//     .container_body_card_start_power {
//       width: 80px;
//       height: 22px;
//       padding: 5px;
//       font-size: 14px;
//       color: $darkblack;
//       position: absolute;
//       font-family: Teko;
//       transform: translate(0, -25px);

//       &.inset {
//         width: 110px;
//         height: 15px;
//         position: absolute;
//         transform: translate(-18px, -20px);

//         svg {
//           padding: 0;
//           margin: 0 0 2px 0;
//           margin-right: 2px;
//           width: 12px;
//           height: 12px;
//         }
//       }

//       img {
//         width: 20px;
//         height: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
// }

// @media (max-width: 1280px) {
//   .container_body_card {
//     padding: 14px 12px;
//     width: 49% !important;
//     //height: 200px;

//     &.inset {
//       padding-top: 17px;
//     }

//     .container_body_card_1 {
//       .container_body_left {
//         .container_body_card_header {
//           height: 26px;

//           .container_selected_p {
//             font-size: 16px;
//           }

//           button {
//             font-size: 10px;
//             width: 76px;
//             height: 24px;
//             font-weight: 500;
//           }

//           .container_selected {
//             .container_selected_p_1 {
//               font-size: 12px;
//               img {
//                 width: 20px;
//                 height: 20px;
//                 margin-left: 3px;
//               }

//               svg {
//                 margin-right: 3px;
//                 width: 14px;
//                 height: 14px;
//               }
//             }
//           }
//         }

//         .container_body_card_state {
//           width: 220px;

//           .card_state_mlb {
//             > div {
//               margin: 0 12px 0 14px;

//               p {
//                 font-size: 14px;
//               }
//             }
//           }

//           .card_state {
//             .card_state_values {
//               span {
//                 font-weight: 500;
//                 font-size: 14px;
//               }
//             }
//           }
//         }
//       }

//       .container_body_right {
//         .right_img {
//           width: 82px;
//           height: 82px;
//         }
//         .img_badge {
//           width: 22px;
//           height: 22px;
//           transform: translate(40px, -12px);
//         }

//         p {
//           font-weight: 400;
//           font-size: 14px;
//         }
//       }
//     }

//     p {
//       font-size: 12px;

//       span {
//         font-weight: 500;
//         font-size: 18px;
//       }
//     }

//     .container_card_footer_main {
//       margin: 5px 0 0 0;

//       .card_footer {
//         .container_body_footer {
//           span {
//             font-size: 12px;

//             svg {
//               margin: 2px 3px 0 0;
//               width: 13px;
//               height: 13px;
//               padding: 0;
//             }
//           }
//         }
//       }
//     }
//     .divider {
//       width: 210px;
//       transform: translate(85px, 0);
//     }

//     .container_body_card_start_power {
//       width: 80px;
//       height: 22px;
//       padding: 5px;
//       font-size: 14px;
//       color: $darkblack;
//       position: absolute;
//       font-family: Teko;
//       transform: translate(0, -25px);

//       &.inset {
//         width: 110px;
//         height: 15px;
//         position: absolute;
//         transform: translate(-18px, -20px);

//         svg {
//           padding: 0;
//           margin: 0 0 2px 0;
//           margin-right: 2px;
//           width: 12px;
//           height: 12px;
//         }
//       }

//       img {
//         width: 20px;
//         height: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
// }

// @media (max-width: 1200px) {
//   .container_body_card {
//     padding: 14px 12px;
//     width: 49% !important;
//     //height: 200px;

//     &.inset {
//       padding-top: 17px;
//     }

//     .container_body_card_1 {
//       .container_body_left {
//         .container_body_card_header {
//           height: 26px;

//           .container_selected_p {
//             font-size: 14px;
//           }

//           button {
//             font-size: 10px;
//             width: 66px;
//             height: 24px;
//             font-weight: 500;
//           }

//           .container_selected {
//             .container_selected_p_1 {
//               font-size: 12px;
//               img {
//                 width: 20px;
//                 height: 20px;
//                 margin-left: 3px;
//               }

//               svg {
//                 margin-right: 3px;
//                 width: 14px;
//                 height: 14px;
//               }
//             }
//           }
//         }

//         .container_body_card_state {
//           width: 220px;

//           .card_state_mlb {
//             > div {
//               margin: 0 12px 0 14px;

//               p {
//                 font-size: 14px;
//               }
//             }
//           }

//           .card_state {
//             .card_state_values {
//               span {
//                 font-weight: 500;
//                 font-size: 14px;
//               }
//             }
//           }
//         }
//       }

//       .container_body_right {
//         .right_img {
//           width: 60px;
//           height: 60px;
//         }
//         .img_badge {
//           width: 22px;
//           height: 22px;
//           transform: translate(25px, -12px);
//         }

//         p {
//           font-weight: 400;
//           font-size: 14px;
//         }
//       }
//     }

//     p {
//       font-size: 12px;

//       span {
//         font-weight: 500;
//         font-size: 16px;
//       }
//     }

//     .container_card_footer_main {
//       margin: 5px 0 0 0;

//       .card_footer {
//         .container_body_footer {
//           span {
//             font-size: 12px;

//             svg {
//               margin: 2px 3px 0 0;
//               width: 13px;
//               height: 13px;
//               padding: 0;
//             }
//           }
//         }
//       }
//     }
//     .divider {
//       width: 210px;
//       transform: translate(85px, 0);
//     }

//     .container_body_card_start_power {
//       width: 80px;
//       height: 22px;
//       padding: 5px;
//       font-size: 14px;
//       color: $darkblack;
//       position: absolute;
//       font-family: Teko;
//       transform: translate(0, -25px);

//       &.inset {
//         width: 110px;
//         height: 15px;
//         position: absolute;
//         transform: translate(-18px, -20px);

//         svg {
//           padding: 0;
//           margin: 0 0 2px 0;
//           margin-right: 2px;
//           width: 12px;
//           height: 12px;
//         }
//       }

//       img {
//         width: 20px;
//         height: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
// }
// @media (max-width: 414px) {
//   .container_body_card {
//     &.inset {
//       width: 96% !important;
//       background-color: #35363a;
//     }
//   }
// }
@import "../../scss/colors.scss";

.container_body_card {
  background-color: $lightBlack;
  border-radius: 5px;
  padding: 17px 16px;
  box-shadow: 0 8px 25px 0 $lightBlack7;
  width: 463px;
  height: 218;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid transparent;
  // align-items: flex-start;

  &.inset {
    overflow: hidden;
    padding-top: 21px;
  }

  &.activeBorder {
    border: 1px solid $primary;
  }

  .container_body_card_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: flex-end;
    position: relative;

    .container_body_left {
      // display: flex;
      // flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 100%;
      width: 100%;
      position: relative;

      .card_mlb_vs {
        margin: 0;
        padding: 0;

        p {
          margin: 0;
          padding: 0;
        }
      }

      .team_vs {
        margin-bottom: 0px;
        &.selectedColor {
          p {
            color: $primary !important;
          }
        }
        p {
          margin-left: 10px;
          color: #688fbd;
          font-weight: 500 !important;
        }
      }

      .container_body_card_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        width: 95%;
        justify-content: space-between;

        &.header_flex {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .container_selected {
          display: flex;
          flex: 1;
        }

        .container_selected_p {
          font-size: 18px;
          padding: 0;
          margin: 0;
          color: $lightBlue;
          font-family: Poppins;
          font-weight: 600;

          span {
            // color: $grey3;
            // font-size: 12px;
            // font-weight: normal;
            font-weight: 600;
          }

          &.active {
            color: $primary;
          }
        }

        button {
          background-color: $lightBlack6;
          border: none;
          outline: none;
          color: $grey2;
          font-size: 12px;
          width: 84px;
          height: 28px;
          border-radius: 4px;
          margin-left: 10px;
          opacity: 0.6;
          font-family: Poppins;
          font-weight: normal;
          margin-right: 30px;

          &:hover {
            cursor: pointer;
          }

          &.disabled {
            background-color: $lightGrey4;
            cursor: not-allowed;
            opacity: 0.2;
          }
        }

        .container_selected {
          padding: 0;
          margin: 0 0 0 6px;

          .container_selected_p_1 {
            display: flex;
            flex-direction: row;
            margin: 0;
            padding: 0;
            justify-content: center;
            align-items: center;
            flex: 1;
            align-items: center;
            justify-content: end;

            img {
              width: 24px;
              height: 24px;
              margin-left: 5px;

              &:hover {
                cursor: pointer;
              }
            }

            svg {
              margin-right: 5px;
            }
          }

          img {
            width: 30px;
            display: flex;
            align-self: normal;
          }
        }
      }

      .container_body_card_state {
        margin: 5px 0 4px 0;
        width: 260px;

        &.border {
          border-left: 3px solid $lightBlue;
        }

        &.active {
          border-color: $primary;
        }

        &.card_state_no_data {
          padding-left: 10px;
        }

        .card_state_mlb {
          display: flex;
          margin: 0px 0 0 20px;
          padding: 0;
          border-left: 3px solid $lightBlue;

          &.active {
            border-color: $primary;
          }

          > div {
            display: flex;
            flex-direction: column;
            margin: 0 14px 0 16px;

            span {
              font-size: 14px;
              opacity: 0.6;
              color: $grey2;
              font-weight: normal !important;
            }

            p {
              padding: 0;
              margin: 0 0 2px 0;
              font-size: 14px;
              font-weight: 600;
              color: $grey2;
            }
          }
        }

        .card_state {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          border-left: 3px solid $lightBlue;

          &.active {
            border-color: $primary;
          }

          .card_state_title {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            span {
              color: $grey2;
              font-size: 10px;
              text-transform: uppercase;
              opacity: 0.6;
              text-align: center;
              flex: 1;
            }
          }

          .card_state_values {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            span {
              text-align: center;
              flex: 1;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
      }
    }

    .container_body_right {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translate(0, -6px);
      width: 110px !important;
      flex: 0 0 110px !important;
      .right_img {
        background-color: $white;
        display: flex;
        // width: fit-content !important;
        width: 100px;
        border-radius: 8px;
        border: 1px solid $primary;
        overflow: hidden;
        box-shadow: 0 12px 24px 0 $primaryLight4;
        img {
          width: 100%;
        }
      }
      .img_badge {
        position: absolute;
        width: 24px;
        height: 24px;
        transform: translate(45px, -12px);
        border-radius: 50%;
      }

      p {
        margin: 5px 0 0 0;
        padding: 0;
        font-family: Teko;
        font-weight: 600;
        font-size: 14px;
        border-bottom: 1px solid $white;
      }
    }
  }

  p {
    font-size: 14px;
    margin-top: 0px;

    span {
      font-weight: 600;
      font-size: 20px;
    }

    .teamA {
      font-size: 14px;
    }
  }

  .card_state_main_container {
    display: flex;
    flex-direction: row;
    font-family: Poppins;

    &.space_evenly {
      justify-content: space-evenly;
      align-items: center;
    }

    .card_state_left {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      font-size: 14px;

      strong {
        display: flex;
        flex: 1;
        font-weight: normal;
        text-transform: capitalize;
      }
    }

    img {
      width: 93%;
      height: 76px;
      margin: 25px auto;
    }
  }

  .container_card_footer_main {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 5px 0 0 0;

    .card_footer {
      display: flex;
      flex-direction: column;
      flex: 1;

      .container_body_footer {
        width: 100%;
        align-self: center;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;

        span {
          display: flex;
          font-size: 14px;
          font-weight: normal;
          color: $grey2;
          opacity: 0.6;

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .forwardArrow {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .divider {
    border: 0.4px solid $divider;
    opacity: 0.1;
    padding: 0;
    margin: 10px auto;
    width: 95%;
  }

  .container_body_card_start_power {
    border-radius: 5px 0px 0px 0px;
    background-image: linear-gradient(110deg, $primary 60%, $yellow);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 16px;
    line-height: 16px;
    position: absolute;
    transform: translate(-17px, -17px);
    box-shadow: 0 0px 8px 4px $black;
    top: -5px;

    p {
      font-size: var(--font-size-13);
      text-align: center;
      text-transform: uppercase;
      font-weight: 550;
      color: $darkblack;
      font-family: Teko;
      margin: 3px 0 0 0;
      padding: 0;
    }

    svg {
      padding: 0;
      margin: 0;
      margin-right: 2px;
    }
  }
}

@media (max-width: 1900px) {
  .container_body_card {
    width: 48%;
    height: 267px;

    &.inset {
      overflow: hidden;
      padding-top: 21px;
    }

    .container_body_card_1 {
      .container_body_left {
        .card_mlb_vs {
          margin: 0;
          padding: 0;

          p {
            margin: 0;
            padding: 0;
          }
        }

        .team_vs {
          margin-bottom: 0px;
          &.selectedColor {
            p {
              color: $primary !important;
            }
          }
        }

        .container_body_card_header {
          display: flex;
          align-items: center;
          height: 30px;

          .container_selected_p {
            font-size: 20px;
          }

          button {
            font-size: 14px;
            width: 94px;
          }

          .container_selected {
            padding: 0;
            margin: 0 0 0 14px;

            .container_selected_p_1 {
              font-size: 16px;
              img {
                width: 28px;
                height: 28px;
                margin-left: 5px;
                padding: 0;
              }

              svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .container_body_card_state {
          margin: 5px 0 4px 0;
          width: 360px;

          &.card_state_no_data {
            padding-left: 10px;
          }

          .card_state_mlb {
            > div {
              span {
                font-size: 14px;
              }

              p {
                font-size: 20px;
              }
            }
          }

          .card_state {
            .card_state_title {
              span {
                font-size: 14px;
              }
            }

            .card_state_values {
              span {
                font-size: 18px;
              }
            }
          }
        }
      }

      .container_body_right {
        .right_img {
          width: 115px;
          // height: 115px;
        }
        .img_badge {
          width: 32px;
          height: 32px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    p {
      font-size: 16px;

      span {
        font-weight: 600;
        font-size: 20px;
      }

      .teamA {
        font-size: 14px;
      }
    }

    .container_card_footer_main {
      .card_footer {
        .container_body_footer {
          span {
            font-size: 18px;
            svg {
              margin-right: 5px;
              width: 20px;
            }
          }
        }
      }
    }

    .container_body_card_start_power {
      width: 140px;
      height: 16px;
      line-height: 20px;
      transform: translate(-18px, -15px);

      p {
        font-size: 15px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 550;
        color: $darkblack;
        font-family: Teko;
        margin: 3px 0 0 0;
        padding: 0;
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1680px) {
  .container_body_card {
    width: calc(50% - 20px) !important;
    height: 248px;

    &.inset {
      overflow: hidden;
      padding-top: 21px;
    }

    .container_body_card_1 {
      .container_body_left {
        .card_mlb_vs {
          margin: 0;
          padding: 0;

          p {
            margin: 0;
            padding: 0;
          }
        }

        .team_vs {
          margin-bottom: 0px;
          p {
            color: #688fbd;
          }
        }

        .container_body_card_header {
          display: flex;
          align-items: center;
          height: 30px;

          .container_selected_p {
            font-size: 20px;
          }

          button {
            font-size: 14px;
            width: 94px;
          }

          .container_selected {
            padding: 0;
            margin: 0 0 0 14px;

            .container_selected_p_1 {
              font-size: 16px;
              img {
                width: 28px;
                height: 28px;
                margin-left: 5px;
                padding: 0;
              }

              svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .container_body_card_state {
          margin: 5px 0 4px 0;
          width: 360px;

          &.card_state_no_data {
            padding-left: 10px;
          }

          .card_state_mlb {
            > div {
              span {
                font-size: 14px;
              }

              p {
                font-size: 16px;
              }
            }
          }

          .card_state {
            .card_state_title {
              span {
                font-size: 14px;
              }
            }

            .card_state_values {
              span {
                font-size: 18px;
              }
            }
          }
        }
      }

      .container_body_right {
        .right_img {
          // width: 102px;
          // height: 102px;
        }
        .img_badge {
          width: 32px;
          height: 32px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    p {
      font-size: 16px;

      span {
        font-weight: 600;
        font-size: 20px;
      }

      .teamA {
        font-size: 14px;
      }
    }

    .container_card_footer_main {
      .card_footer {
        .container_body_footer {
          span {
            font-size: 16px;
            svg {
              margin-right: 5px;
              width: 20px;
            }
          }
        }
      }
    }

    .container_body_card_start_power {
      width: 130px;
      height: 16px;
      line-height: 16px;

      p {
        font-size: var(--font-size-13);
        font-weight: 550;
        margin: 3px 0 0 0;
      }

      svg {
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .container_body_card {
    width: calc(50% - 20px) !important;
    height: 248px;

    &.inset {
      overflow: hidden;
      padding-top: 21px;
    }

    .container_body_card_1 {
      .container_body_left {
        .card_mlb_vs {
          margin: 0;
          padding: 0;

          p {
            margin: 0;
            padding: 0;
          }
        }

        .team_vs {
          margin-bottom: 0px;
          &.selectedColor {
            p {
              color: $primary;
            }
          }
        }

        .container_body_card_header {
          display: flex;
          align-items: center;
          height: 30px;

          .container_selected_p {
            font-size: 20px;
          }

          button {
            font-size: 14px;
            width: 94px;
          }

          .container_selected {
            padding: 0;
            margin: 0 0 0 14px;

            .container_selected_p_1 {
              font-size: 16px;
              img {
                width: 28px;
                height: 28px;
                margin-left: 5px;
                padding: 0;
              }

              svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .container_body_card_state {
          margin: 5px 0 4px 0;
          width: 100%;

          &.card_state_no_data {
            padding-left: 10px;
          }

          .card_state_mlb {
            > div {
              span {
                font-size: 14px;
              }

              p {
                font-size: 16px;
              }
            }
          }

          .card_state {
            .card_state_title {
              span {
                font-size: 14px;
              }
            }

            .card_state_values {
              span {
                font-size: 18px;
              }
            }
          }
        }
      }

      .container_body_right {
        .right_img {
          // width: 102px;
          // height: 102px;
        }
        .img_badge {
          width: 32px;
          height: 32px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    p {
      font-size: 16px;

      span {
        font-weight: 600;
        font-size: 20px;
      }

      .teamA {
        font-size: 14px;
      }
    }

    .container_card_footer_main {
      .card_footer {
        .container_body_footer {
          span {
            svg {
              margin-right: 5px;
              width: 20px;
            }
          }
        }
      }
    }

    .container_body_card_start_power {
      width: 130px;
      height: 16px;
      line-height: 16px;

      p {
        font-size: var(--font-size-13);
        font-weight: 550;
        margin: 3px 0 0 0;
      }

      svg {
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .container_body_card {
    padding: 14px 12px;
    width: calc(50% - 20px) !important;
    height: 217px;

    &.inset {
      padding-top: 17px;
    }

    .container_body_card_1 {
      .container_body_left {
        .container_body_card_header {
          height: 26px;

          .container_selected_p {
            font-size: 16px;
          }

          button {
            font-size: 10px;
            width: 76px;
            height: 24px;
            font-weight: 500;
          }

          .container_selected {
            .container_selected_p_1 {
              font-size: 12px;
              img {
                width: 20px;
                height: 20px;
                margin-left: 3px;
              }

              svg {
                margin-right: 3px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .container_body_card_state {
          width: 100%;

          .card_state_mlb {
            > div {
              margin: 0 12px 0 14px;

              p {
                font-size: 14px;
              }
            }
          }

          .card_state {
            .card_state_values {
              span {
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
        }
      }

      .container_body_right {
        .right_img {
          // width: 82px;
          // height: 82px;
        }
        .img_badge {
          width: 22px;
          height: 22px;
          transform: translate(40px, -12px);
        }

        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    p {
      font-size: 14px;

      span {
        font-weight: 500;
        font-size: 18px;
      }
    }

    .container_card_footer_main {
      margin: 5px 0 0 0;

      .card_footer {
        .container_body_footer {
          span {
            font-size: 12px;

            svg {
              margin: 2px 3px 0 0;
              width: 13px;
              height: 13px;
              padding: 0;
            }
          }
        }
      }
    }

    .container_body_card_start_power {
      width: 130px;
      height: 16px;
      line-height: 16px;

      p {
        font-size: var(--font-size-13);
        font-weight: 550;
        margin: 3px 0 0 0;
      }

      svg {
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .container_body_card {
    padding: 14px 12px;
    width: calc(50% - 20px) !important;
    height: 260px;

    &.inset {
      padding-top: 17px;
    }

    .container_body_card_1 {
      .container_body_left {
        .container_body_card_header {
          height: 26px;

          .container_selected_p {
            font-size: 16px;
          }

          button {
            font-size: 10px;
            width: 76px;
            height: 24px;
            font-weight: 500;
          }

          .container_selected {
            .container_selected_p_1 {
              font-size: 12px;
              img {
                width: 20px;
                height: 20px;
                margin-left: 3px;
              }

              svg {
                margin-right: 3px;
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .container_body_card_state {
          width: 220px;

          .card_state_mlb {
            > div {
              margin: 0 12px 0 14px;

              p {
                font-size: 14px;
              }
            }
          }

          .card_state {
            .card_state_values {
              span {
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
        }
      }

      .container_body_right {
        // .right_img {
        //   width: 82px;
        //   height: 82px;
        // }
        .img_badge {
          width: 22px;
          height: 22px;
          transform: translate(40px, -12px);
        }

        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    p {
      font-size: 12px;

      span {
        font-weight: 500;
        font-size: 18px;
      }
    }

    .container_card_footer_main {
      margin: 5px 0 0 0;

      .card_footer {
        .container_body_footer {
          span {
            font-size: 12px;

            svg {
              margin: 2px 3px 0 0;
              width: 13px;
              height: 13px;
              padding: 0;
            }
          }
        }
      }
    }

    .container_body_card_start_power {
      width: 120px;
      height: 15px;
      line-height: 15px;

      p {
        font-size: var(--font-size-12);
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }
}
.mobile_team_d{
  height: 252px !important;
}
@media (max-width: 1200px) {
  .container_body_card {
    padding: 14px 12px;
    width: calc(50% - 20px) !important;
    height: 212px;
   
    &.inset {
      padding-top: 17px;
    }

    .container_body_card_1 {
      .container_body_left {
        .container_body_card_header {
          height: 26px;

          .container_selected_p {
            font-size: 14px;
          }

          button {
            font-size: 10px;
            width: 66px;
            height: 24px;
            font-weight: 500;
          }

          .container_selected {
            .container_selected_p_1 {
              font-size: 12px;
              img {
                width: 20px;
                height: 20px;
                margin-left: 3px;
              }

              svg {
                margin-right: 3px;
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .container_body_card_state {
          width: 270px;

          .card_state_mlb {
            > div {
              margin: 0 12px 0 14px;

              p {
                font-size: 14px;
              }
            }
          }

          .card_state {
            .card_state_values {
              span {
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
        }
      }

      .container_body_right {
        .right_img {
          // width: 60px;
          // height: 60px;
        }
        .img_badge {
          width: 22px;
          height: 22px;
          transform: translate(25px, -12px);
        }

        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    p {
      font-size: 12px;

      span {
        font-weight: 500;
        font-size: 16px;
      }
    }

    .container_card_footer_main {
      margin: 5px 0 0 0;

      .card_footer {
        .container_body_footer {
          span {
            font-size: 12px;

            svg {
              margin: 2px 3px 0 0;
              width: 13px;
              height: 13px;
              padding: 0;
            }
          }
        }
      }
    }

    .container_body_card_start_power {
      width: 120px;
      height: 15px;
      line-height: 15px;

      p {
        font-size: var(--font-size-12);
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }
}
.team_state_point{
  display: contents !important;
  .team_era{
    padding: 0px 5px 0px 13px !important;
  }
}

@media (max-width: 500px) {
  .container_body_card {
    width: calc(100% - 20px) !important;
    &.inset {
      width: 96% !important;
      background-color: #35363a;
    }

    button {
      margin-right: 0px !important;
    }
  
    .container_body_right{
      display: none !important;
     }
  }
}

@media (max-width: 414px) {
  .footer_selected {
    margin: 0 -16px -12px;
    .container_selected_p {
      background-color: rgba(104, 143, 189, 0.3);
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      padding: 5px 0;
      margin-top: 10px;
      button {
        color: #ffffff;
      }
    }
    .container_selected_p.active {
      background: #fb6e00;
    }
    .footer__after_selected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1px 16px;
      p {
        margin: 0;
        display: flex;
        font-weight: 600;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
    }
  }
}