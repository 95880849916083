.__power_center_card .carousel.carousel-slider {
  height: 260px;
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 3px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  display: none;
}
