@import '../../scss/colors.scss';

:root {
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --font-size-13: 13px;
  --font-size-12: 12px;
  --font-size-10: 10px;
}

.container_body_card {
  background-color: $lightBlack;
  border-radius: 5px;
  padding: 14px 16px;
  box-shadow: 0 8px 25px 0 $lightBlack7;
  width: calc(50% - 20px);
  height: 185px;
  margin: 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  &.display_none {
    display: none;
  }

  &.activeBorder {
    border: 1px solid $primary;
    .teamA {
      color: $primary;
    }
  }

  .container_body_card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .container_selected_p {
      font-size: var(--font-size-20);
      padding: 0;
      margin: 0 0 0 0;
      color: $lightBlue;
      font-family: Poppins;
      font-weight: 600;

      span {
        font-size: var(--font-size-20);
        font-weight: 900;
        font-style: italic;
        text-transform: uppercase;
        margin: 0 8px 0 0;
      }

      &.active {
        color: $primary;
        border-bottom-color: $primary;
      }
    }

    .injured {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      transform: translate(-12px, 0);

      span {
        color: $danger2;
        margin: 0 5px;
        font-size: var(--font-size-14);
        font-weight: 500;
      }
    }

    

    button {
      background-color: $lightBlack6;
      border: none;
      outline: none;
      color: $grey3;
      font-size: var(--font-size-12);
      font-weight: 600;
      width: 84px;
      height: 28px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      width: 84px;
      height: 28px;
      /* margin: 2px 0 85px 75px; */
      padding: 5px 16px 6px;
      border-radius: 8px;
      background-color: #688fbd;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #111;

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        background-color: $lightGrey4;
        cursor: not-allowed;
        opacity: 0.2;
      }
    }

    .container_selected {
      padding: 0;
      margin: 0;

      .container_selected_p_1 {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-left: 5px;

          &:hover {
            cursor: pointer;
          }
        }

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .rushing_and_receiving {
    display: flex;
    flex-direction: row;
    height: 6px;
    margin-bottom: 2px;

    div {
      display: flex;
      flex: 1;
      justify-content: center;
      opacity: 0.6;
      font-family: Poppins;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 2px;
      text-align: center;
      color: #688fbd;
    }
  }

  p {
    font-size: var(--font-size-14);
    // margin-top: 15px;

    span {
      font-weight: 600;
      font-size: var(--font-size-20);
    }

    .teamA {
      font-size: var(--font-size-14);
      color: #688fbd;
    }

    .teamB {
      color: $grey2;
      font-size: var(--font-size-14);
      font-weight: normal;
      opacity: 0.8;
    }
  }

  .stats_detail_container {
    display: flex;
    width: 100%;
    margin-top: 14px;

    .stats_detail_left {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      margin-top: 28px;
      font-family: Poppins;
      font-size: 14px;
      color: $grey2;

      span {
        margin: 3px 0;
      }
    }

    .stats_detail_right {
      display: flex;
      flex-direction: column;
      width: 67%;

      .stats_detail_right_h {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 6px;
        color: $grey2;
        font-weight: normal;
        font-family: Poppins;
        font-size: 14px;

        span {
          flex: 1;
          text-align: center;
        }
      }

      .stats_detail_right_b {
        display: flex;
        flex-direction: column;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 14px;

        .stats_detail_right_b_item {
          display: flex;
          width: 100%;
          margin: 3px 0;

          span {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
  }

  .card_state_main_container {
    display: flex;
    flex-direction: row;
    font-family: Poppins;

    &.space_evenly {
      // justify-content: space-evenly;
      align-items: center;
    }

    .card_state_left {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      font-size: var(--font-size-14);

      strong {
        display: flex;
        flex: 1;
        font-weight: normal;
        text-transform: capitalize;
        margin: 5.5px 0;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
      }
    }

    img {
      width: 93%;
      height: 76px;
      margin: 25px auto;
    }

    .container_body_card_state {
      margin: 8px 0 0 0;

      &.border {
        border-left: 3px solid $lightBlue;
      }

      &.active {
        border-color: $primary;
      }

      &.card_state_no_data {
        padding-left: 10px;
      }
    }
  }

  .container_card_footer_main {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    .card_footer_left {
      display: flex;
      flex-direction: column;
      flex: 1;

      .container_body_footer {
        width: 100%;
        align-self: center;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;

        span {
          display: flex;
          font-size: var(--font-size-14);
          font-weight: normal;
          color: $grey2;
          opacity: 0.6;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .card_footer_right {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      bottom: 15px;
      right: 12px;
      opacity: 1;
      position: absolute;
    }
  }
  .divider {
    border: 0.4px solid $divider;
    opacity: 0.1;
    padding: 0;
    margin: 10px auto;
    width: 95%;
  }

  .container_body_card_start_power {
    position: absolute;
    transform: translate(-10px, -10px);

    p {
      font-size: var(--font-size-13);
      text-align: center;
      text-transform: uppercase;
      font-weight: 550;
      color: $darkblack;
      font-family: Teko;
      margin: 3px 0 0 0;
      padding: 0;
    }

    img {
      width: 145px;
      height: 35px;
      position: absolute;
      transform: translate(-1px, -8px);
    }
  }
}

@media (max-width: 1900px) {
  .container_body_card {
    width: calc(50% - 20px);
    height: 222px;

    .container_body_card_header {
      .container_selected_p {
        font-size: var(--font-size-24);

        span {
          font-size: var(--font-size-24);
        }
      }

      .injured {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        transform: translate(-12px, 0);

        svg {
          width: 28px;
          height: 28px;
        }

        span {
          color: $danger2;
          margin: 0 5px;
          font-size: var(--font-size-14);
        }
      }

      button {
        font-size: var(--font-size-16);
        font-weight: 600;
        width: 104px;
        height: 38px;
      }

      .container_selected {
        .container_selected_p_1 {
          img {
            width: 34px;
            height: 34px;
            margin-top: 8px;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-18);
      // margin-top: 15px;

      span {
        font-weight: 600;
        font-size: var(--font-size-18);
      }

      .teamA {
        font-size: var(--font-size-16);
      }

      .teamB {
        font-size: var(--font-size-16);
      }
    }

    .card_state_main_container {
      .card_state_left {
        font-size: var(--font-size-16);

        strong {
          display: flex;
          flex: 1;
          font-weight: normal;
          text-transform: capitalize;
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 95%;
        height: 116px;
        margin: 25px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;
      }
    }

    .container_card_footer_main {
      .card_footer_left {
        .container_body_footer {
          span {
            font-size: var(--font-size-14);

            svg {
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .card_footer_right {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        bottom: 15px;
        right: 12px;
        opacity: 1;
        position: absolute;
      }
    }
    .divider {
      margin: 16px auto;
    }

    .container_body_card_start_power {
      width: 140px;
      height: 20px;
      line-height: 20px;
      transform: translate(-18px, -15px);

      p {
        font-size: 15px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 550;
        color: $darkblack;
        font-family: Teko;
        margin: 3px 0 0 0;
        padding: 0;
      }

      // svg {
      //   padding: 0;
      //   margin: 0;
      //   margin-right: 2px;
      // }
    }
  }
}
@media (max-width: 1800px) {
  .container_body_card {
    width: calc(50% - 20px);
  }
}
@media (max-width: 1680px) {
  .container_body_card {
    width: calc(50% - 20px);
    height: 185px;

    .container_body_card_header {
      .container_selected_p {
        font-size: var(--font-size-20);

        span {
          font-size: var(--font-size-20);
        }
      }

      .injured {
        span {
          font-size: var(--font-size-14);
        }
      }

      button {
        font-size: var(--font-size-12);
        width: 84px;
        height: 28px;
      }

      .container_selected {
        .container_selected_p_1 {
          img {
            width: 26px;
            height: 26px;
            padding: 0;
            margin: 0;
          }

          svg {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-16);

      span {
        font-weight: 600;
        font-size: var(--font-size-20);
      }

      .teamA {
        font-size: var(--font-size-14);
      }

      .teamB {
        color: $grey2;
        font-size: var(--font-size-14);
        font-weight: normal;
        opacity: 0.8;
      }
    }

    .card_state_main_container {
      .card_state_left {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        font-size: var(--font-size-14);

        strong {
          display: flex;
          flex: 1;
          font-weight: normal;
          text-transform: capitalize;
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 93%;
        height: 88px;
        margin: 20px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;
      }
    }

    .container_card_footer_main {
      .card_footer_left {
        .container_body_footer {
          padding: 0;
          margin: 0;

          span {
            font-size: var(--font-size-14);

            svg {
              margin-right: 5px;
            }
          }
        }
      }

      .card_footer_right {
        bottom: 15px;
      }
    }
    .divider {
      border: 0.4px solid $divider;
      opacity: 0.1;
      padding: 0;
      margin: 10px auto;
      width: 95%;
    }

    .container_body_card_start_power {
      width: 130px;
      height: 16px;
      line-height: 16px;

      p {
        font-size: var(--font-size-13);
        font-weight: 550;
        margin: 3px 0 0 0;
      }

      svg {
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .container_body_card {
    width: calc(50% - 20px) !important;
    height: 185px;

    .container_body_card_header {
      .container_selected_p {
        font-size: var(--font-size-20);

        span {
          font-size: var(--font-size-20);
        }
      }

      .injured {
        span {
          font-size: var(--font-size-14);
        }
      }

      button {
        font-size: var(--font-size-12);
        width: 84px;
        height: 28px;
      }

      .container_selected {
        .container_selected_p_1 {
          img {
            width: 26px;
            height: 26px;
            padding: 0;
            margin: 0;
          }

          svg {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-16);

      span {
        font-weight: 600;
        font-size: var(--font-size-20);
      }

      .teamA {
        font-size: var(--font-size-14);
      }

      .teamB {
        color: #688fbd;
        font-weight: 600;
        font-size: var(--font-size-14);
        opacity: 1;
      }
    }

    .card_state_main_container {
      .card_state_left {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        font-size: var(--font-size-14);

        strong {
          display: flex;
          flex: 1;
          font-weight: normal;
          text-transform: capitalize;
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 93%;
        height: 88px;
        margin: 20px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;
      }
    }

    .container_card_footer_main {
      .card_footer_left {
        .container_body_footer {
          padding: 0;
          margin: 0;

          span {
            font-size: var(--font-size-14);

            svg {
              margin-right: 5px;
            }
          }
        }
      }

      .card_footer_right {
        bottom: 15px;
      }
    }
    .divider {
      border: 0.4px solid $divider;
      opacity: 0.1;
      padding: 0;
      margin: 10px auto;
      width: 95%;
    }

    .container_body_card_start_power {
      width: 130px;
      height: 16px;
      line-height: 16px;

      p {
        font-size: var(--font-size-13);
        font-weight: 550;
        margin: 3px 0 0 0;
      }

      svg {
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1480px) {
  .container_body_card {
    width: calc(50% - 20px) !important;
    height: 185px;

    .container_body_card_header {
      .container_selected_p {
        font-size: var(--font-size-20);

        span {
          font-size: var(--font-size-20);
        }
      }

      .injured {
        span {
          font-size: var(--font-size-14);
        }
      }

      button {
        font-size: var(--font-size-12);
        width: 84px;
        height: 28px;
      }

      .container_selected {
        .container_selected_p_1 {
          img {
            width: 24px;
            height: 24px;
            padding: 0;
            margin: 0;
          }

          svg {
            margin-right: 5px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-14);

      span {
        font-size: var(--font-size-20);
      }

      .teamA {
        font-size: var(--font-size-14);
      }

      .teamB {
        font-size: var(--font-size-14);
      }
    }

    .card_state_main_container {
      .card_state_left {
        padding-top: 30px;
        font-size: var(--font-size-14);

        strong {
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 93%;
        height: 88px;
        margin: 20px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;

        .card_state {
          .card_state_title {
            span {
              font-size: var(--font-size-12);
              width: 55px;
            }

            .state_step_1_title {
              font-size: var(--font-size-14);
              width: 46px;
            }
          }

          .card_state_values {
            &.column {
              flex-direction: column;
              width: 320px !important;
            }

            .step_value {
              width: 55px;

              &.margin_4 {
                margin: 6px 0;
              }

              .state_step_1_value {
                font-size: var(--font-size-14);
              }
            }
          }
        }
      }
    }

    .container_card_footer_main {
      .card_footer_left {
        .container_body_footer {
          padding: 0;
          margin: 0;

          span {
            font-size: var(--font-size-14);

            svg {
              margin-right: 5px;
            }
          }
        }
      }

      .card_footer_right {
        bottom: 15px;
      }
    }
    .divider {
      border: 0.4px solid $divider;
      opacity: 0.1;
      padding: 0;
      margin: 10px auto;
      width: 95%;
    }

    .container_body_card_start_power {
      width: 130px;
      height: 16px;
      line-height: 16px;

      p {
        font-size: var(--font-size-13);
        font-weight: 550;
        margin: 3px 0 0 0;
      }

      svg {
        margin-right: 2px;
      }
    }
  }
}
@media (max-width: 1366px) {
  .container_body_card {
    padding: 14px 12px;
    width: calc(50% - 20px) !important;
    height: 185px !important;

    .container_body_card_header {
      .container_selected_p {
        font-size: var(--font-size-16);

        span {
          font-size: var(--font-size-16);
        }
      }

      .injured {
        transform: translate(-10px, 0);

        svg {
          width: 16px;
          height: 16px;
        }

        span {
          font-size: var(--font-size-12);
        }
      }

      button {
        font-size: var(--font-size-10);
        font-weight: 500;
        width: 84px;
        height: 28px;
      }

      .container_selected {
        .container_selected_p_1 {
          font-size: var(--font-size-12);

          img {
            width: 20px;
            height: 20px;
            margin-left: 5px;
          }

          svg {
            margin-right: 3px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-14);
      // margin-top: 15px;

      span {
        font-weight: 600;
        font-size: var(--font-size-20);
      }

      .teamA {
        font-size: var(--font-size-14);
      }

      .teamB {
        font-size: var(--font-size-14);
      }
    }

    .card_state_main_container {
      .card_state_left {
        padding-top: 24px;
        font-size: var(--font-size-12);

        strong {
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 93%;
        height: 76px;
        margin: 25px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;
      }
    }

    .container_card_footer_main {
      .card_footer_left {
        .container_body_footer {
          padding: 0;
          margin: 0;

          span {
            font-size: var(--font-size-12);

            svg {
              margin-right: 5px;
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .card_footer_right {
        bottom: 15px;
        right: 12px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .divider {
      margin: 15px auto;
    }

    .container_body_card_start_power {
      width: 120px;
      height: 15px;
      line-height: 15px;

      p {
        font-size: var(--font-size-12);
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .container_body_card {
    padding: 14px 12px;
    width: calc(50% - 20px) !important;
    height: 185px !important;

    .container_body_card_header {
      .container_selected_p {
        font-size: var(--font-size-16);

        span {
          font-size: var(--font-size-16);
        }
      }

      .injured {
        transform: translate(-10px, 0);

        svg {
          width: 16px;
          height: 16px;
        }

        span {
          font-size: var(--font-size-12);
        }
      }

      button {
        font-size: var(--font-size-12);
        font-weight: 600;
        width: 84px;
        height: 28px;
        font-family: Poppins;
        color: #111;
      }

      .container_selected {
        .container_selected_p_1 {
          font-size: var(--font-size-12);

          img {
            width: 20px;
            height: 20px;
            margin-left: 5px;
          }

          svg {
            margin-right: 3px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-14);
      // margin-top: 15px;

      span {
        font-weight: 600;
        font-size: var(--font-size-20);
      }

      .teamA {
        font-size: var(--font-size-14);
      }

      .teamB {
        font-size: var(--font-size-14);
      }
    }

    .card_state_main_container {
      .card_state_left {
        padding-top: 24px;
        font-size: var(--font-size-12);

        strong {
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 93%;
        height: 66px;
        margin: 25px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;
      }
    }

    .container_card_footer_main {
      .card_footer_left {
        .container_body_footer {
          padding: 0;
          margin: 0;

          span {
            font-size: var(--font-size-12);

            svg {
              margin-right: 5px;
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .card_footer_right {
        bottom: 15px;
        right: 12px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .divider {
      margin: 15px auto;
    }

    .container_body_card_start_power {
      width: 120px;
      height: 15px;
      line-height: 15px;

      p {
        font-size: var(--font-size-12);
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .container_body_card {
    padding: 14px 12px;
    width: calc(50% - 20px) !important;
    height: 185px !important;

    .container_body_card_header {
      .container_selected_p {
        font-size: var(--font-size-14);

        span {
          font-size: var(--font-size-14);
        }
      }

      .injured {
        transform: translate(-10px, 0);

        svg {
          width: 16px;
          height: 16px;
        }

        span {
          font-size: var(--font-size-12);
        }
      }

      button {
        font-size: var(--font-size-10);
        font-weight: 500;
        width: 74px;
        height: 24px;
      }

      .container_selected {
        .container_selected_p_1 {
          font-size: var(--font-size-12);

          img {
            width: 20px;
            height: 20px;
            margin-left: 5px;
          }

          svg {
            margin-right: 3px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-14);
      // margin-top: 15px;

      span {
        font-weight: 600;
        font-size: var(--font-size-20);
      }

      .teamA {
        font-size: var(--font-size-14);
      }

      .teamB {
        font-size: var(--font-size-14);
      }
    }

    .card_state_main_container {
      .card_state_left {
        padding-top: 24px;
        font-size: var(--font-size-12);

        strong {
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 93%;
        height: 66px;
        margin: 25px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;
      }
    }

    .container_card_footer_main {
      .card_footer_left {
        .container_body_footer {
          padding: 0;
          margin: 0;
          span {
            font-size: var(--font-size-12);

            svg {
              margin-right: 5px;
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .card_footer_right {
        bottom: 15px;
        right: 12px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .divider {
      margin: 15px auto;
    }

    .container_body_card_start_power {
      width: 120px;
      height: 15px;
      line-height: 15px;

      p {
        font-size: var(--font-size-12);
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .container_body_card {
    width: 100% !important;
  }
}

@media (max-width: 414px) {
  .container_body_card_start_power_background {
    background-image: linear-gradient(118deg, #292a2e 2%, #514338) !important;
    background-color: unset;
  }

  .container_body_card {
    background-color: $lightBlack11;
    border-radius: 5px;
    padding: 14px 16px;
    box-shadow: 0 8px 25px 0 $lightBlack7;
    // width: 463px;
    // height: 182px;
    margin: 10px 0px;
    position: relative;
    overflow: hidden;
    width: 347px !important;
    height: 184px;
    margin: 20px 0px;
    padding: 16px 16px 12px;
    border: solid 1px #111111;

    .projected_goalie_color{
      font-size: 12px;
      font-family: Poppins;
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: #3C3D41;
      padding: 0px 5px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      border-radius: 0px 4px 0px 6px;
    }

    &.display_none {
      display: none;
    }

    .container_body_card_header_outer_left{
      width: 100%;
    }

    .container_body_card_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .container_selected_p {
        // font-size: var(--font-size-15);
        // padding: 0;
        // margin: unset;
        // color: $lightBlue;
        // font-family: Poppins;
        // font-weight: 600;
        font-family: Poppins;
        font-size: var(--font-size-18);
        font-weight: 600 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $lightBlue;

        span {
          font-size: var(--font-size-15);
          font-weight: 900;
          font-style: italic;
          text-transform: uppercase;
          margin: 0 8px 0 0;
        }

        &.active {
          color: $primary;
          border-bottom-color: $primary;
        }
      }

      .injured {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        transform: translate(0, 0);

        span {
          color: $danger2;
          margin: 0 5px;
          font-size: var(--font-size-14);
          font-weight: 500;
        }
      }

      button {
        background-color: $lightBlack6;
        border: none;
        outline: none;
        color: $grey3;
        font-size: var(--font-size-12);
        font-weight: 600;
        width: 84px;
        height: 28px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        &:hover {
          cursor: pointer;
        }

        &.disabled {
          background-color: $lightGrey4;
          cursor: not-allowed;
          opacity: 0.2;
        }
      }

      .container_selected {
        padding: 0;
        margin: 0;

        .container_selected_p_1 {
          display: flex;
          flex-direction: row;
          margin: 0;
          padding: 0;
          justify-content: center;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            margin-left: 5px;

            &:hover {
              cursor: pointer;
            }
          }

          svg {
            margin-right: 5px;
            // align-self: center;
          }
        }
      }
    }

    .rushing_and_receiving {
      display: flex;
      flex-direction: row;
      height: 6px;
      margin-bottom: 2px;

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #688fbd;
      }
    }

    p {
      font-size: var(--font-size-14);
      margin-top: 5px;

      span {
        font-weight: 600;
        font-size: var(--font-size-20);
      }

      .teamA {
        font-size: var(--font-size-12);
      }

      .teamB {
        color: $grey2;
        font-size: var(--font-size-12);
        font-weight: normal;
        opacity: 0.8;
      }
    }

    .stats_detail_container {
      display: flex;
      width: 100%;
      margin-top: 14px;

      .stats_detail_left {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        margin-top: 28px;
        font-family: Poppins;
        font-size: 12px;
        color: $grey2;
        text-align: left;

        span {
          margin: 3px 0;
        }
      }

      .stats_detail_right {
        display: flex;
        flex-direction: column;
        width: 67%;

        .stats_detail_right_h {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 6px;
          color: $grey2;
          font-weight: normal;
          font-family: Poppins;
          font-size: 14px;

          span {
            flex: 1;
            text-align: center;
          }
        }

        .stats_detail_right_b {
          display: flex;
          flex-direction: column;
          opacity: 0.6;
          font-family: Poppins;
          font-size: 12px;

          .stats_detail_right_b_item {
            display: flex;
            width: 100%;
            margin: 3px 0;

            span {
              flex: 1;
              text-align: center;
            }
          }
        }
      }
    }

    .card_state_main_container {
      display: flex;
      flex-direction: row;
      font-family: Poppins;

      &.space_evenly {
        // justify-content: space-evenly;
        align-items: center;
      }

      .card_state_left {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        font-size: var(--font-size-14);

        strong {
          display: flex;
          flex: 1;
          font-weight: normal;
          text-transform: capitalize;
          margin: 5.5px 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $grey2;
        }
      }

      img {
        width: 93%;
        height: 76px;
        margin: 25px auto;
      }

      .container_body_card_state {
        margin: 8px 0 0 0;

        &.border {
          border-left: 3px solid $lightBlue;
        }

        &.active {
          border-color: $primary;
        }

        &.card_state_no_data {
          padding-left: 10px;
        }
      }
    }

    .container_card_footer_main {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;

      .card_footer_left {
        display: flex;
        flex-direction: column;
        flex: 1;

        .container_body_footer {
          width: 100%;
          align-self: center;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 0;
          margin: 0;

          span {
            display: flex;
            font-size: var(--font-size-12);
            font-weight: normal;
            color: $grey2;
            opacity: 0.6;

            svg {
              margin-right: 5px;
              align-self: center;
            }
          }
        }
      }

      .card_footer_right {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        bottom: 15px;
        right: 12px;
        opacity: 1;
        position: absolute;
      }
    }
    .divider {
      border: 0.4px solid $divider;
      opacity: 0.1;
      padding: 0;
      margin: 10px auto;
      width: 95%;
    }

    .container_body_card_start_power {
      // border-radius: 2px;
      // background-image: linear-gradient(110deg, $primary 60%, $yellow);
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: center;
      // width: 130px;
      // height: 16px;
      // line-height: 16px;
      // position: absolute;
      // transform: translate(-17px, -14px);
      // box-shadow: 0 0px 8px 4px $black;

      p {
        font-size: var(--font-size-13);
        text-align: center;
        text-transform: uppercase;
        font-weight: 550;
        color: $darkblack;
        font-family: Teko;
        margin: 3px 0 0 0;
        padding: 0;
      }

      img {
        width: 145px;
        height: 35px;
        position: absolute;
        transform: translate(-5px, -8px);
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 2px;
      }
    }
  }
}
.swap_div {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #688fbd;
  display: flex;
  .swap_span_first{
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #688fbd !important;
    padding-right: 8px;
    opacity: 1 !important;
  }
  .swap_span_second {
    padding-left: 10px;
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #688fbd !important;
    padding-right: 8px;
    opacity: 1 !important;
  }
  .swap_span_last {
    padding-left: 10px;
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #688fbd !important;
    padding-right: 8px;
    opacity: 1 !important;
  }
}

.container_body_card_header_outer {
  display: flex;
  padding-top: 5px;
  justify-content: space-between;
}
.container_body_card_header_outer_left {
  width: 75%;
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.container_body_card_header_outer_right {
  width: 86px;
  flex: 0 0 86px;
  img {
    width: 100%;
    cursor: pointer;
  }
}