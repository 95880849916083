@import '../../scss/colors.scss';
.__table{
    .__bar{
        width: 80px;
        background-color: rgba(242, 242, 242, 0.1);
        border-radius: 1em;
        >span{
            border-radius: 1em;
            height: 6px;
        }
        .__pointsRequired{
            justify-content: flex-end;
        }
    }
    .__vertical-line{
        margin: 0 0.22em;
    }
}
@media (min-width: 1199px){
    .__table{
        >div, >a{
            padding: 6px 28px;
            background-color: rgba(17, 17, 17, 0.8);
            &:nth-child(10n + 6), &:nth-child(10n + 7), &:nth-child(10n + 8), &:nth-child(10n + 9), &:nth-child(10n + 10){
                background-color: #17181a;
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){
                background: $black;
                display: flex;
                align-items: center;
                position: -webkit-sticky;
                position: sticky;
            }
        }
        .__light-grey{
            color: rgba(255, 255, 255, 0.6);
        }
    }
    .__support{
        .__icon{
            left: -0.5em;
        }
        .__info-icon{
            top: -1em;
        }
    }
}

@media (max-width: 1200px){
    .__table{
        line-height: 1.2em;
        .__bar{
            width: 70px;
        }
    }    
    .__power-grid-row-card{
        display: flex !important;
        flex-wrap: wrap;
        border-radius: 5px;
        background-image: linear-gradient(to bottom, #111111, #232529) !important;
        padding: 1em;
        border: solid 2px #111111;
        overflow: hidden;
        .__support{
            width: 100%;
            align-items: flex-start;
            .__main{
                display: flex;
            }
            .__info-icon{
                top: 0.3em;
                margin-left: 0.4em !important;
                width: 1em;
            }
            .__icon{
                margin-right: 0.8em;
                height: 100%;
                left: 0;
                svg{
                    max-width: 1.2em;
                }
                span{
                    position: absolute;
                    padding: 2em 0 ;
                    width: 100%;
                    bottom: 0;
                    background: $primary;
                    border-radius: 0 0 2em 2em;
                }
                svg{
                    margin: 0.5em 0.5em 0 0.5em;
                    color: #111;
                }
            }
        }
        .__entries{
            width: 50%;
            margin: 1em 0;
        }
        .__prize{
            width: 50%;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column-reverse;

        }
        .__pointsRequired{
            background-image: linear-gradient(to bottom, #fb6e00, rgba(251, 110, 0, 0.6));
            width: 9em;
            padding: 0.3em;
            display: flex !important;
            justify-content: center;
            margin-left: auto;
            border-radius: 6px;
            margin: 0.6em 0 0 auto;
            img{
                width: 1.3em;
                margin: 0 0.4em;
            }
        }
    }
}