@import "../../scss/colors.scss";

.card {
    display: flex;
    flex-direction: column;
    width: 236px;
    background-color: $lightBlack;
    margin: 0px 30px 25px 30px;
    padding: 9px 16px;
    border-radius: 8px;
    border: 1px solid $lightBlack;

    &.active {
        border: 1px solid $primary;
        box-shadow: 0 2px 24px 0 $primaryLight7;
    }

    &:hover {
        box-shadow: 0 0 8px 0 $primaryLight7;
        border: solid 1px $primaryLight5;
    }

    .card_header {
        display: flex;
        justify-content: space-between;
        color: $lightBlue;
        font-size: 16px;
        font-weight: 600;

        svg {
            margin: 0;
            padding: 0;

            &:hover {
                cursor: pointer;
            }
        }

        >div {
            display: flex;
            
            p {
                &.active {
                    color: $primary;
                }
            }
        }

        img {
            width: 20px;
            height: 19px;
            margin-right: 3px;
        }

    }

    .card_body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 1px;
        font-size: 12px;
        text-transform: capitalize;
        border-left: 3px solid $lightBlue;
        margin: 5px 0;

        .card_cat {
            text-transform: uppercase;
        }
        
        .card_xp_points {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                margin: 0 0 0 5px;
            }
        }

        .card_clock {
            display: flex;
            align-items: center;

            svg {
                padding: 0;
                margin: -3px 3px 0 0;
            }

            span {
                font-size: 12px;
            }
        }
    }

    .card_type {
        background-color: $lightBlack5;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        padding: 3px 0;
        border-radius: 6px;
        font-weight: bold;
        opacity: 0.7;
        color: $grey;
        text-transform: capitalize;

        &.danger {
            color: $danger3;
        }

        &.success {
            color: $success;
        }
    }
}