@import '../../scss/colors.scss';
.subtitle{
    opacity: 0.5;
    margin-top: 25px;
}
.card{
    box-shadow: 0 0.143rem 3.142rem 0 rgba(0, 0, 0, 0.5);
    border-radius: 0.571rem;
    padding: 1rem 1.42rem;
    background-color: #28292C;
    ul{
        padding: 0;
        list-style-type: none;
        margin: 0;
        display: grid;
        grid-gap: 0 2rem;
        grid-template-columns: 1fr auto;
    }
    li{
        color: #f2f2f2;
        &:nth-child(even){
            color: $primary;
            font-weight: 600;
        }
    }
}
.cardWrapper{
    display: grid;
    grid-template-columns: 16.571rem 17.286rem;
    grid-gap: 1.42rem;
    align-items: flex-start;
}
.powersSubSectionsWrapper{
    section{
        display: grid;
        grid-template-columns: 3.857rem 1fr;
        grid-column-gap: 1.428rem;
        margin: 1.8rem 0;
        &:first-child{
            margin-top: 1.3rem;
        }
        img{
            grid-row: 1/3;
            width: 100%;
            margin-top: 1rem;
        }
        h4{
            color: $primary;
            font-size: 1.286rem;
            margin: 0;
            font-weight: 600;
        }
    }
}
.linupRequrementsList{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
        display: flex;
    }
    span{
        color: $primary;
        font-weight: 900;
        width: 1.42rem;
    }
    b{
        margin-right: 1rem;
        font-weight: 900;
        font-style: italic;
    }
}
.crossicon{
    font-size: 1rem;
    position: absolute;
    right: 1.071em;
    top: 1.071em;
    cursor: pointer;
    padding: 1em;
    border-radius: 50%;
    transition: 0.2s all;
    &:hover{
        background: $lightBlack11;
    }
    &:active{
        background: $lightGrey6;
    }
    span{
        line-height: 1em;
        width: 1em;
        height: 1em;
        display: block;
        position: relative;
        &::after, &::before{
            content: '';
            height: 100%;
            position: absolute;
            width: 0.072em;
            background: $grey2;
            left: 50%;
            top: 0;
        }
        &::after{
            transform: rotate(45deg);
        }
        &::before{
            transform: rotate(135deg);
        }
    }
}
.popupWrapper{
    position: absolute;
    z-index: 10000;
    top: 0;
}
.popup{
    padding: 7.142rem;
    border-radius: 0.8571rem;
    background: $lightBlack;
    box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
    width: 72.36%;
    z-index: 1;
    top: 0 !important;
    margin: 5.5rem auto 8rem auto;
    position: relative;
    top: 0;
    max-height: 95vh;
    overflow: auto;
    &::-webkit-scrollbar {
    width: 8px;
    }

    &::-webkit-scrollbar-track {
    background-color: $black2;
    border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
    background-color: $grey5;
    width: 7px;
    border-radius: 5px;
    }
}
.blur{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
}
.title{
    margin-top: -3rem;
}

.ContestRulesPageDesktop  {
    nav{
        position: sticky;
        top: 0;
        align-self: start;
        width: 340px;
        height: 613px;
        padding: 14px 50px 24px 0;
        border-radius: 8px;
        background-color: #1b1c1f;
        overflow-y: auto;
        &::-webkit-scrollbar {
        width: 8px;
        }
    
        &::-webkit-scrollbar-track {
        background-color: $black2;
        border-radius: 5px;
        }
    
        &::-webkit-scrollbar-thumb {
        background-color: $grey5;
        width: 7px;
        border-radius: 5px;
        }
        ol {
            list-style: none;
            li {
                opacity: 0.7;
                font-family: Poppins;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.17px;
                color: #fff;
                /* margin-top: 25px; */
                margin-bottom: 25px;
                text-transform: uppercase;
            }
        }
    }
}

.ContestRulesPageDesktop {
    display: flex;
    // margin-top: 60px;
    // margin-left: 100px;
    // margin-right: 100px;
    // margin-bottom: 60px;
    .contentPart {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-left: 60px;
        padding-right: 60px;
        h1 {
            font-family: Teko;
            font-size: 48px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            color: #fb6e00;
        }
        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.57;
            letter-spacing: normal;
            color: #fff;
        }
        .light {
            font-weight: 400 !important;
            font-style: normal;
            margin-bottom: 10px;
        }
        section {
            margin-top: 25px;
            h2 {
                color: #fb6e00
            }
            li {
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
                margin-bottom: 10px;
            }
            .tableHeader {
                width: 480px;
                height: 60px;
                padding: 19px 15px 19px 20px;
                border-radius: 8px 8px 0px 0px; 
                background-color: #111;
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .headerTitle{
                    display: flex;
                    flex: 1;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #f2f2f2;
                }
            }
            .tableRow {
                width: 480px;
                padding: 19px 15px 19px 20px;
                display: flex;
                flex-direction: row;
                padding: 16px 15px 16px 20px;
                background-color: #1b1c1f;
                .section{
                    display: flex;
                    flex: 1;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #f2f2f2;
                }
            }
            .tableRowEven {
                width: 480px;
                padding: 19px 15px 19px 20px;
                display: flex;
                flex-direction: row;
                padding: 16px 15px 16px 20px;
                background-color: #17181a;
                .section{
                    display: flex;
                    flex: 1;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #f2f2f2;
                }
            }
        }
    }
}

.mobileContestRules {
    button {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #f2f2f2;
        height: 60px;
        background: #17181a;
        width: 100%;
        border: 0;
        text-align: left;
        padding: 10px;
        border: solid 1px #333;
        border-left: 0;
        border-right: 0;
        cursor: pointer;
    }
    h1 {
        font-family: Teko;
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        color: #fb6e00;
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.57;
        letter-spacing: normal;
        color: #fff;
    }
    .light {
        font-weight: 400 !important;
        font-style: normal;
        margin-bottom: 10px;
    }
    section {
        padding-top: 25px;
        background: black;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        h2 {
            color: #fb6e00;
            margin-top: 20px;
        }
        li {
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            color: #f2f2f2;
            margin-bottom: 10px;
        }
        .tableHeader {
            max-width: 480px;
            height: 60px;
            padding: 19px 8px 19px 10px;
            border-radius: 8px 8px 0px 0px; 
            background-color: #111;
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            .headerTitle{
                display: flex;
                flex: 1;
                font-family: Poppins;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
            }
        }
        .tableRow {
            max-width: 480px;
            padding: 19px 8px 19px 10px;
            display: flex;
            flex-direction: row;
            background-color: #1b1c1f;
            .section{
                display: flex;
                flex: 1;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
            }
        }
        .tableRowEven {
            max-width: 480px;
            padding: 19px 8px 19px 10px;
            display: flex;
            flex-direction: row;
            padding: 16px 15px 16px 20px;
            background-color: #17181a;
            .section{
                display: flex;
                flex: 1;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #f2f2f2;
            }
        }
    }
}

@media (max-width: 1300px) {
    .popup{
        max-width: 80%;
    }
}
@media (max-width: 1100px) {
    .popup{
        max-width: 90%;
    }
}
@media (max-width: 997px) {
    .popup{
        padding: 21px;
        padding-top: 56px;
    }
    .title{
        margin-top: -1rem;
        font-size: 3rem;
    }
}

@media (max-width: 700px) {
    .popup{
        max-width: 90%;
        width: 90%;
        max-height: 665px;
        overflow: auto;
    }
    .cardWrapper{
        grid-template-columns: 1fr;
    }
    .powersSubSectionsWrapper{
        section{
            grid-template-columns: 3rem 1fr;
            grid-column-gap: 1rem;
        }
    }
}