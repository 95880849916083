@import '../../scss/colors.scss';
.root{
    padding: 2.8rem 0 10.7rem 0;
    background: #1e1e1e;
    form{
        max-width: 60rem;
        margin: auto;
        width: 90%;
    }
    a{
        text-decoration: underline;
    }
}
.title{
    margin: 4rem 0 2.857rem 0;
}
.formWrapper{
    display: grid;
    grid-template-columns: 25.714rem 26.143rem;
    justify-content: space-between;
}
.textAreaWrapper{
    grid-column: span 2;
}
.additionWaysToContactSection{
    grid-column: 2/3;
    grid-row: 1/2;
    address{
        display: inline;
        font-style: normal;
    }
}
.inputField{
    margin-bottom: 2.857rem;
    position: relative;
    label{
        display: block;
        margin-bottom: 0.214rem;
    }
    input, select, textarea{
        width: 100%;
        background-color: rgba($color: $lightGrey1, $alpha: 0.14);
        border-radius: 0.5714rem;
        font-size: 1rem;
        outline: none;
        padding: 0.714rem 1.4rem;
        display: block;
        color: inherit;
        border: none;
    }
    select{
        background: url("../../assets/chevron-down-white.svg") rgba($color: $lightGrey1, $alpha: 0.14) no-repeat calc(100% - 1.4rem);
        background-size: 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        &::-ms-expand {
            display: none;
        }
        option{
            color: #1e1e1e;
        }
    }
    textarea{
        height: 8.572rem;
    }
    span:last-child{
        position: absolute;
        right: 0;
        bottom: -2.42rem;
        line-height: 1em;
    }
}
.btn{
    background: $primary;
    border: none;
    outline: none;
    font-size: 1.143rem;
    padding: 0.5em 3.3em;
    font-weight: 600;
    color: #111;
    border-radius: 0.5em;
    margin-top: 2.857rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #fff;
    &::after, &::before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        padding-top: 100%;
        top: 50%;
        transition: 0.2s all;
        transform: translateY(-50%) scale(0);
    }
    &:hover::before{
        background: rgba($color: #9e9e9e, $alpha: 0.1);
        transform: translateY(-50%) scale(1);
    }
    &:active::after{
        background: rgba($color: #000, $alpha: 0.1);
        transform: translateY(-50%) scale(1);
    }
}
.noteBlogSection{
    margin-top: 5.5rem;
}
@media (max-width: 900px) {
    .formWrapper{
        grid-template-columns: 1fr;
    }
    .additionWaysToContactSection{
        grid-column: initial;
        grid-row: initial;
        margin-top: 7rem;
    }
}