@import "../../scss/colors.scss";

.container_footer {
    display: flex;
    flex-direction: column;
    margin: 8em 0;

    .container_footer_header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        transform: translate(-20px, 20px);

        .container_footer_title {
            display: flex;
            flex-direction: column;
            transform: translate(10px, -20px);

            h2 {
                font-size: 20px !important;
                font-weight: normal;
                margin: 0;
                padding: 0;
            }

            .separator {
                width: 52px;
                border-bottom: 2px solid $primary;
                margin-top: 2px;
            }
        }

        svg {
            transform: translate(0px, 2px);
        }
    }

    .container_footer_1 {
        display: flex;
        flex-direction: column;
        background-color: $black2;
        padding: 10px 40px;
        border-radius: 9px;
        align-items: flex-start;

        .container_footer_header_1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin: 20px 0;
            width: 80%;
            text-align: left;

            p {
                display: flex;
                align-items: flex-start;
                font-size: 14px;

                &:nth-child(1) {
                    width: 120px;
                }

                &:nth-child(2) {
                    width: 275px;
                }

                &:nth-child(3) {
                    width: 220px;
                }

                svg {
                    margin-right: 5px;
                }
            }
        }

        .container_footer_body {
            display: flex;
            flex-direction: column;
            width: 100%;

            p {
                font-size: 14px;
                display: flex;
                align-items: center;
                margin: 10px 0;

                span {
                    width: 6px;
                    height: 6px;
                    background-color: $primary;
                    border-radius: 50%;
                    margin: 5px 10px 0 0;
                }
            }

            button {
                background-color: $primary;
                border: none;
                outline: none;
                display: flex;
                flex-direction: row;
                padding: 13px 24px;
                width: 210px;
                font-size: 16px;
                font-weight: 500;
                border-radius: 12px;
                margin: 25px 0;

                &:hover {
                    cursor: pointer;
                }

                img {
                    width: 30px;
                    height: 20px;
                    position: relative;
                    margin-left: 10px;
                }
            }

            .container_body_img {
                width: 421px;
                height: 291px;
                position: absolute;
                display: flex;
                align-self: flex-end;
                transform: translate(40px, -19px);
            }
        }
    }
}