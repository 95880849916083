@import "../../scss/colors.scss";

.__interactive_contests_power_center_card + .__interactive_contests_power_center_card {
    margin-bottom: 20px;
}
.__interactive_contests_power_center_card {
    display: flex;
    margin: 0 15px;
}
.__power_center_upcoming_card {
    width: 280px;
    height: 380px;
    border-radius: 8px;
    background-color: $black2;
    display: flex;
    flex-direction: column;
    padding: 0px 24px 24px 24px;
    position: relative;
    &.__mobile_view {
        width: 337px;
        height: 250px;
        margin-bottom: 20px;
    }
    .___upcoming_card_header {
        padding-top: 26px;
        font-family: Poppins;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fb6e00;
        &.__mobile_view {
            padding-top: 10px;
            font-size: 24px;
        }
    }
    .__upcoming_card_subtitle {
       margin: 0;
        text-shadow: 0 2px 4px var(--black-50);
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.86px;
        text-align: center;
        color: rgba(242, 242, 242, 0.75);
    }
    .__upcoming_card_content {
        margin: 38px 22px 10px 24px;
        text-shadow: 0 2px 14px var(--black-50);
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
        &.__mobile_view {
            margin: 20px 20px 10px 20px;;
        }
    }
    .__upcoming_card_sub_content {
        width: 206px;
       margin: 0 auto;
        text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;

        span{
            font-weight: 600;
            color: #fb6e00;
        }
    }
    button {
        position: absolute;
        bottom: 70px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        color:#fb6e00;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        width: 232px;
        height: 38px;
        padding: 7px 16px 8px 15px;
        border-radius: 6px;
        border: solid 1px #fb6e00;
        background-color: rgba(17, 17, 17, 0.6);
        display: flex;
        align-items: center;
        img{
            margin-right: 4px;
        }
        &.__mobile_view{
            width: 292px;
            display: block;
            padding: 0;
            bottom: 24px;
        }
    }

}

@media (max-width: 768px){
    .__interactive_contests_power_center_card {
        display: flex;
        flex: 1 1;
        justify-content: space-evenly;
    }
}