@import "../../scss/colors.scss";

.__interactive_contests_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  background-color: $lightBlack10;

  .__interactive_contests_most_popular {
    width: 15%;
    padding-left: 20px;

    p {
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey2;
    }
  }

  .__interactive_contests_prize_total {
    width: 15%;
    p {
      opacity: 0.6;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey2;
    }
  }

  .__interactive_contests_top_prize {
    width: 15%;
    p {
      opacity: 0.6;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey2;
    }
  }

  .__interactive_contests_min_entry {
    width: 15%;
    p {
      opacity: 0.6;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey2;
    }
  }

  .__interactive_contests_date {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .__drop_down_menu {
    cursor: pointer;
  }

  .__currency_menu {
    width: 150px;
    height: 193px;
    border-radius: 8px;
    box-shadow: 0 2px 14px 0 $black5;
    border: solid 1px $black;
    background-color: $lightGrey;
    z-index: 1;
    position: absolute;
    margin-top: 10px;

    div {
      display: flex;
      align-items: center;
      padding-left: 20px;
      width: 100%;
      height: 46px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey2;
      cursor: pointer;
      margin-bottom: 2px;
    }

    .__currency_menu_selected {
      background-color: $lightBlue6;
    }
  }
}

.__interactive_contests_power_center_card_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  .__interactive_contests_power_center_card
    + .__interactive_contests_power_center_card {
    margin-bottom: 20px;
  }
  .__interactive_contests_power_center_card {
    display: flex;
    margin: 0 15px;
    //flex: 1;
    //justify-content: space-evenly;
  }
}

.__power_up_text {
  width: 326px;
  height: 60px;
  margin: 40px 24px 28px 25px;
  text-shadow: 0 2px 14px $lightBlack8;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
  text-align: center;
  color: $white;
}

.__power_up_btn {
  width: 316px;
  height: 48px;
  margin: 28px 29px 60px 30px;
  border-radius: 6px;
  border: solid 2px $primary;
  background-color: $primary;
  font-family: Teko;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $black;
}

@media (max-width: 768px) {
  .__interactive_contests_filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: $lightBlack;

    .__interactive_contests_most_popular {
      width: 55%;
      padding-left: 20px;

      p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
      }
    }

    .__interactive_contests_date {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    .__drop_down_menu {
      cursor: pointer;
    }
  }

  .__interactive_contests_power_center_card_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .__interactive_contests_power_center_card {
      display: flex;
      flex: 1;
      justify-content: space-evenly;
    }
  }
}

@media (max-width: 414px) {
  .__interactive_contests_filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: $lightBlack;

    .__interactive_contests_most_popular {
      width: 55%;
      padding-left: 0px;

      p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey2;
      }
    }

    .__interactive_contests_date {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    .__drop_down_menu {
      cursor: pointer;
    }
  }

  .__interactive_contests_power_center_card_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .__interactive_contests_power_center_card {
      display: flex;
      flex: 1;
      justify-content: space-evenly;
      margin: 0px;
    }
  }
}

.__age_restirction {
  margin-top: 70px;
  width: 100%;
  height: 100%;
  text-shadow: 0 2px 34px rgba(0, 0, 0, 0.5);
  font-family: Teko;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
  text-align: center;
  color: #f2f2f2;

  .__age_restirction_desctiprion {
    width: 464px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #f2f2f2;
    margin: 0 auto;
    margin-top: 35px;

    .__age_restirction_contact_us {
      margin-top: 17px;
      font-size: 15px;
    }

    .__age_restirction_link {
      text-decoration: underline !important;
      color: #fb6e00;
    }
  }
}

.discrepancy_wrp {
  position: relative;
  text-align: center;
  padding: 40px 0;
  max-width: 600px;
  margin: 0 auto;
}

.discrepancy_wrp h2 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #f2f2f2;
  margin: 0;
  padding: 30px 0 20px 0;
}

.discrepancy_wrp p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #f2f2f2;
  margin: 0;
  padding-bottom: 40px;
}
.discrepancy_wrp button {
  padding: 12px 93px;
  border-radius: 12px;
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #111;
  border: 0;
  display: block;
  margin: 0 auto 50px;
}
.discrepancy_wrp span {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #f2f2f2;
  display: block;
}
.discrepancy_wrp a {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #fb6e00;
  text-decoration: underline !important;
}
@media (max-width: 540px) {
  .discrepancy_wrp span {
    display: inline;
  }
  .discrepancy_wrp p {
    padding-bottom: 30px;
  }
  .discrepancy_wrp button {
    margin: 0 auto 30px;
  }
}
.notEnoguhPWRSModel {
  border-radius: 14px;
  background-color: #202124;
  padding: 40px;
  text-align: center;
  color: #f2f2f2;
  img {
    width: 60%;
  }
  .not_enoguh_pwrs_div {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    letter-spacing: 0.67px;
    margin-bottom: 26px;
    span{
        color: #fb6e00;
    }
  }
  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0.53px;
    margin-bottom: 50px;
  }
  button {
    background-color: #fb6e00;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #111;
    padding: 5px 20px;
    border-radius: 12px;
    border: none;
    height: 50px;
    min-width: 70%;
  }
  .notEnoguhPWRSModel_body {
    border-radius: 10px;
    background-color: #202124;
    text-align: center;
  }
}
