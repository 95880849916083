.main {
  color: white;
  max-width: 550px;
  margin: 0 auto;

  .stick {
    position: sticky;
    top: 50px;
    z-index: 200;
    background-color: #1e1e1e;
    height: 50px;
    padding-top: 10px;

    .details {
      padding: 0 15px 0;
    }
  }

  .play__heading {
    margin-top: 30px;
    h2 {
      text-align: center;
      font-family: Teko;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fb6e00;
    }
  }

  .standingWrapper {
    background-color: #17181a;
  }
}
