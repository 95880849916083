@import '../../scss/colors.scss';

.battery_power_levels {
    width: 290px;
    height: 540px;
    margin: 35px 0 0;
    padding: 29px 22px 15px;
    border-radius: 14px;
    background-color: $black2;

    .title {
      width: 100%;
      height: 28px;
      font-family: Teko;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $grey2;
      text-transform: uppercase;

      span {
        color: $primary;
      }
    }

    .battery_levels {
      display: flex;
      flex-direction: row;
      margin-top: 22px;

      div {
        flex: 1;
        text-align: center;
      }

      .power_title {
        width: 120px;
        height: 18px;
        font-family: Teko;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.9;
        letter-spacing: normal;
        text-align: center;
        color: $grey2;
      }

      .battery_duration_text {
        width: 120px;
        height: 18px;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: $grey6;
        margin-top: -10px;
      }

      .recharge_all_container {
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        .share_to_recharge {
            width: 58px;
            height: 30px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            color: $grey6;
        }

        img {
            margin: 2px;
        }
      }
    }
  }