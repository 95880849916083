.__TimerClock{
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 0 0.6em;
    .__primary-color{
        background: #000;
        width: 1.6em;
        height: 1.6em;
        text-align: center;  
        border-radius: 0.2em;
        line-height: 1.75em;
    }
    .__label{
        font-size: 12px;
        color: rgba($color: #f2f2f2, $alpha: 0.7);
    }
}