@import '../../scss/colors.scss';

.__custom_drop_down_wrapper {
    position: relative;
    width: 135px;

    .__custom_drop_down_wrapper_header {
        display: flex;
        margin-left: 10px;
        cursor: pointer;
        align-items: center;

        .__custom_drop_down_wrapper_header_title {
            height: 23px;
            margin: 0 5px 0 0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $grey2;
        }

        .__custom_drop_down_wrapper_header_down_arrow {
            margin: 4px 0 4px 2px;
            display: inline-block;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $grey2;
        }
    }

    .__custom_drop_down_wrapper_list {
        position: absolute;
        top: 35px;
        width: 100%;
        border-radius: 8px;
        z-index: 1;

        .__custom_drop_down_wrapper_list_item {
            width: 100%;
            height: 48px;
            padding: 13px 0 12px 11px;
            background-color: $lightGrey;
            border-bottom: 1px solid $black;
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $grey2;
            cursor: pointer;
        }

        .__custom_drop_down_wrapper_list_item_selected {
            background-color: $lightBlue4;;
        }

        .__custom_drop_down_wrapper_list_item_border_radius_top {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        .__custom_drop_down_wrapper_list_item_border_radius_bottom {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}

@media (max-width: 414px) {
    .__custom_drop_down_wrapper{
        min-width: inherit !important;
    }
    // .__custom_drop_down_wrapper {
    //     .__custom_drop_down_wrapper_header {
    //         display: unset;
    //         margin-left: 0;
    //     }
    // }
}