@import "../../scss/colors.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  .page_left {
    position: relative;
    display: flex;
    flex: 1 1 70%;
    // width: 1124px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .page_sidebar {
    flex: 1 1 7%;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin: 100px 0 0 0;
    justify-content: center;
    align-items: center;

    .header_top {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: center;
      align-items: center;

      h1 {
        margin: 0;
        padding: 0;
        color: $white;
        font-size: 56px;

        .h1_orange {
          color: $primary;
        }
      }

      p {
        font-size: 20px;
        padding: 0;
        margin: 0 0 30px 0;
      }
    }

    .header_bottom {
      display: grid;
      grid-template-columns: repeat(3, 200px);
      gap: 10px;
      justify-content: center;
      align-items: center;

      p {
        font-size: 20px;
        margin: 0;
        padding: 0;
      }

      span {
        font-size: 26px;
        font-weight: bold;
        color: $primary;
      }
    }
  }

  .content_header {
    display: flex;
    width: 99%;
    justify-content: space-around;
    align-items: flex-end;
    margin: 60px 0 10px 0;
  }

  .content_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;

    .content_body {
      margin: 0 0 40px 0;
      display: flex;
      flex-direction: column;
      width: 82%;
    }

    .content_body_footer {
      display: flex;
      flex-direction: column;
      width: 90%;
      align-items: center;
      font-size: 26px;
      height: 320px;
      margin: 20px 0 0 0;

      strong {
        margin: 40px 0 20px 0;
      }

      span {
        color: $primary;
      }

      .content_body_footer_bottom {
        align-self: flex-end;
        position: absolute;
        bottom: -10px;
        right: -30px;
      }
    }
  }

  .content_header_right {
    display: flex;
    flex-direction: row;
    flex: 0.6 1 30%;
    justify-content: flex-end;
  }

  .content_header_left {
    color: $primary;
    font-size: 18px;
    margin: 0;
    padding: 0;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin: -250px 0 0 0;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 1224px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    .page_left {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: 100px 0 0 0;
      justify-content: center;
      align-items: center;

      .header_top {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: center;

        h1 {
          margin: 0;
          padding: 0;
          color: $white;
          font-size: 52px;

          .h1_orange {
            color: $primary;
          }
        }

        p {
          font-size: 16px;
          padding: 0;
          margin: 0 0 30px 0;
          text-align: center;
          width: 65%;
        }
      }

      .header_bottom {
        display: flex;
        width: 94%;

        .header_bottom_mobile {
          width: 100%;
        }

        p {
          font-size: 20px;
          margin: 0;
          padding: 0;
        }

        span {
          font-size: 26px;
          font-weight: bold;
          color: $primary;
        }
      }
    }

    .content_header {
      display: flex;
      flex-direction: column-reverse;
      width: 99%;
      justify-content: space-around;
      align-items: flex-start;
      margin: 60px 0 10px 0;
    }

    .content_left {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;

      .content_body {
        margin: 0 0 40px 0;
        display: flex;
        flex-direction: column;
        width: 95%;
      }

      .content_body_footer {
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;
        font-size: 20px;
        height: 320px;
        margin: 20px 0 0 0;

        strong {
          margin: 40px 0 20px 0;
        }

        span {
          color: $primary;
        }

        .content_body_footer_bottom {
          align-self: flex-end;
          position: absolute;
          bottom: -10px;
          right: -50px;
        }
      }
    }

    .content_header_right {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 5px 0;
      justify-content: space-evenly;
      align-items: flex-start;
      width: 90%;
      margin: 0 auto;
    }

    .content_header_left {
      color: $white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      width: 95%;
      margin: 20px auto;
      padding: 0;
    }
  }
}
